import {
  Box,
  Stack,
  Image,
  Text,
  Flex,
  ModalOverlay,
  Modal,
  Button,
  useDisclosure,
  useToast,
  ToastId,
  Slide,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useAppSelector } from "../../stores/global";
import {
  useGetBettingNumQuery,
  useGetGuesserBetNumQuery,
} from "../../stores/api";
import { BellIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import { BetInfo2, GameResult, GameWin, Message } from "../../types/ws";
import { isArray, isEmpty } from "lodash";
import {
  GameNotification,
  GameNotificationProps,
} from "../../components/GameNotification2";
import { numToSide, playSound } from "./utils";
import { motion } from "framer-motion";

export function Guess2() {
  const navigate = useNavigate();
  const toastRef = useRef<ToastId | undefined>();
  const token = useAppSelector((state) => state.auth.token);
  const locale = useAppSelector((state) => state.locale);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [available, setAvailable] = useState("0");
  const [from, setFrom] = useState<"available" | "robot_free">("available");
  const [lastInterval, setLastInterval] = useState<
    ReturnType<typeof setInterval> | undefined
  >();
  const [win, setWin] = useState("");
  const [lose, setLose] = useState("");
  const [bet, setBet] = useState("");
  const [result, setResult] = useState("");
  const [notification, setNotification] =
    useState<GameNotificationProps["title"]>("end");
  const notificationDisclosure = useDisclosure();
  const guessDisclosure = useDisclosure();

  const { data } = useGetBettingNumQuery(1);
  const [betting, setBetting] = useState("1");
  const { data: guesserBetNum } = useGetGuesserBetNumQuery();
  const [guessBetting, setGuessBetting] = useState("10");
  const toast = useToast();

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    import.meta.env.VITE_GAME2_WS_HOST,
    {
      protocols: `${token.split(" ")[1]}&${locale === "EN" ? "en" : "ch"}&web`,
      heartbeat: true,
    },
    !!token,
  );

  const [counts, setCounts] = useState<BetInfo2["game"]>({
    id: 0,
    gusser: "0",
    obj_1: "0",
    obj_2: "0",
    obj_3: "0",
  });

  const [betCounts, setBetCounts] = useState<BetInfo2["personal_bet_num"]>({
    obj_1: "",
    obj_2: "",
    obj_3: "",
  });

  const [guesserSelf, setGuesserSelf] = useState(false);

  const [queue, setQueue] = useState(0);
  const [myQueue, setMyQueue] = useState("");
  const [winner, setWinner] = useState("");

  const connectionState = {
    [ReadyState.CONNECTING]: (
      <FormattedMessage defaultMessage="连接中" id="Guess.connecting" />
    ),
    [ReadyState.OPEN]: (
      <FormattedMessage defaultMessage="已连接" id="Guess.open" />
    ),
    [ReadyState.CLOSING]: (
      <FormattedMessage defaultMessage="断开中" id="Guess.closing" />
    ),
    [ReadyState.CLOSED]: (
      <FormattedMessage defaultMessage="已断开" id="Guess.closed" />
    ),
    [ReadyState.UNINSTANTIATED]: (
      <FormattedMessage defaultMessage="未初始化" id="Guess.uninstantiated" />
    ),
  }[readyState];

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        class: "GuessBettingService",
        function: "betInfo",
        params: {},
      });
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    if (toastRef.current) {
      toast.update(toastRef.current, { title: connectionState });
    } else {
      toastRef.current = toast({
        title: connectionState,
        duration: 500,
        position: "top",
      });
    }
    if (readyState === ReadyState.CLOSED) {
      navigate("/");
    }
  }, [readyState, toast, connectionState, navigate, sendJsonMessage]);

  const [left, setLeft] = useState(0);
  const confirmDisclosure = useDisclosure();
  const [side, setSide] = useState<3 | 2 | 1 | 4>(2);

  const audioRef = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(true);
  const [fighting, setFighting] = useState(false);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }
    const msg = lastJsonMessage as Message<unknown>;
    if (
      msg.origin &&
      msg.origin.class === "GuessBettingService" &&
      (msg.origin.function === "betting" ||
        msg.origin.function === "guessBetting")
    ) {
      if (msg.code !== 10000) {
        toast({
          title: msg.msg,
          position: "top",
          containerStyle: {
            marginTop: "140px",
          },
        });
        return;
      }
    }
    switch (msg.type) {
      case "betInfo": {
        const typedMessage = msg as Message<BetInfo2>;
        setAvailable(typedMessage.result.available);
        setCounts(typedMessage.result.game);
        setQueue(typedMessage.result.guesser_tol);
        setBetCounts(typedMessage.result.personal_bet_num);
        setGuesserSelf(typedMessage.result.guesserSelf === 1);
        setMyQueue(typedMessage.result.queue_number);
        const timeout = parseInt(typedMessage.result.left_time);
        setLeft(timeout);
        if (lastInterval) {
          clearInterval(lastInterval);
        }
        const int = setInterval(() => {
          setLeft((value) => {
            if (value <= 0) {
              clearInterval(int);
              return 0;
            }
            return value - 1;
          });
        }, 1000);
        setLastInterval(int);
        break;
      }
      case "pushBetInfo": {
        const typedMessage = msg as Message<BetInfo2>;
        setCounts(typedMessage.result.game);
        break;
      }
      case "finishWs2": {
        if (isEmpty(msg.result)) {
          setWin("");
          setLose("");
          setBet("");
        }
        if (
          isArray(msg.result) &&
          msg.result.length > 0 &&
          msg.result?.[0] === "stop"
        ) {
          setNotification("offline");
          setWin("");
          setLose("");
          setBet("");
          setTimeout(() => {
            notificationDisclosure.onClose();
          }, 3000);
        } else {
          setNotification("end");
        }
        notificationDisclosure.onOpen();
        break;
      }
      case "startWs2": {
        setWin("");
        setLose("");
        setBet("");
        notificationDisclosure.onOpen();
        setNotification("countdown");
        setResult("");
        setWinner("");
        break;
      }
      case "destroyWs2": {
        setNotification("stop");
        setFighting(true);
        notificationDisclosure.onOpen();
        break;
      }
      case "gameResultWs2": {
        notificationDisclosure.onClose();
        const gameResultWs2 = msg as Message<GameResult>;
        setResult(gameResultWs2.result.lose);
        setFighting(false);
        setWinner(gameResultWs2.result.win);
        break;
      }
      case "gameWinNumWs2": {
        const gameMsg = msg as Message<GameWin>;
        setWin(gameMsg.result.win_num);
        setLose(gameMsg.result.lose_num);
        setBet(gameMsg.result.bet_num);
        setNotification("end");
        notificationDisclosure.onOpen();
        break;
      }
      case "farmerGameWinNum": {
        const gameMsg = msg as Message<GameWin>;
        setWin(gameMsg.result.win_num);
        setLose(gameMsg.result.lose_num);
        setBet(gameMsg.result.bet_num);
        setNotification("continue");
        notificationDisclosure.onOpen();
        break;
      }
      case "beginWs2": {
        notificationDisclosure.onClose();
        break;
      }
      case "origin": {
        if (
          msg.origin.function === "guessBetting" ||
          msg.origin.function === "guessAgain"
        ) {
          toast({
            title: (
              <FormattedMessage
                defaultMessage="下注成功"
                id="Game.Guess.success"
              />
            ),
          });
          guessDisclosure.onClose();
        }
        break;
      }
    }
  }, [lastJsonMessage, toast]);

  return (
    <Stack
      width="100vw"
      height="100vh"
      bgImg="/img/game2/bg.png"
      bgSize="cover"
      position="relative"
      alignItems="center"
      overflowX="hidden"
      gap="0"
    >
      <audio hidden src="/img/game2/bgm.mp3" autoPlay loop ref={audioRef} />
      <Box position="relative">
        <Image
          src="/img/game/count.png"
          position="absolute"
          width="140px"
          maxWidth="fit-content"
        />
        <Text
          zIndex="10"
          color="#ff676e"
          fontWeight="900"
          py="6px"
          filter="drop-shadow(1px 1px 0px #162945)"
          fontSize="x-large"
          width="140px"
          textAlign="center"
        >
          {left}
        </Text>
      </Box>
      <Stack
        transform="auto"
        position="absolute"
        alignItems="end"
        fontSize="small"
        color="white"
        right="0"
        zIndex="10"
      >
        <Text
          transform="auto"
          translateY="5px"
          bgImg="/img/game/bg-record.png"
          bgSize="cover"
          bgRepeat="no-repeat"
          px="8px"
          py="4px"
          textAlign="end"
          onClick={() => {
            navigate("/game/guess/record/1");
          }}
        >
          <FormattedMessage defaultMessage="游戏记录" id="Game.Guess.history" />
        </Text>
        <Flex
          alignItems="center"
          gap="4px"
          rounded="16px"
          bg="#ffffff66"
          px="8px"
          py="2px"
          borderWidth={from === "available" ? "2px" : 0}
          borderColor="yellow"
          borderStyle="solid"
          onClick={() => {
            setFrom("available");
            playSound("asset");
          }}
        >
          <Image src="/img/game/coin.png" width="16px" height="16px" />
          <Text>可用IEBB {available}</Text>
        </Flex>
        {playing && (
          <BellIcon
            width="24px"
            height="24px"
            alignSelf="end"
            m="4px"
            onClick={() => {
              if (playing) {
                audioRef.current.pause();
                setPlaying(false);
              } else {
                audioRef.current.play();
                setPlaying(true);
              }
            }}
          />
        )}
        {!playing && (
          <Image
            src="/img/icon-bell-slash-solid.svg"
            width="24px"
            height="24px"
            alignSelf="end"
            m="4px"
            onClick={() => {
              if (playing) {
                audioRef.current.pause();
                setPlaying(false);
              } else {
                audioRef.current.play();
                setPlaying(true);
              }
            }}
          />
        )}
      </Stack>
      <Stack
        transform="auto"
        translateY="-20px"
        width="100vw"
        alignItems="center"
        overflow="hidden"
      >
        <Text color="white" alignSelf="start">
          <FormattedMessage
            id="Game.Guess.round"
            defaultMessage="第 {id} 期"
            values={{ id: counts.id }}
          />
        </Text>
        <Stack
          alignSelf="start"
          transform="auto"
          translateY="0px"
          onClick={() => {
            guessDisclosure.onToggle();
          }}
        >
          <Flex
            color="white"
            pos="absolute"
            top="0px"
            width="100%"
            textAlign="center"
            justifyContent="center"
          >
            <FormattedMessage
              defaultMessage="魔法师"
              id="Game.Guess.guessSide"
            />{" "}
            {guesserSelf && (
              <motion.div
                animate={{
                  color: ["#ff00ff", "#ffff00", "#00ffff", "#ff00ff"],
                  scale: [1, 1.5, 0.8, 1],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  type: "tween",
                }}
              >
                <FormattedMessage defaultMessage="自己" id="Game.Guess.self" />
              </motion.div>
            )}
          </Flex>
          <Image src="/img/game2/portrait.gif" width="100px" />
          <Stack
            transform="auto"
            translateY="-30px"
            fontSize="x-small"
            alignItems="center"
            gap="0px"
            py="4px"
            px="8px"
            bg="#111"
            rounded="50px"
          >
            <Text color="white">
              排队中 {queue} {myQueue}
            </Text>
            <Text color="white">本局定额 {counts.gusser}</Text>
          </Stack>
        </Stack>
        <Stack
          transform="auto"
          translateY="-50px"
          alignItems="center"
          onClick={() => {
            if (guessDisclosure.isOpen) return;
            setSide(3);
            playSound("bet");
            confirmDisclosure.onOpen();
          }}
        >
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#111"
            rounded="50px"
            alignItems="center"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="cyan" ml="16px" fontWeight="bold">
              {counts.obj_3}
            </Text>
          </Flex>
          <Text
            color="white"
            fontSize="x-small"
            borderRadius="16px"
            border="2px solid #ccc"
            background="#00000022"
            px="16px"
            py="2px"
          >
            <FormattedMessage
              defaultMessage="和平使者"
              id="Game.Guess.yellowSide"
            />
          </Text>
          {(result === "" || result === "obj_1" || result === "obj_2") && (
            <Image src="/img/game2/yellowman.png" width="70px" />
          )}
          {result === "obj_3" && (
            <Image src="/img/game2/yellow_win.gif" width="120px" />
          )}
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#eee"
            rounded="50px"
            alignItems="center"
            display={betCounts.obj_3 ? "flex" : "none"}
            position="absolute"
            top="300px"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="black" ml="16px" fontWeight="bold">
              {betCounts.obj_3}
            </Text>
          </Flex>
        </Stack>
        <Stack
          alignItems="center"
          transform="auto"
          translateX="-140px"
          translateY="-140px"
          onClick={() => {
            if (guessDisclosure.isOpen) return;
            setSide(2);
            playSound("bet");
            confirmDisclosure.onOpen();
          }}
        >
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#111"
            rounded="50px"
            alignItems="center"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="cyan" ml="16px" fontWeight="bold">
              {counts.obj_2}
            </Text>
          </Flex>
          <Text
            color="white"
            fontSize="x-small"
            borderRadius="16px"
            border="2px solid #ccc"
            background="#00000022"
            px="16px"
            py="2px"
          >
            <FormattedMessage defaultMessage="战士" id="Game.Guess.blueSide" />
          </Text>
          {result === "obj_2" && (
            <Image
              src="/img/game2/blue_lose.gif"
              width="200px"
              transform="auto"
              translateX="-40px"
              translateY="-10px"
            />
          )}
          {fighting && (
            <Image
              src="/img/game2/blue_attack.gif"
              width="200px"
              transform="auto"
              translateX="30px"
              translateY="-10px"
            />
          )}
          {!fighting && result === "" && (
            <Image
              src="/img/game2/blueman.png"
              width="80px"
              transform="auto"
              translateX="0px"
              translateY="0px"
            />
          )}
          {(result === "obj_3" || result === "obj_1") && (
            <Image
              src="/img/game2/blue_win.gif"
              width="120px"
              transform="auto"
              translateX="0px"
              translateY="-10px"
            />
          )}
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#eee"
            rounded="50px"
            alignItems="center"
            display={betCounts.obj_2 ? "flex" : "none"}
            position="absolute"
            top="150px"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="black" ml="16px" fontWeight="bold">
              {betCounts.obj_2}
            </Text>
          </Flex>
        </Stack>
        <Stack
          alignItems="center"
          transform="auto"
          translateX="120px"
          translateY="-240px"
          onClick={() => {
            if (guessDisclosure.isOpen) return;
            setSide(1);
            playSound("bet");
            confirmDisclosure.onOpen();
          }}
        >
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#111"
            rounded="50px"
            alignItems="center"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="cyan" ml="16px" fontWeight="bold">
              {counts.obj_1}
            </Text>
          </Flex>
          <Text
            color="white"
            fontSize="x-small"
            borderRadius="16px"
            border="2px solid #ccc"
            background="#00000022"
            px="16px"
            py="2px"
          >
            <FormattedMessage defaultMessage="忍者" id="Game.Guess.redSide" />
          </Text>
          {result === "obj_1" && (
            <Image
              src="/img/game2/red_lose.gif"
              width="150px"
              transform="auto"
              translateX="30px"
              translateY="-30px"
            />
          )}
          {fighting && (
            <Image
              src="/img/game2/red_attack.gif"
              width="230px"
              transform="auto"
              translateX="-70px"
              translateY="-60px"
            />
          )}
          {!fighting && result === "" && (
            <Image
              src="/img/game2/redman.png"
              width="80px"
              transform="auto"
              translateX="0px"
              translateY="0px"
            />
          )}
          {(result === "obj_2" || result === "obj_3") && (
            <Image
              src="/img/game2/red_win.gif"
              width="120px"
              transform="auto"
              translateX="0px"
              translateY="-30px"
            />
          )}
          <Flex
            width="100px"
            height="24px"
            p="4px"
            bg="#eee"
            rounded="50px"
            alignItems="center"
            display={betCounts.obj_1 ? "flex" : "none"}
            position="absolute"
            top="130px"
          >
            <Image src="/img/game/coin.png" width="16px" />
            <Text color="black" ml="16px" fontWeight="bold">
              {betCounts.obj_1}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Stack
        position="fixed"
        bottom="0"
        width="100vw"
        alignItems="center"
        flex="1"
        gap="0"
      >
        <Stack width="calc(100vw - 32px)" bottom="0">
          {isOpen && (
            <Flex justifyContent="space-between" marginX="16px" gap="16px">
              {data?.data?.betting?.map((item) => {
                return (
                  <Box
                    key={item}
                    px="16px"
                    py="4px"
                    bgColor="#ffffff99"
                    flex={1}
                    rounded="4px"
                    textAlign="center"
                    fontSize="large"
                    fontWeight="bold"
                    onClick={() => {
                      setBetting(item);
                      playSound("bet");
                      onClose();
                    }}
                  >
                    {item}
                  </Box>
                );
              })}
            </Flex>
          )}
          <Flex
            rounded="12px"
            bgColor="#ffffff66"
            alignItems="center"
            marginX="16px"
            marginY="8px"
          >
            <Flex
              flex={1}
              margin="8px"
              bgColor="#ffffff66"
              borderWidth="0"
              rounded="8px"
              onClick={() => {
                onToggle();
              }}
              px="8px"
              py="4px"
              fontSize="large"
              fontWeight="bold"
              minH="36px"
            >
              <ChevronUpIcon width="24px" height="24px" />
              {betting}
            </Flex>
            <Text
              color="black"
              bgColor="#fad600"
              borderColor="white"
              borderStyle="solid"
              borderWidth="2px"
              rounded="8px"
              fontSize="small"
              fontWeight="900"
              px="16px"
              py="4px"
              margin="8px"
              onClick={() => {
                if (betting === "") {
                  toast({
                    title: (
                      <FormattedMessage
                        defaultMessage="请选择金额和星球"
                        id="Game.Guess.choose"
                      />
                    ),
                    position: "top",
                  });
                  return;
                }
                playSound("bet");
                confirmDisclosure.onOpen();
              }}
            >
              <FormattedMessage id="Game.Guess.bet" defaultMessage="选择" />
            </Text>
          </Flex>
        </Stack>
      </Stack>
      {guessDisclosure.isOpen && (
        <Box
          position="fixed"
          width="100%"
          height="100%"
          bg="#00000066"
          onClick={() => {
            guessDisclosure.onClose();
          }}
        />
      )}
      <Slide direction="bottom" in={guessDisclosure.isOpen}>
        <Stack
          bg="#181535"
          width="100vw"
          alignItems="center"
          flex="1"
          paddingBottom="20px"
        >
          <Image src="/img/game2/title.gif" width="150px" />
          <Text
            pos="absolute"
            color="white"
            top="15px"
            fontWeight="bold"
            style={{ textShadow: "#17114d 2px 0 3px" }}
          >
            <FormattedMessage
              defaultMessage="魔法师"
              id="Game.Guess.guessSide"
            />
            选择
          </Text>
          <CloseIcon
            position="absolute"
            top="10px"
            right="10px"
            color="white"
            onClick={() => {
              guessDisclosure.onClose();
            }}
          />
          <Stack width="calc(100vw - 32px)" bottom="0">
            {isOpen && (
              <Flex justifyContent="space-between" marginX="16px" gap="16px">
                {guesserBetNum?.data?.betting?.map((item) => {
                  return (
                    <Box
                      key={item}
                      px="16px"
                      py="4px"
                      bgColor="#ffffff99"
                      flex={1}
                      rounded="4px"
                      textAlign="center"
                      fontSize="large"
                      fontWeight="bold"
                      onClick={() => {
                        setGuessBetting(item);
                        playSound("bet");
                        onClose();
                      }}
                    >
                      {item}
                    </Box>
                  );
                })}
              </Flex>
            )}
            <Flex
              rounded="12px"
              bgColor="#ffffff66"
              alignItems="center"
              margin="16px"
            >
              <Flex
                flex={1}
                margin="8px"
                bgColor="#ffffff66"
                borderWidth="0"
                rounded="8px"
                onClick={() => {
                  onToggle();
                }}
                px="8px"
                py="4px"
                fontSize="large"
                fontWeight="bold"
                minH="36px"
              >
                <ChevronUpIcon width="24px" height="24px" />
                {guessBetting}
              </Flex>
              <Text
                color="black"
                bgColor="#fad600"
                borderColor="white"
                borderStyle="solid"
                borderWidth="2px"
                rounded="8px"
                fontSize="small"
                fontWeight="900"
                px="16px"
                py="4px"
                margin="8px"
                onClick={() => {
                  if (guessBetting === "") {
                    toast({
                      title: (
                        <FormattedMessage
                          defaultMessage="请选择金额和星球"
                          id="Game.Guess.choose"
                        />
                      ),
                      position: "top",
                    });
                    return;
                  }
                  setSide(4);
                  playSound("bet");
                  confirmDisclosure.onOpen();
                }}
              >
                <FormattedMessage id="Game.Guess.bet" defaultMessage="选择" />
              </Text>
            </Flex>
          </Stack>
        </Stack>
      </Slide>
      <GameNotification
        title={notification}
        win={win}
        lose={lose}
        bet={bet}
        winner={winner}
        isOpen={notificationDisclosure.isOpen}
        onClose={notificationDisclosure.onClose}
        onContinue={() => {
          sendJsonMessage({
            class: "GuessBettingService",
            function: "guessAgain",
            params: {},
          });
        }}
      />
      <Modal
        isOpen={confirmDisclosure.isOpen}
        onClose={confirmDisclosure.onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <Stack
          justifyContent="center"
          h="100%"
          color="white"
          position="fixed"
          top="0"
          zIndex="2000"
        >
          <Box position="relative">
            <Image src="/img/game/bg-notify-2.png" />
            <Text
              position="absolute"
              top="25%"
              width="100%"
              textAlign="center"
              fontSize="xx-large"
              fontWeight="900"
              filter="drop-shadow(2px 2px 0 #062083)"
              rotate="-10deg"
              transform="auto"
            >
              {side === 4 && (
                <FormattedMessage
                  defaultMessage="确认排队"
                  id="Game.Guess.queue"
                />
              )}
              {side !== 4 && (
                <FormattedMessage
                  defaultMessage="确认选择"
                  id="Game.Guess.confirm"
                />
              )}{" "}
            </Text>
            <Text
              position="absolute"
              top="45%"
              fontSize="x-large"
              fontWeight="bold"
              width="100%"
              textAlign="center"
              transform="auto"
              filter="drop-shadow(2px 2px 0 #062083)"
              rotate="-10deg"
            >
              {numToSide(side)}
              <br />
              <Text as="span" color="yellow">
                {side === 4 ? guessBetting : betting}
              </Text>
              IEBB
            </Text>
            <Flex
              position="absolute"
              top="70%"
              left="calc(50% - 60px)"
              transform="auto"
              rotate="-10deg"
            >
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  if (guessDisclosure.isOpen) {
                    sendJsonMessage({
                      class: "GuessBettingService",
                      function: "guessBetting",
                      params: {
                        account_type: from,
                        num: guessBetting,
                      },
                    });
                  } else {
                    sendJsonMessage({
                      class: "GuessBettingService",
                      function: "betting",
                      params: {
                        account_type: from,
                        obj_id: side,
                        num: betting,
                      },
                    });
                  }
                  confirmDisclosure.onClose();
                }}
              >
                <FormattedMessage defaultMessage="确认" id="Game.Guess.ok" />
              </Button>
              <Button
                variant="ghost"
                onClick={() => confirmDisclosure.onClose()}
              >
                <FormattedMessage
                  defaultMessage="取消"
                  id="Game.Guess.cancel"
                />
              </Button>
            </Flex>
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
}
