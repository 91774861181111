import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Grid,
  GridItem,
  Flex,
  Image,
  UseDisclosureProps,
  DrawerBody,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

export type WalletSelectorProps = {
  onSelect: (wallet: "tokenpocket" | "walletconnect") => void;
};

export default function WalletSelector({
  isOpen,
  onClose,
  onSelect,
}: UseDisclosureProps & WalletSelectorProps) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="gray.800">
        <DrawerCloseButton color="white" />
        <DrawerHeader>
          <Text color="white">
            <FormattedMessage
              id="WalletSelector.selectWallet"
              defaultMessage="选择要连接并登录的钱包"
            />
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <Grid templateColumns="repeat(1, 1fr)" rowGap="16px" px="24px">
            <GridItem
              bgColor="#2980fe"
              rounded="50px"
              textColor="white"
              onClick={() => {
                onSelect("tokenpocket");
                onClose();
              }}
            >
              <Flex align="center" gap="8px">
                <Image
                  src="/img/icon-tokenpocket.webp"
                  boxSize="48px"
                  rounded="50%"
                  ml="8px"
                />
                <Text flex="1" textAlign="center" fontSize="large">
                  TokenPocket
                </Text>
              </Flex>
            </GridItem>
            <GridItem
              bgColor="#3396ff"
              rounded="50px"
              textColor="white"
              onClick={() => {
                onSelect("walletconnect");
                onClose();
              }}
            >
              <Flex align="center" gap="8px">
                <Image
                  src="/img/icon-walletconnect.png"
                  boxSize="48px"
                  rounded="50%"
                  ml="8px"
                />
                <Text flex="1" textAlign="center" fontSize="large">
                  Wallet Connect
                </Text>
              </Flex>
            </GridItem>
          </Grid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
