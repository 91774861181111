import {
  Box,
  Flex,
  Grid,
  Image,
  Spacer,
  Stack,
  Text,
  GridItem,
  useToast,
  ToastId,
  useDisclosure,
  Modal,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import { Planet } from "../../components/Planet";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../stores/global";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  BetInfo,
  GameResult,
  GameWin,
  Message,
  PushBetInfo,
} from "../../types/ws";
import { useGetBettingNumQuery } from "../../stores/api";
import { isArray, isEmpty } from "lodash";
import {
  GameNotification,
  GameNotificationProps,
} from "../../components/GameNotification";
import { BellIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { BrowserContextProvider } from "../../main";
import { playSound } from "./utils";

export function Guess() {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);
  const locale = useAppSelector((state) => state.locale);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    import.meta.env.VITE_GAME_WS_HOST,
    {
      protocols: `${token.split(" ")[1]}&${locale === "EN" ? "en" : "ch"}`,
      heartbeat: true,
    },
    !!token,
  );

  const browser = useContext(BrowserContextProvider);
  const os = useMemo(() => {
    return browser.os;
  }, [browser]);

  const [stars, setStars] = useState<Omit<BetInfo["game"], "id">>({
    star_1: "0.00",
    star_2: "0.00",
    star_3: "0.00",
    star_4: "0.00",
    star_5: "0.00",
    star_6: "0.00",
    star_7: "0.00",
    star_8: "0.00",
  });

  const [counts, setCounts] = useState<BetInfo["game_bet_num"]>({
    star_1: "0",
    star_2: "0",
    star_3: "0",
    star_4: "0",
    star_5: "0",
    star_6: "0",
    star_7: "0",
    star_8: "0",
  });

  const [betCounts, setBetCounts] = useState<BetInfo["personal_bet_num"]>({
    star_1: "",
    star_2: "",
    star_3: "",
    star_4: "",
    star_5: "",
    star_6: "",
    star_7: "",
    star_8: "",
  });

  const { data } = useGetBettingNumQuery(2);

  const toast = useToast();
  const toastRef = useRef<ToastId | undefined>();

  const [available, setAvailable] = useState("0");
  const [from, setFrom] = useState<"available" | "free">("available");
  const [betting, setBetting] = useState("1");
  const [selected, setSelected] = useState("");
  const [win, setWin] = useState("");
  const [lose, setLose] = useState("");
  const [bet, setBet] = useState("");
  const [lostStar, setLostStar] = useState("");

  const [id, setId] = useState(0);
  const [left, setLeft] = useState(0);
  const [lastInterval, setLastInterval] = useState<
    ReturnType<typeof setInterval> | undefined
  >();

  const [notification, setNotification] =
    useState<GameNotificationProps["title"]>("end");

  const { isOpen, onToggle, onClose } = useDisclosure();
  const notificationDisclosure = useDisclosure();
  const confirmDisclosure = useDisclosure();

  const connectionState = {
    [ReadyState.CONNECTING]: (
      <FormattedMessage defaultMessage="连接中" id="Guess.connecting" />
    ),
    [ReadyState.OPEN]: (
      <FormattedMessage defaultMessage="已连接" id="Guess.open" />
    ),
    [ReadyState.CLOSING]: (
      <FormattedMessage defaultMessage="断开中" id="Guess.closing" />
    ),
    [ReadyState.CLOSED]: (
      <FormattedMessage defaultMessage="已断开" id="Guess.closed" />
    ),
    [ReadyState.UNINSTANTIATED]: (
      <FormattedMessage defaultMessage="未初始化" id="Guess.uninstantiated" />
    ),
  }[readyState];

  useEffect(() => {
    if (toastRef.current) {
      toast.update(toastRef.current, { title: connectionState });
    } else {
      toastRef.current = toast({
        title: connectionState,
        duration: 500,
        position: "top",
      });
    }
    if (readyState === ReadyState.CLOSED) {
      navigate("/");
    }
  }, [readyState, toast, connectionState, navigate, sendJsonMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        class: "BettingService",
        function: "betInfo",
        params: {},
      });
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }
    const msg = lastJsonMessage as Message<unknown>;
    if (msg.type === "betInfo") {
      const betInfoMsg = msg as Message<BetInfo>;
      setAvailable(betInfoMsg.result.available);
      setId(betInfoMsg.result.game.id);
      setStars(betInfoMsg.result.game);
      setCounts(betInfoMsg.result.game_bet_num);
      setBetCounts(betInfoMsg.result.personal_bet_num);
      const timeout = parseInt(betInfoMsg.result.left_time);
      setLeft(timeout);
      if (lastInterval) {
        clearInterval(lastInterval);
      }
      const int = setInterval(() => {
        setLeft((value) => {
          if (value <= 0) {
            clearInterval(int);
            return 0;
          }
          return value - 1;
        });
      }, 1000);
      setLastInterval(int);
    } else if (
      msg.origin &&
      msg.origin.class === "BettingService" &&
      msg.origin.function === "betting"
    ) {
      if (msg.code !== 10000) {
        toast({ title: msg.msg, position: "top" });
      }
    } else if (msg.type === "finish") {
      if (isEmpty(msg.result)) {
        setWin("");
        setLose("");
        setBet("");
      }
      if (
        isArray(msg.result) &&
        msg.result.length > 0 &&
        msg.result?.[0] === "stop"
      ) {
        setNotification("offline");
        setWin("");
        setLose("");
        setBet("");
        setLostStar("");
        setTimeout(() => {
          notificationDisclosure.onClose();
        }, 3000);
      } else {
        setNotification("end");
      }
      notificationDisclosure.onOpen();
    } else if (msg.type === "start") {
      setWin("");
      setLose("");
      setBet("");
      setNotification("countdown");
      notificationDisclosure.onOpen();
      setLostStar("");
    } else if (msg.type === "gameWinNum") {
      const gameMsg = msg as Message<GameWin>;
      setWin(gameMsg.result.win_num);
      setLose(gameMsg.result.lose_num);
      setBet(gameMsg.result.bet_num);
      setNotification("end");
      notificationDisclosure.onOpen();
    } else if (msg.type === "pushBetInfo") {
      const pushBetInfoMsg = msg as Message<PushBetInfo>;
      setStars(pushBetInfoMsg.result.game);
      setCounts(pushBetInfoMsg.result.game_bet_num);
    } else if (msg.type === "gameResult") {
      const gameResultMsg = msg as Message<GameResult>;
      setLostStar(gameResultMsg.result.lose);
      notificationDisclosure.onClose();
    } else if (msg.type === "destroy") {
      setNotification("stop");
      notificationDisclosure.onOpen();
    } else if (msg.type === "begin") {
      notificationDisclosure.onClose();
    }
  }, [lastJsonMessage, toast]);

  const audioRef = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(true);

  return (
    <Stack
      width="100vw"
      height="100vh"
      bgImg="/img/game/bg.png"
      bgSize="contain"
      position="relative"
      alignItems="center"
      overflowX="hidden"
      gap="0"
    >
      <audio hidden src="/img/game/bgm.mp3" autoPlay loop ref={audioRef} />
      <Box position="relative">
        <Image
          src="/img/game/count.png"
          position="absolute"
          width="140px"
          maxWidth="fit-content"
        />
        <Text
          zIndex="10"
          color="#ff676e"
          fontWeight="900"
          py="6px"
          filter="drop-shadow(1px 1px 0px #162945)"
          fontSize="x-large"
          width="140px"
          textAlign="center"
        >
          {left}
        </Text>
      </Box>
      <Flex color="white" alignItems="start" width="100vw">
        <Stack marginX="8px" transform="auto" translateY="-30px">
          <Text fontSize="large">
            <FormattedMessage
              defaultMessage="第 {id} 期"
              values={{ id }}
              id="Game.Guess.round"
            />
          </Text>
          <Flex
            alignItems="center"
            gap="4px"
            rounded="16px"
            bg="#ffffff66"
            px="8px"
            py="2px"
            borderWidth={from === "available" ? "2px" : 0}
            borderColor="yellow"
            borderStyle="solid"
            onClick={() => {
              setFrom("available");
              playSound("asset");
            }}
          >
            <Image src="/img/game/coin.png" width="16px" height="16px" />
            <Text fontSize={{ base: "sm", sm: os === "iOS" ? "lg" : "sm" }}>
              可用IEBB {available}
            </Text>
          </Flex>
        </Stack>
        <Spacer />
        <Stack transform="auto" translateY="-50px">
          <Text
            fontSize={{ base: "sm", sm: os === "iOS" ? "lg" : "sm" }}
            transform="auto"
            translateY="5px"
            bgImg="/img/game/bg-record.png"
            bgSize="cover"
            bgRepeat="no-repeat"
            px="8px"
            py="4px"
            onClick={() => {
              navigate("/game/guess/record/2");
            }}
          >
            <FormattedMessage
              defaultMessage="游戏记录"
              id="Game.Guess.history"
            />
          </Text>
          {playing && (
            <BellIcon
              width="24px"
              height="24px"
              alignSelf="end"
              m="4px"
              onClick={() => {
                if (playing) {
                  audioRef.current.pause();
                  setPlaying(false);
                } else {
                  audioRef.current.play();
                  setPlaying(true);
                }
              }}
            />
          )}
          {!playing && (
            <Image
              src="/img/icon-bell-slash-solid.svg"
              width="24px"
              height="24px"
              alignSelf="end"
              m="4px"
              onClick={() => {
                if (playing) {
                  audioRef.current.pause();
                  setPlaying(false);
                } else {
                  audioRef.current.play();
                  setPlaying(true);
                }
              }}
            />
          )}
        </Stack>
      </Flex>
      <Grid
        alignItems="center"
        templateColumns="2fr 2fr"
        width="100%"
        mt="-40px"
        mb="100px"
        transform="auto"
        scale="0.9"
        translateY="-20px"
      >
        <GridItem
          scale="0.8"
          transform="auto"
          translateX={10}
          onClick={() => {
            setSelected("star_2");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/4.gif"
            name={
              <FormattedMessage defaultMessage="金星" id="Game.Guess.venus" />
            }
            amount={stars.star_2}
            width={120}
            selected={selected === "star_2"}
            count={counts.star_2}
            lost={lostStar === "star_2"}
            betAmount={betCounts.star_2}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateX={5}
          translateY={-2}
          onClick={() => {
            setSelected("star_1");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/8.gif"
            name={
              <FormattedMessage defaultMessage="木星" id="Game.Guess.jupiter" />
            }
            amount={stars.star_1}
            width={140}
            selected={selected === "star_1"}
            count={counts.star_1}
            lost={lostStar === "star_1"}
            betAmount={betCounts.star_1}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateY={-5}
          onClick={() => {
            setSelected("star_4");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/7.gif"
            name={
              <FormattedMessage defaultMessage="水星" id="Game.Guess.mercury" />
            }
            amount={stars.star_4}
            width={120}
            selected={selected === "star_4"}
            count={counts.star_4}
            lost={lostStar === "star_4"}
            betAmount={betCounts.star_4}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateX="-50px"
          translateY="-40px"
          onClick={() => {
            setSelected("star_3");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/3.gif"
            name={
              <FormattedMessage defaultMessage="火星" id="Game.Guess.mars" />
            }
            amount={stars.star_3}
            width={120}
            selected={selected === "star_3"}
            count={counts.star_3}
            lost={lostStar === "star_3"}
            betAmount={betCounts.star_3}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateX={10}
          translateY={-10}
          scale="0.8"
          onClick={() => {
            setSelected("star_6");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/5.gif"
            name={
              <FormattedMessage defaultMessage="地球" id="Game.Guess.earth" />
            }
            amount={stars.star_6}
            width={150}
            selected={selected === "star_6"}
            count={counts.star_6}
            lost={lostStar === "star_6"}
            betAmount={betCounts.star_6}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateY="-50px"
          scale="1.2"
          onClick={() => {
            setSelected("star_5");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/6.gif"
            name={
              <FormattedMessage
                defaultMessage="海王星"
                id="Game.Guess.neptune"
              />
            }
            amount={stars.star_5}
            width={130}
            selected={selected === "star_5"}
            count={counts.star_5}
            lost={lostStar === "star_5"}
            betAmount={betCounts.star_5}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateY="-60px"
          onClick={() => {
            setSelected("star_8");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/2.gif"
            name={
              <FormattedMessage defaultMessage="土星" id="Game.Guess.saturn" />
            }
            amount={stars.star_8}
            width={140}
            selected={selected === "star_8"}
            count={counts.star_8}
            lost={lostStar === "star_8"}
            betAmount={betCounts.star_8}
          />
        </GridItem>
        <GridItem
          transform="auto"
          translateY="-50px"
          onClick={() => {
            setSelected("star_7");
            playSound("planet");
          }}
        >
          <Planet
            img="/img/game/1.gif"
            name={
              <FormattedMessage
                defaultMessage="天王星"
                id="Game.Guess.uranus"
              />
            }
            amount={stars.star_7}
            width={150}
            selected={selected === "star_7"}
            count={counts.star_7}
            lost={lostStar === "star_7"}
            betAmount={betCounts.star_7}
          />
        </GridItem>
      </Grid>
      <Stack position="fixed" width="calc(100vw - 32px)" bottom="0">
        <Flex
          justifyContent="space-between"
          marginX="16px"
          gap="16px"
          style={{ display: isOpen ? "flex" : "none" }}
        >
          {data?.data?.betting?.map((item) => {
            return (
              <Box
                key={item}
                px="16px"
                py="4px"
                bgColor="#ffffff99"
                flex={1}
                rounded="4px"
                textAlign="center"
                fontSize="large"
                fontWeight="bold"
                onClick={() => {
                  setBetting(item);
                  playSound("bet");
                  onClose();
                }}
              >
                {item}
              </Box>
            );
          })}
        </Flex>
        <Flex
          rounded="12px"
          bgColor="#ffffff66"
          alignItems="center"
          margin="16px"
        >
          <Flex
            flex={1}
            margin="8px"
            bgColor="#ffffff66"
            borderWidth="0"
            rounded="8px"
            onClick={() => {
              onToggle();
            }}
            px="8px"
            py="4px"
            fontSize="large"
            fontWeight="bold"
            minH="36px"
          >
            <ChevronUpIcon width="24px" height="24px" />
            {betting}
          </Flex>
          <Text
            color="black"
            bgColor="#fad600"
            borderColor="white"
            borderStyle="solid"
            borderWidth="2px"
            rounded="8px"
            fontSize="small"
            fontWeight="900"
            px="16px"
            py="4px"
            margin="8px"
            onClick={() => {
              if (betting === "" || selected === "") {
                toast({
                  title: (
                    <FormattedMessage
                      defaultMessage="请选择金额和星球"
                      id="Game.Guess.choose"
                    />
                  ),
                  position: "top",
                });
                return;
              }
              playSound("bet");
              confirmDisclosure.onOpen();
            }}
          >
            <FormattedMessage id="Game.Guess.bet" defaultMessage="选择" />
          </Text>
        </Flex>
      </Stack>
      <GameNotification
        title={notification}
        win={win}
        lose={lose}
        bet={bet}
        isOpen={notificationDisclosure.isOpen}
        onClose={notificationDisclosure.onClose}
      />
      <Modal
        isOpen={confirmDisclosure.isOpen}
        onClose={confirmDisclosure.onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <Stack
          justifyContent="center"
          h="100%"
          color="white"
          position="fixed"
          top="0"
          zIndex="2000"
        >
          <Box position="relative">
            <Image src="/img/game/bg-notify.png" />
            <Text
              position="absolute"
              top="45%"
              left="calc(50% - 80px)"
              fontSize="xx-large"
              fontWeight="900"
              filter="drop-shadow(2px 2px 0 #062083)"
              rotate="-10deg"
              transform="auto"
            >
              <FormattedMessage
                defaultMessage="确认选择"
                id="Game.Guess.confirm"
              />{" "}
              {betting}
            </Text>
            <Flex
              position="absolute"
              top="65%"
              left="calc(50% - 60px)"
              transform="auto"
              rotate="-10deg"
            >
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  sendJsonMessage({
                    class: "BettingService",
                    function: "betting",
                    params: {
                      account_type:
                        from === "available" ? "available" : "robot_free",
                      game_type: 2,
                      num: betting,
                      obj_id: selected.replace("star_", ""),
                    },
                  });
                  playSound("bet");
                  confirmDisclosure.onClose();
                }}
              >
                <FormattedMessage defaultMessage="确认" id="Game.Guess.ok" />
              </Button>
              <Button
                variant="ghost"
                onClick={() => confirmDisclosure.onClose()}
              >
                <FormattedMessage
                  defaultMessage="取消"
                  id="Game.Guess.cancel"
                />
              </Button>
            </Flex>
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
}
