import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const tabSlice = createSlice({
  name: 'tabSlice',
  initialState: 0,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state = action.payload
      return state;
    }
  }
})

export default tabSlice.reducer;

export const { setTab } = tabSlice.actions;
