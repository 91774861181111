import {
  Container,
  Flex,
  Input,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SecondaryHeader from "../components/SecondaryHeader";
import { useEffect, useState } from "react";
import { GradientButton } from "../components/GradientButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../stores/global";
import { Abi, formatEther } from "viem";
import useAllowance from "../hooks/useAllowance";
import { USDT_BSC_ADDRESS, getBalance } from "../wallet/EtherWallet";
import useApprove from "../hooks/useApprove";
import { buyRobotThunk, depositThunk } from "../stores/wallet";
import { useGetPayAddressQuery } from "../stores/api";
import usdtABI from '../abi/usdt.json'

export default function Deposit() {
  const dispatch = useAppDispatch();
  const address = useAppSelector(
    (state) => state.auth.address,
  ) as `0x${string}`;
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(BigInt(0));

  const toast = useToast();

  const { loading: allowanceLoading, allowance, onAllance } = useAllowance(USDT_BSC_ADDRESS);
  const { loading: approveLoading, onApprove } = useApprove(usdtABI as Abi, USDT_BSC_ADDRESS);

  const { data } = useGetPayAddressQuery("charge");

  const [value, setValue] = useState("");

  useEffect(() => {
    if (address) {
      setLoading(true);
      getBalance(address, USDT_BSC_ADDRESS).then((data: bigint) => {
        setBalance(data);
        setLoading(false);
      });
    }
  }, [address]);

  useEffect(() => {
    if (data?.data) {
      onAllance(data.data as `0x${string}`, false);
    }
  }, [onAllance, data?.data]);

  return (
    <Container pos="relative">
      <SecondaryHeader
        title={<FormattedMessage id="Deposit.title" defaultMessage="充币" />}
      />
      <Flex gap="12px" direction="column" height="70vh">
        <Flex
          bgColor="#ffffff22"
          px="12px"
          py="24px"
          textColor="white"
          alignItems="center"
          rounded="12px"
        >
          <Stack>
            <Text fontSize="small">
              USDT
            </Text>
            {loading ? (
              <Spinner />
            ) : (
              <Text fontSize="xx-large" fontWeight="bold">
                {parseFloat(formatEther(balance)).toFixed(4)}
              </Text>
            )}
          </Stack>
        </Flex>
        <Spacer />
        <Text color="white" alignSelf="center">
          <FormattedMessage
            id="Deposit.input"
            defaultMessage="请输入充币金额"
          />
        </Text>
        <Input
          width="80%"
          alignSelf="center"
          size="lg"
          textColor="white"
          rounded="100px"
          mb="32px"
          value={value}
          type="number"
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {allowance === BigInt(0) && (
          <GradientButton
            alignSelf="center"
            width="80%"
            fontSize="large"
            height="60px"
            onClick={async () => {
              if (allowanceLoading || approveLoading || loading) return;
              if (!data.data) {
                return;
              }
              const result = await onApprove(data.data);
              if (result) {
                await onAllance(data.data as `0x${string}`, true);
              }
            }}
          >
            {!address || allowanceLoading || approveLoading || loading ? (
              <Spinner />
            ) : (
              <FormattedMessage id="Deposit.approve" defaultMessage="授权" />
            )}
          </GradientButton>
        )}
        {allowance !== BigInt(0) && (
          <GradientButton
            alignSelf="center"
            width="80%"
            fontSize="large"
            height="60px"
            onClick={async () => {
              if (!data.data) {
                toast({
                  title: "获取充币地址失败，请刷新页面或者稍后再试",
                });
                return;
              }
              setSending(true);
              const resultAction = await dispatch(
                depositThunk({
                  amount: String(value),
                  targetAddress: data.data,
                  abi: usdtABI as Abi,
                  contract: USDT_BSC_ADDRESS,
                }),
              );
              if (depositThunk.fulfilled.match(resultAction)) {
                toast({
                  title: "充币成功",
                  duration: 2000,
                  isClosable: false,
                  status: "success",
                  onCloseComplete() {
                    navigate(-1);
                  },
                });
              } else if (buyRobotThunk.rejected.match(resultAction)) {
                toast({
                  title: "充币失败",
                  duration: 2000,
                  status: "error",
                });
              }
              setSending(false);
            }}
          >
            {sending && <Spinner />}
            {!sending && (
              <>
                <FormattedMessage
                  id="Deposit.confirm"
                  defaultMessage="确认支付 "
                />{" "}
                {value || ""} U
              </>
            )}
          </GradientButton>
        )}
      </Flex>
    </Container>
  );
}
