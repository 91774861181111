import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "./global";

const locale = createSlice({
  name: "locale",
  initialState: localStorage.getItem('locale') || 'CN',
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state = action.payload;
      localStorage.setItem('locale', state);
      return state;
    },
  },
});

export default locale.reducer;

export const { setLocale } = locale.actions;

export const useSuffix = () => useAppSelector((state) => state.locale === 'EN' ? '-en' : '');
