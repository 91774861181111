import { Outlet, useSearchParams } from "react-router-dom";
import ClientProvider from "./components/ClientProvider";
import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Header from "./components/Header";
import { useAppSelector } from "./stores/global";

export default function App() {
  const [searchParams] = useSearchParams();
  const address = useAppSelector((state) => state.auth.address);
  const token = useAppSelector((state) => state.auth.token);
  useEffect(() => {
    if (searchParams.get("invite_code")) {
      localStorage.setItem("invitecode", searchParams.get("invite_code"));
    }
  });
  return (
    <Box width="100vw" height="100vh">
      <ClientProvider />
      {address && token ? (
        <Outlet />
      ) : (
        <>
          <Header />
          <Stack
            pos="relative"
            h="60vh"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="#58ffad" fontFamily="serif" fontSize="xx-large">
              FixBox
            </Text>
            <Image
              justifySelf="center"
              pos="relative"
              src="/img/bg-home.png"
              width="50%"
            />
          </Stack>
        </>
      )}
      {import.meta.env.MODE !== "production" && (
        <Box
          pos="fixed"
          left={-12}
          top={-5}
          px="35px"
          pt="40px"
          bgColor="blue.300"
          transform="rotate(-45deg)"
          opacity="0.6"
        >
          测试
        </Box>
      )}
    </Box>
  );
}

