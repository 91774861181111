import {
  Flex,
  Image,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../stores/global";
import { setTab } from "../stores/tabIndex";

export type Props = {
  children: ReactNode;
};

export default function BottonNav({ children }: Props) {
  const index = useAppSelector((state) => state.tabIndex);
  const dispatch = useAppDispatch();

  const handleTabsChange = (index: number) => {
    dispatch(setTab(index));
  };

  return (
    <Tabs isFitted variant="unstyled" onChange={handleTabsChange} index={index}>
      <TabPanels height={"100dvh"}>{children}</TabPanels>
      <TabList
        position={"fixed"}
        bottom={0}
        width={"100%"}
        bg="#183e47"
        textColor="white"
      >
        <Tab>
          <Flex direction="column" alignItems="center">
            {index !== 0 && (
              <Image
                src="/img/icon-tab-home.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            {index === 0 && (
              <Image
                src="/img/icon-tab-sel-home.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            <Text mt="4px" fontSize="small" color="white">
              <FormattedMessage id="BottomNav.home" defaultMessage="首页" />
            </Text>
          </Flex>
        </Tab>
        <Tab>
          <Flex direction="column" alignItems="center">
            {index !== 1 && (
              <Image
                src="/img/icon-tab-game.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            {index === 1 && (
              <Image
                src="/img/icon-tab-sel-game.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            <Text mt="4px" fontSize="small" color="white">
              <FormattedMessage id="BottomNav.game" defaultMessage="游戏" />
            </Text>
          </Flex>
        </Tab>
        <Tab>
          <Flex direction="column" alignItems="center">
            {index !== 2 && (
              <Image
                src="/img/icon-inscription-2.png"
                alt="home"
                width="20px"
                fit="contain"
              />
            )}
            {index === 2 && (
              <Image
                src="/img/icon-inscription.png"
                alt="home"
                width="20px"
                fit="contain"
              />
            )}
            <Text mt="4px" fontSize="small" color="white">
              <FormattedMessage
                id="BottomNav.inscription"
                defaultMessage="铭文"
              />
            </Text>
          </Flex>
        </Tab>
        <Tab>
          <Flex direction="column" alignItems="center">
            {index !== 3 && (
              <Image
                src="/img/icon-tab-asset.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            {index === 3 && (
              <Image
                src="/img/icon-tab-sel-asset.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            <Text mt="4px" fontSize="small" color="white">
              <FormattedMessage id="BottomNav.asset" defaultMessage="币种" />
            </Text>
          </Flex>
        </Tab>
        <Tab>
          <Flex direction="column" alignItems="center">
            {index !== 4 && (
              <Image
                src="/img/icon-tab-profile.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            {index === 4 && (
              <Image
                src="/img/icon-tab-sel-profile.png"
                alt="home"
                boxSize="20px"
                fit="contain"
              />
            )}
            <Text mt="4px" fontSize="small" color="white">
              <FormattedMessage id="BottomNav.profile" defaultMessage="我的" />
            </Text>
          </Flex>
        </Tab>
      </TabList>
    </Tabs>
  );
}
