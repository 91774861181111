import { useEffect, useRef, useState } from "react";
import { useGetKLineQuery } from "../stores/api";
import {
  Flex,
  Stack,
  Text,
  Menu,
  MenuButton,
  Spacer,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { last } from "lodash";
import { useAppSelector } from "../stores/global";

export function KLine({ type }: { type: string }) {
  const [day, setDay] = useState<1 | 7 | 30>(1);

  const KLine = useGetKLineQuery({ type, day });

  const chartBox = useRef<HTMLDivElement>(null);

  const index = useAppSelector((state) => state.tabIndex);

  useEffect(() => {
    if (chartBox.current && index === 2) {
      chartBox.current.scrollBy(KLine.data?.data.Kline.length * 50, 0);
    }
  }, [KLine.data?.data, index]);

  return (
    KLine.data && (
      <Stack bg="#ffffff33" rounded="16px" px="16px" py="8px" width="100%">
        <Flex alignItems="start">
          <Stack gap="0">
            <Text color="white" fontSize="small" fontWeight="bold">
              <FormattedMessage
                id="BottomNav.inscription"
                defaultMessage="铭文"
              />
              {type == "1" ? "IECC": "IEBB"}
              <FormattedMessage
                id="Home.todayPrice"
                defaultMessage="今日行情"
              />{" "}
              涨幅
              <Text
                as="span"
                textColor={
                  parseFloat(KLine?.data?.data.increase) > 0 ? "#0aff4a" : "red"
                }
              >
                {KLine?.data?.data.increase}%
              </Text>
            </Text>
            <Text color="white" fontSize="small">
              {type == "1" ? "1IECC": "1IEBB"} = {last(KLine?.data?.data.Kline).amount}USDT
            </Text>
          </Stack>
          <Spacer />
          <Menu>
            <MenuButton color="white" fontSize="small">
              {day} <FormattedMessage id="Index.Home.day" defaultMessage="天" />
              <ChevronDownIcon />
            </MenuButton>
            <MenuList
              rootProps={{
                minWidth: "80px !important",
                maxWidth: "80px",
                width: "80px",
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <MenuItem
                width="80px"
                color="black"
                onClick={() => {
                  setDay(1);
                }}
              >
                1
                <FormattedMessage id="Index.Home.day" defaultMessage="天" />
              </MenuItem>
              <MenuItem
                width="80px"
                color="black"
                onClick={() => {
                  setDay(7);
                }}
              >
                7
                <FormattedMessage id="Index.Home.day" defaultMessage="天" />
              </MenuItem>
              <MenuItem
                width="80px"
                color="black"
                onClick={() => {
                  setDay(30);
                }}
              >
                30
                <FormattedMessage id="Index.Home.day" defaultMessage="天" />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Box overflow="scroll" ref={chartBox}>
          <AreaChart
            width={Math.max(400, KLine.data?.data.Kline.length * 50)}
            height={200}
            data={KLine.data.data.Kline.map((value) => {
              return {
                amount: parseFloat(value.amount),
                ctime: value.ctime,
              };
            })}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="white" stopOpacity={0.4} />
                <stop offset="85%" stopColor="white" stopOpacity={0.1} />
                <stop offset="100%" stopColor="white" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip
              position={{ y: 10 }}
              defaultIndex={KLine.data.data.Kline.length - 1}
              labelStyle={{ display: "none" }}
              contentStyle={{ background: "transparent", borderWidth: 0 }}
              separator=""
              formatter={(value) => {
                return [(value as number).toFixed(4), ""];
              }}
              itemStyle={{
                borderRadius: 16,
                color: "black",
                padding: "2px 4px",
                background: "#a9fc72",
                minWidth: "50px",
                textAlign: "center",
                fontSize: "10px",
              }}
              offset={20}
            />
            <Area
              type="monotone"
              dataKey="amount"
              stroke="#a6fc74"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              strokeLinecap="round"
            />
            <XAxis dataKey="ctime" fontSize="x-small" stroke="#c3c3c3" />
            <YAxis
              strokeWidth="0"
              width={30}
              domain={[0, "dataMax + 10"]}
              fontSize="small"
              stroke="#c3c3c3"
            />
            <CartesianGrid
              vertical={false}
              stroke="#fff"
              strokeLinecap="round"
            />
          </AreaChart>
        </Box>
      </Stack>
    )
  );
}
