import { FormattedMessage } from "react-intl";

export function numToStar(num: number) {
  switch (num) {
    case 1:
      return <FormattedMessage defaultMessage="木星" id="Game.Guess.jupiter" />;
    case 2:
      return <FormattedMessage defaultMessage="金星" id="Game.Guess.venus" />;
    case 3:
      return <FormattedMessage defaultMessage="火星" id="Game.Guess.mars" />;
    case 4:
      return <FormattedMessage defaultMessage="水星" id="Game.Guess.mercury" />;
    case 5:
      return (
        <FormattedMessage defaultMessage="海王星" id="Game.Guess.neptune" />
      );
    case 6:
      return <FormattedMessage defaultMessage="地球" id="Game.Guess.earth" />;
    case 7:
      return (
        <FormattedMessage defaultMessage="天王星" id="Game.Guess.uranus" />
      );
    case 8:
      return <FormattedMessage defaultMessage="土星" id="Game.Guess.saturn" />;
  }
  return "";
}

export function numToSide(num: number) {
  switch (num) {
    case 1:
      return <FormattedMessage defaultMessage="忍者" id="Game.Guess.redSide" />;
    case 2:
      return <FormattedMessage defaultMessage="战士" id="Game.Guess.blueSide" />;
    case 3:
      return <FormattedMessage defaultMessage="和平使者" id="Game.Guess.yellowSide" />;
    case 4:
      return <FormattedMessage defaultMessage="魔法师" id="Game.Guess.guessSide" />;
  }
  return "";
}

export function playSound(type: "bet" | "asset" | "planet") {
  switch (type) {
    case "bet":
      new Audio("/img/game/bet.mp3").play();
      return;
    case "asset":
      new Audio("/img/game/asset.mp3").play();
      return;
    case "planet":
      new Audio("/img/game/planet.mp3").play();
      return;
  }
}

