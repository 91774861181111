import {
  Container,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SecondaryHeader from "../../components/SecondaryHeader";
import { useEffect, useState } from "react";
import { GradientButton } from "../../components/GradientButton";
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../stores/global";
import { Abi, formatEther } from "viem";
import useAllowance from "../../hooks/useAllowance";
import { USDT_BSC_ADDRESS, getBalance } from "../../wallet/EtherWallet";
import useApprove from "../../hooks/useApprove";
import { buyRobotThunk } from "../../stores/wallet";
import {
  Api,
  useGetPayAddressQuery,
  usePostBuyRobotMutation,
} from "../../stores/api";
import usdtABI from '../../abi/usdt.json';

export default function Buy() {
  const dispatch = useAppDispatch();
  const [searchPrams] = useSearchParams();
  const address = useAppSelector(
    (state) => state.auth.address,
  ) as `0x${string}`;
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(BigInt(0));

  const toast = useToast();

  const { loading: allowanceLoading, allowance, onAllance } = useAllowance(USDT_BSC_ADDRESS);
  const { loading: approveLoading, onApprove } = useApprove(usdtABI as Abi, USDT_BSC_ADDRESS);

  const { data } = useGetPayAddressQuery("robot");

  const [postBuyRobot] = usePostBuyRobotMutation();

  useEffect(() => {
    if (address) {
      setLoading(true);
      getBalance(address, USDT_BSC_ADDRESS).then((data: bigint) => {
        setBalance(data);
        setLoading(false);
      });
    }
  }, [address]);

  useEffect(() => {
    if (data?.data) {
      onAllance(data.data as `0x${string}`, false);
    }
  }, [onAllance, data?.data]);

  return (
    <Container pos="relative">
      <SecondaryHeader
        title={<FormattedMessage id="Robot.Buy.title" defaultMessage="兑换" />}
      />
      <Flex gap="12px" direction="column" height="70vh">
        <Flex
          bgColor="#ffffff22"
          px="12px"
          py="24px"
          textColor="white"
          alignItems="center"
          rounded="12px"
        >
          <Stack>
            <Text fontSize="small">
              USDT
              <FormattedMessage id="Robot.Buy.account" defaultMessage="账户" />
            </Text>
            {loading ? (
              <Spinner />
            ) : (
              <Text fontSize="xx-large" fontWeight="bold">
                {parseFloat(formatEther(balance)).toFixed(4)}
              </Text>
            )}
          </Stack>
          <Spacer />
          <GradientButton
            boxSize="28px"
            rounded="50%"
            paddingInline="0"
            p="0"
            minWidth="28px"
          >
            <CheckIcon boxSize="16px" />
          </GradientButton>
        </Flex>
        <Spacer />
        {allowance === BigInt(0) && (
          <GradientButton
            alignSelf="center"
            width="80%"
            fontSize="large"
            height="60px"
            onClick={async () => {
              if (allowanceLoading || approveLoading || loading) return;
              if (!data.data) {
                return;
              }
              const result = await onApprove(data.data);
              if (result) {
                await onAllance(data.data as `0x${string}`, true);
              }
            }}
          >
            {!address || allowanceLoading || approveLoading || loading ? (
              <Spinner />
            ) : (
              <FormattedMessage id="Robot.Buy.approve" defaultMessage="授权" />
            )}
          </GradientButton>
        )}
        {allowance !== BigInt(0) && (
          <GradientButton
            alignSelf="center"
            width="80%"
            fontSize="large"
            height="60px"
            onClick={async () => {
              if (!data.data) {
                toast({
                  title: "获取充币地址失败，请刷新页面或者稍后再试",
                });
                return;
              }
              setSending(true);
              const { data: checkData } = await dispatch(
                Api.endpoints.getBuyCheck.initiate(
                  parseInt(searchPrams.get("id")),
                ),
              );
              if (!checkData.data) {
                toast({
                  title: "无法购买",
                  status: "error",
                });
                return;
              }
              const resultAction = await dispatch(
                buyRobotThunk({
                  amount: searchPrams.get("price"),
                  targetAddress: data.data,
                }),
              );
              if (buyRobotThunk.fulfilled.match(resultAction)) {
                const data = await postBuyRobot({
                  id: searchPrams.get("id"),
                  hash: resultAction.payload as unknown as string,
                }).unwrap();
                if (data.data) {
                  navigate(-1);
                  toast({
                    title: "购买成功",
                    duration: 2000,
                    isClosable: false,
                    status: "success",
                  });
                } else {
                  toast({
                    title: "购买失败",
                    duration: 2000,
                    status: "error",
                  });
                }
              } else if (buyRobotThunk.rejected.match(resultAction)) {
                toast({
                  title: "购买失败",
                  duration: 2000,
                  status: "error",
                });
              }
              setSending(false);
            }}
          >
            {sending && <Spinner />}
            {!sending && (
              <>
                <FormattedMessage
                  id="Robot.Buy.confirm"
                  defaultMessage="确认支付 "
                />
                {searchPrams.get("price")} U
              </>
            )}
          </GradientButton>
        )}
      </Flex>
    </Container>
  );
}
