import {
  Box,
  Divider,
  Flex,
  Image,
  Skeleton,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useGetHomeCarouselQuery } from "../../stores/api";
import { Carousel } from "react-responsive-carousel";
import { FormattedMessage } from "react-intl";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useAppSelector } from "../../stores/global";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { isLoading, data, refetch } = useGetHomeCarouselQuery();
  const token = useAppSelector((state) => state.auth.token);
  const locale = useAppSelector((state) => state.locale);
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [locale, token, refetch]);

  return (
    <Stack pb="100px" gap="0px">
      <div>{isLoading && <Skeleton height="100px" />}</div>
      {data?.data && (
        <Stack gap="20px">
          <Box rounded="16px" clipPath={"content-box"}>
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              autoPlay
              infiniteLoop
              dynamicHeight
            >
              {data.data.carousel.map((item) => {
                return (
                  <Box key={item}>
                    <Image src={item} />
                  </Box>
                );
              })}
            </Carousel>
          </Box>
          <Box
            rounded="100px"
            bg="#282636"
            py="8px"
            px="16px"
            onClick={() => {
              navigate("/notice/list");
            }}
          >
            <Flex flexDirection={"row"} alignItems="center" gap="8px">
              <Image src={"/img/icon-notice.png"} width="20px" />
              <Flex>
                <Text lineHeight="25px" textColor="white" color="#70fdb0">
                  <FormattedMessage
                    id="Home.home.system"
                    defaultMessage="系统"
                  />
                </Text>
                <Text lineHeight="25px" textColor="white">
                  <FormattedMessage
                    id="Home.home.notice"
                    defaultMessage="公告"
                  />
                </Text>
              </Flex>
              <Divider orientation="vertical" color="gray" height="16px" />
              <Text color="white">{data.data.notice.title}</Text>
              <Spacer />
              <ChevronRightIcon
                color="black"
                bg="#c1fc58"
                rounded="50%"
                boxSize="20px"
              />
            </Flex>
          </Box>
          <Box
            bgImage="/img/bg-iebb-pool.png"
            bgRepeat="no-repeat"
            bgPos="center"
            bgSize="contain"
            height="150px"
            textColor="white"
            onClick={() => navigate("/iebb/join")}
          >
            <Flex padding="16px" align="center">
              <Stack align="start" justify="center">
                <Text fontSize="24">
                  <FormattedMessage
                    id="Home.home.total"
                    defaultMessage="DeFi IEBB-POOL"
                  />
                </Text>
                <Text fontSize="24">
                  <FormattedMessage
                    id="Home.home.rush"
                    defaultMessage="进入    抢"
                  />
                </Text>
              </Stack>
              <Spacer />
              <Image src="/img/ic-iebb-arrow.png" width="40px" height="40px" />
            </Flex>
          </Box>
          <Flex gap="16px">
            <Box
              width="50%"
              position="relative"
              onClick={() => {
                navigate("/pool/liquidity/join");
              }}
            >
              <Image src="/img/bg-machine.png" />
              <Stack pos="absolute" top="8px" left="16px">
                <Text>
                  IEBB<FormattedMessage
                    id="Home.home.robot"
                    defaultMessage="流动矿池"
                  />
                </Text>
                <Image src="/img/ic-left-arrow.png" width="24px" />
              </Stack>
            </Box>
            <Box
              width="50%"
              position="relative"
              onClick={() => {
                navigate("/pool/join");
              }}
            >
              <Image src="/img/bg-pool.png" />
              <Stack pos="absolute" top="8px" left="16px">
                <Text>
                  <FormattedMessage
                    id="Home.home.pool"
                    defaultMessage="IEB矿池"
                  />
                </Text>
                <Image src="/img/ic-left-arrow.png" width="24px" />
              </Stack>
            </Box>
          </Flex>
          <Flex gap="16px" color="white">
            <Stack
              bgColor="#1c1929"
              borderRadius="24px"
              width="50%"
              aspectRatio={1.3}
              alignItems="center"
              justifyContent="center"
              gap="32px"
              onClick={() => {
                navigate("/invite");
              }}
            >
              <Image width="32px" src="/img/ic-invite.png" />
              <Text>
                <FormattedMessage
                  id="Home.home.invite"
                  defaultMessage="邀请好友"
                />
              </Text>
            </Stack>
            <Stack
              bgColor="#1c1929"
              borderRadius="24px"
              width="50%"
              aspectRatio={1.3}
              alignItems="center"
              justifyContent="center"
              gap="32px"
              onClick={() => {
                navigate("/swap");
              }}
            >
              <Image width="32px" src="/img/ic-exchange.png" />
              <Text>
                <FormattedMessage
                  id="Home.home.exchange"
                  defaultMessage="闪兑"
                />
              </Text>
            </Stack>
          </Flex>
          <Flex gap="16px" color="white">
            <Stack
              bgColor="#1c1929"
              borderRadius="24px"
              width="50%"
              aspectRatio={1.3}
              alignItems="center"
              justifyContent="center"
              gap="32px"
              onClick={() => {
                navigate("/feedback");
              }}
            >
              <Image width="32px" src="/img/ic-help.png" />
              <Text>
                <FormattedMessage
                  id="Home.home.help"
                  defaultMessage="帮助中心"
                />
              </Text>
            </Stack>
          </Flex>
        </Stack>
      )}
    </Stack>
  );
}
