import "core-js/full";
import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Box, ChakraProvider, Image } from "@chakra-ui/react";

import { Provider } from "react-redux";
import { store } from "./stores/global";
import { IntlWrapper } from "./components/IntlWrapper";
import theme from "./theme";
import frowser from "frowser";
import { routes } from "./routes";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://6ce778c1b83c37c00f9ae022dd4f9163@o782786.ingest.us.sentry.io/4507252305428480",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: [/^https:\/\/ieb\.ink/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBroswerRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBroswerRouter(routes);

export interface BrowserContext {
  os: string;
}

export const BrowserContextProvider = createContext<BrowserContext>({ os: "" });

const browser = frowser.parse(window.navigator.userAgent);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserContextProvider.Provider value={{ os: browser.os.name }}>
      <Provider store={store}>
        <IntlWrapper>
          <ChakraProvider theme={theme}>
            <Box
              position={"fixed"}
              width={"100%"}
              height={"100%"}
              top={0}
              bgColor="#161324"
            >
              <Image
                src="/img/bg-gradient.png"
                alt="bg-gradient"
                height="300px"
                width="100%"
              />
            </Box>
            <RouterProvider router={router} />
          </ChakraProvider>
        </IntlWrapper>
      </Provider>
    </BrowserContextProvider.Provider>
  </React.StrictMode>,
);
