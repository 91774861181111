import {
  Flex,
  Spacer,
  Stack,
  StackItem,
  Text,
  Grid,
  GridItem,
  useDisclosure,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  Button,
  ModalContent,
} from "@chakra-ui/react";
import {
  useGetAssetQuery,
  useGetNiceOpenQuery,
  useIebTransferMutation,
  usePostCaptchaMutation,
} from "../../stores/api";
import { FormattedMessage } from "react-intl";
import OutlineButton from "../../components/OutlineButton";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../stores/global";
import { Link } from "react-router-dom";
import Exchange, { ExchangeProps } from "../asset/Exchange";
import Withdraw, { WithdrawProps } from "../asset/Withdraw";
import QueryPage from "../../components/QueryPage";
import { InnerTransfer } from "../asset/InnerTransfer";
import { copyToClipboard } from "../../copy";
import { metrics } from "@sentry/react";

export default function AssetTab() {
  const { isFetching, error, data, refetch } = useGetAssetQuery();
  const token = useAppSelector((state) => state.auth.token);

  const [exchange, setExchange] = useState<ExchangeProps | undefined>();
  const [withdraw, setWithdraw] = useState<WithdrawProps | undefined>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const withdrawClosure = useDisclosure();
  const innerTransferDisclosure = useDisclosure();
  const iebOutDisclosure = useDisclosure();

  const toast = useToast();

  const [postCaptcha] = usePostCaptchaMutation();
  const [isGetCaptcha, setIsGetCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const address = useAppSelector((state) => state.auth.address);
  const [iebTransfer] = useIebTransferMutation();
  const niceOpen = useGetNiceOpenQuery();

  useEffect(() => {
    refetch();
  }, [token, refetch]);

  useEffect(() => {
    if (data?.data) {
      metrics.distribution(
        "asset-usdt",
        Number(
          data?.data?.find((item) => item.coin_name === "USDT")?.available ?? 0,
        ),
        {
          tags: {
            address,
          },
        },
      );
      metrics.distribution(
        "asset-ieb",
        Number(
          data?.data?.find((item) => item.coin_name === "IEB")?.available ?? 0,
        ),
        {
          tags: {
            address,
          },
        },
      );
    }
  }, [data?.data, address]);

  return (
    <>
      <QueryPage
        error={error}
        hasMore={false}
        refetch={refetch}
        isLoading={isFetching}
        errorMessage={
          <FormattedMessage
            id="Asset.error"
            defaultMessage="币种下载失败，请刷新重试"
          />
        }
        px="0"
        pb="80px"
      >
        {data?.data
          ?.filter((item) => item.coin_name !== "IEC")
          ?.map((item) => {
            return (
              <Stack
                key={item.coin_name}
                bg="#ffffff11"
                textColor="white"
                rounded="8px"
                px="12px"
                py="20px"
                mb="20px"
              >
                <StackItem>
                  <Flex>
                    {item.coin_name === "IECC" && (
                      <Text mr="4px">
                        <FormattedMessage
                          id="BottomNav.inscription"
                          defaultMessage="铭文"
                        />
                      </Text>
                    )}
                    <Text>{item.coin_name}</Text>
                    <Spacer />
                    <Link to={`/transaction/${item.coin_name}`}>
                      <Text
                        bg="#ffffff33"
                        rounded="100px"
                        px="12px"
                        py="8px"
                        fontSize="small"
                      >
                        <FormattedMessage
                          id="Home.Asset.log"
                          defaultMessage="明细"
                        />
                      </Text>
                    </Link>
                  </Flex>
                </StackItem>
                <StackItem>
                  <Grid gap="12px" templateColumns="repeat(2, 1fr)">
                    {item.coin_name === "USDT" && (
                      <>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                        >
                          <Text color="gray.400" fontSize="small">
                            <FormattedMessage
                              id="Home.Asset.assetUSDT"
                              defaultMessage="可用USDT"
                            />
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.available}
                          </Text>
                        </GridItem>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                        >
                          <Text color="gray.400" fontSize="small">
                            <FormattedMessage
                              id="Home.Asset.withdrawing"
                              defaultMessage="提币中"
                            />
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.disabled}
                          </Text>
                        </GridItem>
                        {parseFloat(item.welfare) > 0 && (
                          <GridItem
                            alignItems="center"
                            flex="1"
                            bg="#ffffff11"
                            rounded="12px"
                            py="12px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Text color="gray.400" fontSize="small">
                              <FormattedMessage
                                id="Home.Asset.freeze"
                                defaultMessage="冻结中"
                              />
                            </Text>
                            <Text color="#a2fc79" fontWeight="bold">
                              {item.welfare}
                            </Text>
                          </GridItem>
                        )}
                      </>
                    )}
                    {item.coin_name === "IEB" && (
                      <>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                        >
                          <Text color="gray.400" fontSize="small">
                            <FormattedMessage
                              id="Home.Asset.avaiable"
                              defaultMessage="可用IEB"
                            />
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.available}
                          </Text>
                        </GridItem>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                        >
                          <Text color="gray.400" fontSize="small">
                            <FormattedMessage
                              id="Home.Asset.flowing"
                              defaultMessage="流动IEB"
                            />
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.robot_free}
                          </Text>
                        </GridItem>
                      </>
                    )}
                    {item.coin_name === "IEBB" && (
                      <>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                          colSpan={1}
                        >
                          <Text color="gray.400" fontSize="small">
                            可用IEBB
                          </Text>
                          <Text
                            color="#a2fc79"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {item.available} ≈ {item.price}U
                          </Text>
                          <Text color="gray.400" fontSize="small">
                            可用NICE-COIN
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {
                              data?.data?.find(
                                (e) => e.coin_name === "NICE-COIN",
                              ).available
                            }
                          </Text>
                        </GridItem>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                          colSpan={1}
                        >
                          <Text color="gray.400" fontSize="small">
                            IEBB总算力1
                          </Text>
                          <Text
                            color="#a2fc79"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {item.lock_up}
                          </Text>
                          <Text color="gray.400" fontSize="small">
                            IEBB总算力2
                          </Text>
                          <Text
                            color="#a2fc79"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {item.lock_up_two}
                          </Text>
                        </GridItem>
                      </>
                    )}
                    {item.coin_name === "IECC" && (
                      <>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                          colSpan={2}
                        >
                          <Text color="gray.400" fontSize="small">
                            <FormattedMessage
                              id="Home.Asset.assetIECC"
                              defaultMessage="可用IECC"
                            />
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.available} ≈ {item.price}U
                          </Text>
                        </GridItem>
                      </>
                    )}
                    {item.coin_name === "NICE-COIN" && (
                      <>
                        <GridItem
                          alignItems="center"
                          flex="1"
                          bg="#ffffff11"
                          rounded="12px"
                          py="12px"
                          display="flex"
                          flexDirection="column"
                          colSpan={2}
                        >
                          <Text color="gray.400" fontSize="small">
                            可用NICE-COIN
                          </Text>
                          <Text color="#a2fc79" fontWeight="bold">
                            {item.available}
                          </Text>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </StackItem>
                <StackItem mt="8px">
                  {item.coin_name === "USDT" && (
                    <Stack
                      flexDirection="row"
                      justifyContent="end"
                      opacity="0.8"
                    >
                      <OutlineButton
                        onClick={() => {
                          setExchange({
                            from: "USDT",
                            to: "IEB",
                            available: item.available,
                            onSuccess: () => {
                              refetch();
                            },
                          });
                          onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.swap"
                          defaultMessage="闪兑"
                        />
                        IEB
                      </OutlineButton>
                    </Stack>
                  )}
                  {item.coin_name === "IEB" && (
                    <Flex gap="8px" align="center" opacity="0.8">
                      <OutlineButton
                        onClick={() => {
                          iebOutDisclosure.onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.out"
                          defaultMessage="划转"
                        />
                      </OutlineButton>
                      <Spacer />
                      <OutlineButton
                        onClick={() => {
                          setExchange({
                            from: "IEB",
                            to: "IEBB",
                            available: item.available,
                            onSuccess: () => {
                              refetch();
                            },
                          });
                          onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.swap"
                          defaultMessage="闪兑"
                        />
                        IEBB
                      </OutlineButton>
                    </Flex>
                  )}
                  {item.coin_name === "IEBB" && (
                    <Flex gap="8px" align="center" opacity="0.8">
                      <OutlineButton
                        onClick={() => {
                          setExchange({
                            from: "IEBB",
                            to: "NICE-COIN",
                            available: item.available,
                            onSuccess: () => {
                              refetch();
                            },
                          });
                          onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.swap"
                          defaultMessage="闪兑"
                        />
                        NICE-COIN
                      </OutlineButton>
                      <OutlineButton
                        onClick={() => {
                          innerTransferDisclosure.onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.transfer"
                          defaultMessage="互赠"
                        />
                      </OutlineButton>
                      <OutlineButton
                        onClick={async () => {
                          setIsGetCaptcha(true);
                          try {
                            const response = await postCaptcha().unwrap();
                            if (response.data) {
                              setCaptcha(response.data);
                            }
                          } catch (error) {
                            toast({
                              title: (
                                <FormattedMessage
                                  id="Home.Asset.captchaError"
                                  defaultMessage="获取验证码失败"
                                />
                              ),
                              status: "error",
                            });
                          }
                          setIsGetCaptcha(false);
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.captcha"
                          defaultMessage="验证码"
                        />
                      </OutlineButton>
                      {isGetCaptcha && <Spinner size="sm" />}
                      {!isGetCaptcha && (
                        <Text
                          color="red"
                          fontWeight="bold"
                          onClick={() => {
                            if (copyToClipboard(captcha)) {
                              toast({
                                title: (
                                  <FormattedMessage
                                    id="Home.Asset.copySuccess"
                                    defaultMessage="复制成功"
                                  />
                                ),
                              });
                            }
                          }}
                        >
                          {captcha}
                        </Text>
                      )}
                      <Spacer />
                      <OutlineButton
                        onClick={() => {
                          if (niceOpen.data) {
                            if (niceOpen?.data?.data === 1) {
                              setWithdraw({
                                coin: "IEBB",
                                available: item.available,
                                onSuccess: () => {
                                  refetch();
                                },
                              });
                              withdrawClosure.onOpen();
                            } else {
                              setWithdraw({
                                coin: "NICE-COIN",
                                available: data.data?.find((e) => e.coin_name === 'NICE-COIN')?.available,
                                onSuccess: () => {
                                  refetch();
                                },
                              });
                              withdrawClosure.onOpen();
                            }
                          }
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.withdraw"
                          defaultMessage="提币"
                        />
                      </OutlineButton>
                    </Flex>
                  )}
                  {item.coin_name === "IECC" && (
                    <Stack
                      flexDirection="row"
                      justifyContent="end"
                      opacity="0.8"
                    >
                      <OutlineButton
                        onClick={() => {
                          setExchange({
                            from: "IECC",
                            to: "IEB",
                            available: item.available,
                            onSuccess: () => {
                              refetch();
                            },
                          });
                          onOpen();
                        }}
                      >
                        <FormattedMessage
                          id="Home.Asset.swap"
                          defaultMessage="闪兑"
                        />
                        IEB
                      </OutlineButton>
                    </Stack>
                  )}
                </StackItem>
              </Stack>
            );
          })}
      </QueryPage>
      <Exchange {...exchange} isOpen={isOpen} onClose={onClose} />
      <Withdraw
        {...withdraw}
        isOpen={withdrawClosure.isOpen}
        onClose={withdrawClosure.onClose}
      />
      <InnerTransfer disclosure={innerTransferDisclosure} />
      <Modal
        isOpen={iebOutDisclosure.isOpen}
        onClose={iebOutDisclosure.onClose}
        size="xs"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>全部划转到可用</ModalHeader>
          <ModalFooter>
            <Flex gap="8px">
              <Button
                variant="solid"
                colorScheme="green"
                onClick={async () => {
                  await iebTransfer();
                  await refetch();
                  iebOutDisclosure.onClose();
                }}
              >
                确定
              </Button>
              <Button variant="outline" onClick={iebOutDisclosure.onClose}>
                取消
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
