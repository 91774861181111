import { Container, TabPanel } from "@chakra-ui/react";
import BottonNav from "../../components/BottomNav";
import Header from "../../components/Header";
import AssetTab from "./Asset";
import { Game } from "./Game";
import Home from "./Home";
import Profile from "./Profile";
import { useAppSelector } from "../../stores/global";
import { Rush } from "../inscription/rush";

export default function Index() {
  const token = useAppSelector((state) => state.auth.token);
  return (
    <div>
      {token && (
        <BottonNav>
          <TabPanel p={0}>
            <Header />
            <Container>
              <Home />
            </Container>
          </TabPanel>
          <TabPanel p={0}>
            <Header />
            <Container>
              <Game />
            </Container>
          </TabPanel>
          <TabPanel p={0}>
            <Rush />
          </TabPanel>
          <TabPanel p={0}>
            <Header />
            <Container>
              <AssetTab />
            </Container>
          </TabPanel>
          <TabPanel p={0}>
            <Header />
            <Container>
              <Profile />
            </Container>
          </TabPanel>
        </BottonNav>
      )}
    </div>
  );
}
