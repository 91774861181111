import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import auth from "./auth";
import { Api } from "./api";
import { setupListeners } from "@reduxjs/toolkit/query";
import wallet from "./wallet";
import tabIndex from './tabIndex';
import locale from "./locale";


export const store = configureStore({
  reducer: {
    auth: auth,
    wallet: wallet,
    tabIndex: tabIndex,
    locale: locale,
    [Api.reducerPath]: Api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(Api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
