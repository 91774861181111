import { Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { GradientButton } from "./GradientButton";

export type RefreshProps = {
  description?: string | ReactNode;
  onClick: () => void;
};

export default function Refresh({ onClick, description }: RefreshProps) {
  return (
    <Stack align={'center'} gap={8}>
      {description && <Text textColor="white">{description}</Text>}
      <GradientButton fontSize="medium" onClick={onClick}>
        <FormattedMessage id="Refresh.refresh" defaultMessage="刷新" />
      </GradientButton>
    </Stack>
  );
}
