import { useCallback, useState } from "react";
import { useAppDispatch } from "../stores/global";
import { setApproveThunk } from "../stores/wallet";
import { SerializedError } from "@reduxjs/toolkit";
import { Abi } from "viem";

export default function useApprove(abi: Abi, target: `0x${string}`) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<string | undefined>();
  const [error, setError] = useState<SerializedError | undefined>();

  const onApprove = useCallback(
    async (address: string) => {
      setLoading(true);
      const resultAction = await dispatch(
        setApproveThunk({ targetAddress: address, abi: abi, contract: target }),
      );
      setLoading(false);
      if (setApproveThunk.fulfilled.match(resultAction)) {
        setResult(resultAction.payload);
        return resultAction.payload;
      } else if (setApproveThunk.rejected.match(resultAction)) {
        console.log(resultAction.error);
        setError(resultAction.error);
      }
      return;
    },
    [dispatch, abi, target],
  );

  return {
    loading,
    result,
    onApprove,
    error,
  };
}
