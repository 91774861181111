import { FormattedMessage } from "react-intl";
import QueryPage from "../../components/QueryPage";
import { useGetGameRecordQuery } from "../../stores/api";
import { useEffect, useState } from "react";
import { GameRecord } from "../../types";
import { unionWith } from "lodash";
import { Flex, Spacer, Stack, Text, Grid, GridItem } from "@chakra-ui/react";
import { numToSide } from "./utils";

export function Record2() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<GameRecord[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(true);

  const { error, data, isFetching, refetch, requestId } = useGetGameRecordQuery(
    { page, type: "1" },
  );

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTotal((total) => {
        return unionWith(total, data?.data?.data ?? [], (a, b) => {
          return a.id === b.id;
        });
      });
    }
  }, [data?.data, requestId]);

  useEffect(() => {
    setCanFetchMore(
      !isFetching && total.length !== 0 && page < data?.data?.last_page,
    );
  }, [page, data?.data?.last_page, total, isFetching]);

  return (
    <QueryPage
      error={error}
      refetch={() => {
        setTotal([]);
        setPage(1);
        refetch();
      }}
      isLoading={isFetching}
      onFetchMore={async () => {
        if (!isFetching) {
          setPage(page + 1);
        }
      }}
      hasMore={canFetchMore}
      errorMessage={
        <FormattedMessage id="Games.Record.error" defaultMessage="数据错误" />
      }
    >
      <Stack>
        {total.map((item) => {
          return (
            <Stack
              key={item.id}
              bgColor="#ffffff22"
              color="white"
              p="16px"
              rounded="8px"
            >
              <Flex>
                <Text>
                  <FormattedMessage
                    defaultMessage="第 {id} 期"
                    values={{ id: item.id }}
                    id="Game.Guess.round"
                  />
                </Text>
                <Spacer />
                <Flex
                  align="center"
                  bg="#ffffff22"
                  px="16px"
                  py="4px"
                  rounded="4px"
                  gap="16px"
                >
                  <Text>
                    <FormattedMessage
                      defaultMessage="开"
                      id="Game.Record2.lost"
                    />
                  </Text>
                  <Text color="#3190f4">
                    {numToSide(parseInt(item.lose_obj))}
                  </Text>
                </Flex>
              </Flex>
              <Grid templateColumns="1fr 1fr 1fr" gap="4px">
                <GridItem>
                  <Stack align="center" bg="#ffffff22" py="4px" rounded="4px">
                    <Text>
                      <FormattedMessage
                        defaultMessage="忍者"
                        id="Game.Guess.redSide"
                      />
                    </Text>
                    <Text color="#ffee35">{item.obj_1}</Text>
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack align="center" bg="#ffffff22" py="4px" rounded="4px">
                    <Text>
                      <FormattedMessage
                        defaultMessage="和平使者"
                        id="Game.Guess.yellowSide"
                      />
                    </Text>
                    <Text color="#ffee35">{item.obj_3}</Text>
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack align="center" bg="#ffffff22" py="4px" rounded="4px">
                    <Text>
                      <FormattedMessage
                        defaultMessage="战士"
                        id="Game.Guess.blueSide"
                      />
                    </Text>
                    <Text color="#ffee35">{item.obj_2}</Text>
                  </Stack>
                </GridItem>
              </Grid>
            </Stack>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
