import { Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import QueryPage from "../../components/QueryPage";
import { useGetMyRobotListQuery } from "../../stores/api";

export default function MyList() {
  const { isLoading, error, data, refetch } = useGetMyRobotListQuery();
  return (
    <QueryPage
      isLoading={isLoading}
      error={error}
      errorMessage={
        <FormattedMessage
          id="Robot.MyList.error"
          defaultMessage="加载我的矿机失败"
        />
      }
      refetch={refetch}
      title={
        <FormattedMessage id="Robot.MyList.title" defaultMessage="我的矿机" />
      }
    >
      <Stack gap="24px">
        {data?.data.map((item) => {
          return (
            <Stack pos="relative">
              <Image src={item.img} objectFit="contain" pos="absolute" />
              <Flex pos="relative" alignItems="center" gap="0">
                <Stack textColor="white" p="16px" gap="0">
                  <Text fontSize="large">{item.sn}</Text>
                  <Flex alignItems="center" gap="8px">
                    <Text fontSize="small" color="gray.300">
                      <FormattedMessage
                        id="Robot.MyList.total"
                        defaultMessage="总量"
                      />
                    </Text>
                    <Image src="/img/icon-diamond.png" boxSize="16px" />
                    {item.free_total}
                  </Flex>
                  <Flex alignItems="center" gap="8px">
                    <Text fontSize="small" color="gray.300">
                      <FormattedMessage
                        id="Robot.MyList.produced"
                        defaultMessage="已产"
                      />
                    </Text>
                    <Image src="/img/icon-diamond.png" boxSize="16px" />
                    {item.has_profit}
                  </Flex>
                  <Flex alignItems="center" gap="8px">
                    <Text fontSize="small" color="gray.300">
                      <FormattedMessage
                        id="Robot.MyList.left"
                        defaultMessage="剩余"
                      />
                    </Text>
                    <Image src="/img/icon-diamond.png" boxSize="16px" />
                    {item.residue}
                  </Flex>
                  <Text fontSize="small" color="gray.300">
                    {item.ctime}
                  </Text>
                </Stack>
                <Spacer />
                <Stack p="16px">
                  <Image src={item.img_robot} boxSize="60px" objectFit="contain" />
                  <Text color="white">{item.status}</Text>
                </Stack>
              </Flex>
            </Stack>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
