import {
  Box,
  Center,
  Flex,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SecondaryHeader from "../../components/SecondaryHeader";
import { useState } from "react";
import { GradientButton } from "../../components/GradientButton";
import { useGetAssetQuery, usePostBuyIEBRobotMutation } from "../../stores/api";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

export function Join() {
  const assets = useGetAssetQuery();
  const [amount, setAmount] = useState("");
  const [postBuyIEBRobot] = usePostBuyIEBRobotMutation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  return (
    <Box
      pos="relative"
      bgImg="/img/bg-join.png"
      bgPos="top"
      bgSize="contain"
      bgRepeat="no-repeat"
    >
      <Box mx="8px">
        <SecondaryHeader
          title={
            <FormattedMessage id="Pool.Join.title" defaultMessage="矿池" />
          }
        />
        <Text
          pos="absolute"
          right="16px"
          top="16px"
          onClick={() => {
            navigate("/pool/list");
          }}
        >
          <FormattedMessage id="Pool.Join.record" defaultMessage="我的矿池→" />
        </Text>
      </Box>
      <Box mt="60px" mx="16px" mb="60px">
        <Text fontSize="x-large">
          <FormattedMessage
            defaultMessage="参与IEB矿池"
            id="Pool.Join.description"
          />
        </Text>
        <Text>
          <FormattedMessage
            defaultMessage="探索游戏竞技的无限可能"
            id="Pool.Join.description2"
          />
        </Text>
      </Box>
      <Stack
        bgImg="/img/bg-dark-join.png"
        bgSize="cover"
        bgPos="center"
        minH="700px"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        color="white"
        p="16px"
      >
        <Text>
          <FormattedMessage id="Pool.Join.amount" defaultMessage="数量" />
          <Text as="span">
            {" ("}
            <FormattedMessage
              defaultMessage="可用IEB:"
              id="Pool.Join.available"
            />{" "}
            {assets.data?.data?.find((e) => e.coin_name === "IEB")?.available}
            {")"}
          </Text>
        </Text>
        <Flex align="center" gap="16px">
          <Input
            color="white"
            bg="black"
            value={amount}
            type="number"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            width="auto"
            flex="1"
          />
          <Text
            color="#57fcaf"
            onClick={() => {
              const value = parseFloat(
                assets.data?.data?.find((item) => item.coin_name === "IEB")
                  ?.available || "0",
              );
              setAmount(String(Math.floor(value / 10) * 10));
            }}
          >
            <FormattedMessage id="Pool.Join.max" defaultMessage="最大" />
          </Text>
        </Flex>
        {!isLoading && (
          <GradientButton
            fontSize="large"
            mt="32px"
            color="black"
            onClick={async () => {
              setIsLoading(true);
              const response = await postBuyIEBRobot(amount).unwrap();
              if (response.data) {
                toast({
                  title: (
                    <FormattedMessage
                      defaultMessage="成功参与"
                      id="Pool.Join.success"
                    />
                  ),
                });
                assets.refetch();
                Sentry.metrics.increment("pool_total_join", Number(amount));
              } else {
                toast({
                  title: response.msg,
                });
              }
              setAmount("");
              setIsLoading(false);
            }}
          >
            <FormattedMessage defaultMessage="确定" id="Pool.Join.confirm" />
          </GradientButton>
        )}
        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}
      </Stack>
    </Box>
  );
}
