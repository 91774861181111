import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import {
  useGetAssetQuery,
  useGetInterTransferAmountQuery,
  usePostInterTransferMutation,
} from "../../stores/api";
import { useState } from "react";
import { useDebounce } from "usehooks-ts";

export interface InnerTransferProps {
  disclosure: UseDisclosureReturn;
}

export function InnerTransfer({ disclosure }: InnerTransferProps) {
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [captcha, setCaptcha] = useState("");
  const debouncedAmount = useDebounce(amount, 300);
  const { data, isFetching } = useGetInterTransferAmountQuery(debouncedAmount, {
    skip: !debouncedAmount,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postInterTransfer] = usePostInterTransferMutation();
  const toast = useToast();
  const assets = useGetAssetQuery();

  return (
    <Drawer {...disclosure} placement="bottom">
      <DrawerOverlay />
      <DrawerContent bg="gray.800" color="white">
        <DrawerHeader>
          <Text>
            <FormattedMessage
              defaultMessage="互赠"
              id="Asset.InnerTransfer.title"
            />
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="Asset.InnerTransfer.receiveAddress"
                defaultMessage="接收地址"
              />
            </FormLabel>
            <Input
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <FormHelperText />
          </FormControl>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="Asset.InnerTransfer.sendAmount"
                defaultMessage="发送数量"
              />
              (
              <FormattedMessage
                id="Asset.InnerTransfer.avaible"
                defaultMessage="可用IEBB"
              />{" "}
              {assets.data?.data?.find((e) => e.coin_name === "IEBB")?.available}
              )
            </FormLabel>
            <Input
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            <FormHelperText>
              {data?.code === "error_num" && data?.msg}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="Asset.InnerTransfer.receiveAmount"
                defaultMessage="接收数量"
              />
            </FormLabel>
            <InputGroup>
              <Input disabled value={data?.data?.to_num ?? ""} />
              <InputRightElement>
                {isFetching ? <Spinner /> : null}
              </InputRightElement>
            </InputGroup>
            <FormHelperText />
          </FormControl>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="Asset.InnerTransfer.captcha"
                defaultMessage="验证码"
              />
            </FormLabel>
            <InputGroup>
              <Input
                value={captcha}
                onChange={(e) => {
                  setCaptcha(e.target.value);
                }}
              />
            </InputGroup>
            <FormHelperText />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          {!isSubmitting && (
            <Button
              colorScheme="green"
              onClick={async () => {
                setIsSubmitting(true);
                try {
                  const response = await postInterTransfer({
                    address,
                    num: amount,
                    code: captcha,
                  }).unwrap();
                  if (response.data) {
                    toast({
                      title: (
                        <FormattedMessage
                          id="Asset.InnerTransfer.success"
                          defaultMessage="提交成功"
                        />
                      ),
                    });
                    disclosure.onClose();
                  } else {
                    toast({
                      title: (
                        <FormattedMessage
                          id="Asset.InnerTransfer.error"
                          defaultMessage="提交失败"
                        />
                      ),
                      status: "error",
                    });
                  }
                } catch (error) {
                  toast({
                    title: (
                      <FormattedMessage
                        id="Asset.InnerTransfer.error"
                        defaultMessage="提交失败"
                      />
                    ),
                    status: "error",
                  });
                }
                setIsSubmitting(false);
              }}
            >
              <FormattedMessage
                id="Asset.InnerTransfer.confirm"
                defaultMessage="提交"
              />
            </Button>
          )}
          {isSubmitting && <Spinner />}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
