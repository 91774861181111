import { FormattedMessage } from "react-intl";
import QueryPage from "../../components/QueryPage";
import { useGetNoticeListQuery } from "../../stores/api";
import { Container, Stack, StackItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Notice } from "../../types";
import SecondaryHeader from "../../components/SecondaryHeader";

export default function List() {
  const { isLoading, data, error, refetch } = useGetNoticeListQuery();
  const [selected, setSelected] = useState<Notice | undefined>();
  return (
    <>
      {selected && (
        <Container position="relative">
          <SecondaryHeader
            title={selected.title}
            onClick={() => { setSelected(undefined) }}
          />
          <Stack
            bgColor="#ffffff44"
            rounded="4px"
            p="16px"
            h="85vh"
            overflowY="scroll"
          >
            <Text dangerouslySetInnerHTML={{ __html: selected.content }} />
          </Stack>
        </Container>
      )}
      {!selected && (
        <QueryPage
          isLoading={isLoading}
          error={error}
          title={
            <FormattedMessage
              id="Notice.List.title"
              defaultMessage="公告列表"
            />
          }
          errorMessage={
            <FormattedMessage
              id="Notice.List.error"
              defaultMessage="加载公告错误"
            />
          }
          refetch={refetch}
          loadingType="skeleton"
        >
          <Stack gap="12px">
            {data?.data?.map((item) => {
              return (
                <StackItem onClick={() => setSelected(item)}>
                  <Stack
                    rounded="8px"
                    bgColor="#ffffff22"
                    px="12px"
                    py="16px"
                    color="white"
                  >
                    <Text fontSize="x-large">{item.title}</Text>
                    <Text color="gray.200">{item.preview}</Text>
                  </Stack>
                </StackItem>
              );
            })}
          </Stack>
        </QueryPage>
      )}
    </>
  );
}
