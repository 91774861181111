import App from "./App";
import Community from "./routes/Community";
import Deposit from "./routes/Deposit";
import SubTeamList from "./routes/SubTeamList";
import TeamList from "./routes/TeamList";
import Transaction from "./routes/Transaction";
import { Guess2 } from "./routes/games/Guess2";
import { History } from "./routes/games/History";
import { MyRecord } from "./routes/games/MyRecord";
import { Guess } from "./routes/games/guess";
import Index from "./routes/index/Index";
import NoticeList from "./routes/notice/List";
import RobotList from "./routes/robot/List";
import MyRobotList from "./routes/robot/MyList";
import Buy from "./routes/robot/Buy";
import { RouteObject } from "react-router-dom";
import { Swap } from "./routes/asset/Swap";
import { Feedback } from "./routes/Feedback";
import { Invite } from "./routes/Invite";
import { Join } from "./routes/pool/Join";
import { List as PoolList } from "./routes/pool/List";
import DepositIEBB from './routes/DepositIEBB';

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Index />,
      },
      {
        path: "robot/list",
        element: <RobotList />,
      },
      {
        path: "robot/my",
        element: <MyRobotList />,
      },
      {
        path: "robot/buy",
        element: <Buy />,
      },
      {
        path: "profile/community",
        element: <Community />,
      },
      {
        path: "profile/team",
        element: <TeamList />,
      },
      {
        path: "profile/team/:address",
        element: <SubTeamList />,
      },
      {
        path: "transaction/:coin",
        element: <Transaction />,
      },
      {
        path: "deposit",
        element: <Deposit />,
      },
      {
        path: "depositIEBB",
        element: <DepositIEBB />,
      },
      {
        path: "notice/list",
        element: <NoticeList />,
      },
      {
        path: "game/guess",
        element: <Guess />,
      },
      {
        path: "game/guess/record/:type",
        element: <History />,
      },
      {
        path: "game/guess/myrecord/:type",
        element: <MyRecord />,
      },
      {
        path: "game/guess2",
        element: <Guess2 />,
      },
      {
        path: "swap",
        element: <Swap />,
      },
      {
        path: "feedback",
        element: <Feedback />,
      },
      {
        path: "invite",
        element: <Invite />,
      },
      {
        path: "pool/join",
        element: <Join />,
      },
      {
        path: "pool/list",
        element: <PoolList />,
      },
      {
        path: "pool/liquidity/join",
        lazy: () => import("./routes/pool/liquidity/Join"),
      },
      {
        path: "pool/liquidity/list",
        lazy: () => import("./routes/pool/liquidity/List"),
      },
      {
        path: "iebb/join",
        lazy: () => import("./routes/iebb/join"),
      },
    ],
  },
  {
    path: "/ipo",
    lazy: () => import("./routes/ipo/Ipo"),
  },
  {
    path: "/ipo/list",
    lazy: () => import("./routes/ipo/List"),
  },
];
