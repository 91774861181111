import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spacer,
  Spinner,
  Stack,
  StackItem,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useGetBuyCheckQuery, useGetRobotListQuery } from "../../stores/api";

import { GradientButton } from "../../components/GradientButton";
import QueryPage from "../../components/QueryPage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function List() {
  const { isLoading, data, error, refetch } = useGetRobotListQuery();
  const [checkId, setCheckId] = useState<number | undefined>();
  const useGetBuyCheckQueryProps = useGetBuyCheckQuery(checkId, {
    skip: checkId === undefined,
    refetchOnMountOrArgChange: true,
  });

  const checkDisclosure = useDisclosure();
  const navigate = useNavigate();

  const toast = useToast();

  useEffect(() => {
    if (checkId && !useGetBuyCheckQueryProps.isFetching) {
      if (data.data && useGetBuyCheckQueryProps.data) {
        if (useGetBuyCheckQueryProps.data?.data) {
          const item = data.data?.find((item) => item.id === checkId);
          if (item) {
            navigate(`/robot/buy?id=${checkId}&price=${item.price}`);
            setCheckId(undefined);
          }
        } else {
          toast({
            title: useGetBuyCheckQueryProps.data?.msg,
            status: "error",
          });
          setCheckId(undefined);
        }
      }
    }
  }, [useGetBuyCheckQueryProps, checkId, data, navigate, toast]);

  useEffect(() => {
    if (useGetBuyCheckQueryProps.isFetching) {
      checkDisclosure.onOpen();
    } else {
      checkDisclosure.onClose();
    }
  });

  return (
    <QueryPage
      isLoading={isLoading}
      error={error}
      refetch={refetch}
      title={
        <FormattedMessage id="Robot.List.title" defaultMessage="矿机列表" />
      }
      errorMessage={
        <FormattedMessage
          id="Robot.List.error"
          defaultMessage="加载矿机列表失败请刷新重试"
        />
      }
    >
      {data && (
        <Stack textColor="white">
          {data.data.map((item) => {
            return (
              <StackItem
                position="relative"
                key={item.id}
                aspectRatio="694/296"
                pb="24px"
              >
                <Image position="absolute" src={item.img} width="100vw"/>
                <Flex flexDirection={"row"} p="16px" pos="relative" zIndex="10">
                  <Flex flexDirection="column" alignItems="start" gap="10px" width="14rem">
                    <Text>{item.title}</Text>
                    <Text
                      fontSize="small"
                      color="gray.300"
                    >
                      {item.desc}
                    </Text>
                    <GradientButton
                      width="100px"
                      color="black"
                      fontSize="unset"
                      onClick={() => {
                        setCheckId(item.id);
                      }}
                    >
                      <FormattedMessage
                        id="Robot.List.buy"
                        defaultMessage="兑换"
                      />
                    </GradientButton>
                  </Flex>
                  <Spacer />
                  <Flex flexDirection={"column"} gap="20px" align="center">
                    <Image
                      src={item.img_robot}
                      width="40px"
                      objectFit="contain"
                    />
                    <Text fontWeight="bold" wordBreak="keep-all">
                      {item.price}U
                    </Text>
                  </Flex>
                </Flex>
              </StackItem>
            );
          })}
        </Stack>
      )}
      <Drawer
        isOpen={checkDisclosure.isOpen}
        onClose={checkDisclosure.onClose}
        placement="bottom"
      >
        <DrawerOverlay />
        <DrawerContent bgColor="gray.800" textColor="white">
          <DrawerHeader />
          <DrawerBody>
            <Flex gap="16px">
              <FormattedMessage id="List.check" defaultMessage="检测中" />
              {useGetBuyCheckQueryProps.isLoading && <Spinner />}
            </Flex>
          </DrawerBody>
          <DrawerFooter />
        </DrawerContent>
      </Drawer>
    </QueryPage>
  );
}
