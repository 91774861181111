import {
  Flex,
  Image,
  SlideFade,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { motion, motionValue, useTime, useTransform } from "framer-motion";
import { range } from "lodash";
import { ReactNode, useContext, useMemo } from "react";
import { BrowserContextProvider } from "../main";

export interface PlanetProps {
  img: string;
  name: ReactNode;
  amount: string;
  width: number;
  selected?: boolean;
  count?: string;
  lost?: boolean;
  betAmount?: string;
}
export function Planet({
  img,
  name,
  amount,
  width,
  selected = false,
  count = "0",
  lost = true,
  betAmount,
}: PlanetProps) {
  const browser = useContext(BrowserContextProvider);
  const num = parseInt(count);
  const time = useTime();
  const rotate = useTransform(
    time,
    [0, 400], // For every 4 seconds...
    [0, 360], // ...rotate 360deg
    { clamp: false },
  );

  const os = useMemo(() => {
    return browser.os;
  }, [browser]);

  return (
    <Stack color="white" alignItems="center" position="relative">
      <motion.div
        style={{
          rotate: lost ? rotate : motionValue(0),
        }}
      >
        <Image src={img} width={`${width}px`} zIndex="0" />
      </motion.div>
      {betAmount && (
        <Text
          position="absolute"
          top="4px"
          bg="white"
          color="black"
          px="8px"
          rounded="4px"
          fontSize={{ base: "xs", sm: os === "iOS" ? "lg" : "xs" }}
          zIndex="10"
        >
          {betAmount}
        </Text>
      )}
      <Text
        zIndex="10"
        borderWidth="4px"
        rounded="50px"
        borderColor={selected ? "white" : "#3b538e99"}
        borderStyle="solid"
        px="16px"
        py="2px"
        mt="-60px"
        bg={selected ? "#ffffff99" : "#00000066"}
          fontSize={{ base: "sm", sm: os === "iOS" ? "xl" : "sm" }}
        transition="all"
        transitionDuration="0.5s"
        transitionTimingFunction="ease"
        color={selected ? "black" : "white"}
      >
        {name}
      </Text>
      <Flex alignItems="center" gap="4px" mt="-10px" zIndex="10">
        <Image width="16px" height="16px" src="/img/game/coin.png" />
        <Text
          fontSize={{ base: "sm", sm: os === "iOS" ? "2xl" : "sm" }}
          fontWeight="bold"
        >
          {amount}
        </Text>
      </Flex>
      {!lost && (
        <Wrap position="absolute" width="50%" top="0">
          {range(0, Math.min(10, num)).map((item) => {
            return (
              <WrapItem key={item} width="10px" height="20px">
                <SlideFade in={true} transition={{ enter: { duration: 0.5 } }}>
                  <Image
                    src="/img/game/human.gif"
                    width="40px"
                    objectFit="cover"
                    maxWidth="fit-content"
                  />
                </SlideFade>
              </WrapItem>
            );
          })}
        </Wrap>
      )}
      {lost && (
        <Wrap position="absolute" width="50%" top="0">
          {range(0, 10).map((item) => {
            return (
              <WrapItem key={`a-${item}`} width="10px" height="20px">
                <SlideFade
                  in={true}
                  transition={{
                    enter: { duration: 0.5, delay: 1 + item / 10 },
                  }}
                >
                  <Image
                    src="/img/game/alien.gif"
                    width="25px"
                    objectFit="cover"
                    maxWidth="fit-content"
                  />
                </SlideFade>
              </WrapItem>
            );
          })}
        </Wrap>
      )}
    </Stack>
  );
}
