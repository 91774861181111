import { Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import { useGetTeamListQuery } from "../stores/api";
import { useEffect, useState } from "react";
import { TeamListItem } from "../types";
import { unionWith } from "lodash";
import QueryPage from "../components/QueryPage";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function TeamList() {
  const { address } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<TeamListItem[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const { error, data, isFetching, refetch, requestId } = useGetTeamListQuery({
    page,
    address,
  });

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTotal((total) => {
        return unionWith(total, data?.data?.data ?? [], (a, b) => {
          return a.id === b.id;
        });
      });
    }
  }, [data?.data, requestId]);

  useEffect(() => {
    setTotal([]);
    setPage(1);
  }, [address]);

  useEffect(() => {
    setCanFetchMore(
      !isFetching && total.length !== 0 && page < data?.data?.last_page
    );
  }, [page, data?.data?.last_page, total, isFetching]);

  return (
    <QueryPage
      isLoading={isFetching}
      refetch={() => {
        setTotal([]);
        setPage(1);
        refetch();
      }}
      error={error}
      onFetchMore={async () => {
        if (!isFetching) {
          setPage(page + 1);
        }
      }}
      title={<FormattedMessage id="TeamList.title" defaultMessage="直推用户" />}
      hasMore={canFetchMore}
      errorMessage={
        <FormattedMessage id="TeamList.error" defaultMessage="数据错误" />
      }
    >
      <Stack>
        {total.map((item) => {
          return (
            <Flex
              key={item.id}
              gap="8px"
              p="8px"
              rounded="8px"
              color="white"
              bgColor={item.is_active ? "#ffffff22" : "#ffffff11"}
              alignItems="center"
              onClick={() => {
                if (!address) {
                  navigate(`/profile/team/${item.paddress}`);
                }
              }}
            >
              <Image src="/img/icon-avatar.png" width="40px" />
              <Stack flex="1">
                <Flex alignItems="center">
                  <Text
                    fontSize="large"
                    fontWeight="bold"
                    color={item.isMax === 0 ? "white" : "#6dfeb4"}
                  >
                    {item.address}
                  </Text>
                  <Text as="span" fontSize="x-small">
                    {item.robot}
                  </Text>
                  <Spacer />
                  <Text fontSize="small">{item.is_active}</Text>
                </Flex>
                <Flex align="center">
                  <Text fontSize="normal">{item.detail}</Text>
                  <Spacer />
                  <Text fontSize="x-small" color="gray.300">
                    {item.created_at}
                  </Text>
                </Flex>
              </Stack>
              <ChevronRightIcon />
            </Flex>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
