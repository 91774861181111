import {
  Box,
  Center,
  ComponentDefaultProps,
  Container,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ReactNode } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import Refresh from "./Refresh";
import SecondaryHeader from "./SecondaryHeader";
import { motion, useScroll, useTransform } from "framer-motion";

export type QueryPageProps = {
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError;
  children?: ReactNode;
  title?: string | ReactNode;
  errorMessage: string | ReactNode;
  refetch: () => void;
  loadingType?: "spinner" | "skeleton";
  hasMore?: boolean;
  onFetchMore?: () => void;
} & ComponentDefaultProps;

export default function QueryPage({
  isLoading,
  error,
  title,
  refetch,
  errorMessage,
  loadingType = "spinner",
  children,
  hasMore = false,
  onFetchMore,
  ...rest
}: QueryPageProps) {
  const handleRefresh = () => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!isLoading) {
          resolve();
          clearInterval(interval);
        }
      }, 2000);
    });
  };
  const { scrollY } = useScroll();
  const bg = useTransform(scrollY, [0, 100], ["#ffffff00", "#ffffffaa"]);
  const color = useTransform(scrollY, [0, 100], ["#ffffffff", "#000000ff"]);
  const scale = useTransform(scrollY, [0, 100], [1, 0.8]);
  const round = useTransform(scrollY, [0, 100], [0, 8]);

  return (
    <Container position="relative" mb="16px" {...rest}>
      {title && (
        <motion.div
          style={{
            zIndex: "10",
            backgroundColor: bg,
            position: "fixed",
            width: "100%",
            left: "0",
            color,
            scale,
            borderRadius: round,
          }}
        >
          <SecondaryHeader title={title} />
        </motion.div>
      )}
      {isLoading && loadingType === "skeleton" && (
        <Stack gap={"4"}>
          <Skeleton height="150px" />
          <Skeleton height="150px" />
          <Skeleton height="150px" />
          <Skeleton height="150px" />
          <Skeleton height="150px" />
          <Skeleton height="150px" />
        </Stack>
      )}
      {!isLoading && error && (
        <Center h="80vh">
          <Refresh onClick={refetch} description={errorMessage} />
        </Center>
      )}
      {title && <Box height="60px" />}
      <PullToRefresh
        onRefresh={() => {
          refetch();
          return handleRefresh();
        }}
        canFetchMore={hasMore}
        onFetchMore={() => {
          onFetchMore();
          return handleRefresh();
        }}
      >
        <div style={{ minHeight: "80vh" }}>{children}</div>
      </PullToRefresh>
    </Container>
  );
}
