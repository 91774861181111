import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  base: "0em",
  sm: "24em",
};

const theme = extendTheme({
  breakpoints,
  components: {
    Button: {
      variants: {
        gradient: {
          bgGradient:
            "linear-gradient(150deg, rgba(233,250,44,1) 0%, rgba(93,254,197,1) 100%)",
          rounded: "100px",
          height: "32px",
        },
      },
      defaultProps: {
        colorScheme: "whiteAplha",
      },
    },
  },
});

export default theme;
