import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import SecondaryHeader from "../components/SecondaryHeader";
import { FormattedMessage } from "react-intl";
import { GradientButton } from "../components/GradientButton";
import { Api } from "../stores/api";
import { useState } from "react";
import { useAppDispatch } from "../stores/global";

export function Feedback() {
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container pos="relative">
      <SecondaryHeader
        title={
          <FormattedMessage id="Feedback.title" defaultMessage="意见反馈" />
        }
      />
      <Stack color="white">
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="Feedback.description"
              defaultMessage="问题描述"
            />
          </FormLabel>
          <Textarea
            height="200px"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></Textarea>
        </FormControl>
        <FormControl>
          <FormLabel>
            <FormattedMessage id="Feedback.email" defaultMessage="联系邮箱" />
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        {isLoading && <Spinner />}
        {!isLoading && (
          <GradientButton
            mt="32px"
            fontSize="large"
            onClick={async () => {
              setIsLoading(true);
              const response = await dispatch(
                Api.endpoints.getFeedback.initiate({ text: description, email })
              );
              if (response.data) {
                toast({
                  title: (
                    <FormattedMessage
                      id="Feedback.success"
                      defaultMessage="提交成功"
                    />
                  ),
                });
                setEmail("");
                setDescription("");
              }
              setIsLoading(false);
            }}
          >
            <FormattedMessage id="Feedback.submit" defaultMessage="提交" />
          </GradientButton>
        )}
      </Stack>
    </Container>
  );
}
