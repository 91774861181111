import React, { useEffect, useRef } from "react";

const Wave: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const offset = Math.random() * 50;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (context) {
      let frameCount = 0;
      let animationFrameId: number;

      const render = () => {
        frameCount++;
        context.clearRect(0, 0, canvas.width, canvas.height);

        const waveHeight = 10;
        const waveSpeed = 0.02;
        const waveOffset = offset + frameCount * waveSpeed;
        const waveOffset2 = offset * 4 + frameCount * waveSpeed;

        context.beginPath();
        context.moveTo(0, canvas.height / 4);

        for (let x = 0; x < canvas.width; x++) {
          const y =
            canvas.height / 2 +
            Math.sin(x * waveSpeed * 1.5 + waveOffset) * waveHeight;
          context.lineTo(x, y);
        }

        context.lineTo(canvas.width, canvas.height);
        context.lineTo(0, canvas.height);
        context.closePath();

        context.fillStyle = "#d1ffe2";
        context.fill();

        context.beginPath();
        context.moveTo(0, canvas.height / 4);

        for (let x = 0; x < canvas.width; x++) {
          const y =
            canvas.height / 2 +
            Math.sin(x * waveSpeed + waveOffset2) * waveHeight * 1.5;
          context.lineTo(x, y);
        }

        context.lineTo(canvas.width, canvas.height);
        context.lineTo(0, canvas.height);
        context.closePath();

        context.fillStyle = "#aafed3";
        context.fill();

        animationFrameId = requestAnimationFrame(render);
      };

      render();

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, []);

  return <canvas ref={canvasRef} />;
};

export default Wave;
