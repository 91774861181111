import { useCallback, useState } from "react";
import { useAppSelector } from "../stores/global";
import { getAllowance } from "../wallet/EtherWallet";

export default function useAllowed(target: `0x${string}`) {
  const address = useAppSelector((state) => state.auth.address);
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(BigInt(0));

  const onAllance = useCallback(
    async (spender: `0x${string}`, polling: boolean) => {
      if (address) {
        setLoading(true);
        const data = await getAllowance(address, spender, target, polling);
        setAllowed(BigInt(data));
        setLoading(false);
      }
    },
    [address],
  );

  return {
    allowance: allowed,
    loading,
    onAllance,
  };
}
