import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bsc } from "viem/chains";
import { walletConnectModal } from "../wallet/WalletModal";
import { host } from "./api";
import { RootState } from "./global";
import { signClient } from "./wallet";
import { Response } from "../types";
import { walletClient } from "../wallet/EtherWallet";
import Bugsnag from "@bugsnag/js";

export interface AuthState {
  token: string | null;
  expiry: number | null;
  address: `0x${string}` | null;
  authing: boolean;
  connectintg: boolean;
}

const initialState: AuthState = {
  token: null,
  expiry: null,
  authing: false,
  connectintg: false,
  address: localStorage.getItem("session:address") as `0x${string}`,
};

export const authThunk = createAsyncThunk("auth/auth", async (_, thunkApi) => {
  if ((thunkApi.getState() as RootState).auth.authing) return;
  try {
    const rootState = thunkApi.getState() as RootState;
    const address = rootState.auth.address;

    const inviteCode = localStorage.getItem("invitecode");
    const formData = new URLSearchParams();
    formData.set("address", address);
    formData.set("invite_code", inviteCode);
    const response = await fetch(`${host}/api/login/login`, {
      body: formData,
      method: "POST",
    });
    const data = await response.json();
    if (!data.data) {
      throw new Error(data.msg);
    }

    let result: string;
    if (rootState.wallet.wallet === "walletconnect") {
      if (!signClient) return;
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      result = await signClient.request({
        topic: lastSession.topic,
        chainId: `eip155:${bsc.id}`,
        request: {
          method: "personal_sign",
          params: [data.data, address],
        },
      });
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      result = await walletClient.signMessage({
        account: address,
        message: data.data,
      });
    }

    const authFormData = new URLSearchParams();
    authFormData.set("address", address);
    authFormData.set("signature", result);
    authFormData.set("invite_code", inviteCode);

    const authResponse = await fetch(`${host}/api/login/auth`, {
      body: authFormData,
      method: "POST",
    });

    const authData: Response<{
      access_token: string;
      expires_in: number;
    }> = await authResponse.json();

    console.log(authData);

    if (!authData.data) {
      throw new Error(JSON.stringify(authData));
    }

    if (authData.data.access_token) {
      thunkApi.dispatch(
        set({
          token: authData.data.access_token,
          expires_in: authData.data.expires_in,
        }),
      );
    }
    thunkApi.dispatch(authing(false));
  } catch (e) {
    thunkApi.dispatch(authing(false));
    console.log(e);
    thunkApi.dispatch(set({ token: null, expires_in: 0 }));
    Bugsnag.notify(e);
    throw e;
  }
});

export const authIPOThunk = createAsyncThunk("auth/auth", async (_, thunkApi) => {
  if ((thunkApi.getState() as RootState).auth.authing) return;
  try {
    const rootState = thunkApi.getState() as RootState;
    const address = rootState.auth.address;

    const inviteCode = localStorage.getItem("invitecode");
    const formData = new URLSearchParams();
    formData.set("address", address);
    formData.set("invite_code", inviteCode);
    formData.set("type", "1");
    const response = await fetch(`${host}/api/ipo/ipologin`, {
      body: formData,
      method: "POST",
    });
    const data = await response.json();
    if (!data.data) {
      throw new Error(data.msg);
    }

    let result: string;
    if (rootState.wallet.wallet === "walletconnect") {
      if (!signClient) return;
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      result = await signClient.request({
        topic: lastSession.topic,
        chainId: `eip155:${bsc.id}`,
        request: {
          method: "personal_sign",
          params: [data.data, address],
        },
      });
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      result = await walletClient.signMessage({
        account: address,
        message: data.data,
      });
    }

    const authFormData = new URLSearchParams();
    authFormData.set("address", address);
    authFormData.set("signature", result);
    authFormData.set("invite_code", inviteCode);
    authFormData.set("type", "1");

    await fetch(`${host}/api/ipo/iposignature`, {
      body: authFormData,
      method: "POST",
    });

    thunkApi.dispatch(authing(false));
  } catch (e) {
    thunkApi.dispatch(authing(false));
    console.log(e);
    thunkApi.dispatch(set({ token: null, expires_in: 0 }));
    Bugsnag.notify(e);
    throw e;
  }
});


export const connectThunk = createAsyncThunk(
  "auth/connect",
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (rootState.wallet.wallet === "walletconnect") {
      if (!signClient) {
        return;
      }
      try {
        const { uri, approval } = await signClient.connect({
          requiredNamespaces: {
            eip155: {
              methods: [
                "eth_sendTransaction",
                "eth_signTransaction",
                "eth_sign",
                "personal_sign",
                "eth_signTypedData",
              ],
              chains: [`eip155:${bsc.id}`],
              events: ["chainChanged", "accountsChanged"],
            },
          },
        });

        // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
        if (uri) {
          walletConnectModal.openModal({ uri });
          // Await session approval from the wallet.
          const session = await approval();
          thunkApi.dispatch(
            setAddress({
              address: session.namespaces.eip155.accounts[0].split(
                ":",
              )[2] as `0x${string}`,
            }),
          );

          walletConnectModal.closeModal();
        }
      } catch (e) {
        console.error(e);
      }
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      const addresses = await walletClient.requestAddresses();
      if (addresses) {
        setAddress({
          address: addresses[0],
        });
      }
    }
  },
);

export const disconnectThunk = createAsyncThunk(
  "auth/disconnect",
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (rootState.wallet.wallet === "walletconnect") {
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      if (lastSession) {
        await signClient.disconnect({
          topic: lastSession.topic,
          reason: {
            data: "Logout",
            code: 200,
            message: "Logout",
          },
        });
        thunkApi.dispatch(set({ token: null, expires_in: 0 }));
        thunkApi.dispatch(setAddress({ address: null }));
      }
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (walletClient) {
        thunkApi.dispatch(set({ token: null, expires_in: 0 }));
        thunkApi.dispatch(setAddress({ address: null }));
      }
    }
  },
);

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{ token: string | null; expires_in: number }>,
    ) => {
      state.token = action.payload.token;
      if (state.token) {
        localStorage.setItem(
          `session:token:${state.address}`,
          action.payload.token,
        );
        localStorage.setItem(
          `session:expiry:${state.address}`,
          action.payload.expires_in.toString(),
        );
      } else {
        localStorage.removeItem(`session:token:${state.address}`);
      }
      return state;
    },
    authing: (state, action: PayloadAction<boolean>) => {
      state.authing = action.payload;
      return state;
    },
    setAddress: (state, action: PayloadAction<{ address: `0x${string}` }>) => {
      state.address = action.payload.address;
      localStorage.setItem("session:address", action.payload.address);
      try {
        state.token = localStorage.getItem(
          `session:token:${action.payload.address}`,
        );
        state.expiry = parseInt(
          localStorage.getItem(`session:expiry:${action.payload.address}`),
        );
      } catch (e) {
        console.log(e);
      }
      return state;
    },
  },
});

export const { set, authing, setAddress } = AuthSlice.actions;

export default AuthSlice.reducer;
