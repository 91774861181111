import {
  AlertDialog,
  AlertDialogOverlay,
  Image,
  Stack,
  UseDisclosureProps,
  Text,
  Box,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { numToSide } from "../routes/games/utils";

export interface GameNotificationProps {
  title: "end" | "countdown" | "stop" | "offline" | "continue";
  bet?: string;
  win?: string;
  lose?: string;
  onContinue?: () => void;
  winner?: string;
}

export function GameNotification(
  props: GameNotificationProps & UseDisclosureProps,
) {
  const cancelRef = useRef();
  const [countdown, setCountdown] = useState(5);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [continued, setContinued] = useState(false);

  useEffect(() => {
    if (props.title === "continue") {
      setCountdown(5);
      setContinued(false);
      setIntervalId(
        setInterval(() => {
          setCountdown((countdown) => countdown - 1);
        }, 1000),
      );
    }
  }, [props.title]);

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(intervalId!);
    }
  }, [countdown, props, intervalId]);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <Stack justifyContent="center" h="100%" color="white">
          <Box position="relative">
            <Image src="/img/game/bg-notify-2.png" opacity="0.5" />
            <Text
              position="absolute"
              top="35%"
              width="100%"
              textAlign="center"
              fontSize="xx-large"
              fontWeight="900"
              filter="drop-shadow(2px 2px 0 #062083)"
              rotate="-10deg"
              transform="auto"
            >
              {props.title === "stop" && (
                <FormattedMessage
                  defaultMessage="暂停选择"
                  id="Game.Guess.stop"
                />
              )}
              {props.title === "offline" && (
                <>
                  <FormattedMessage
                    defaultMessage="游戏结束"
                    id="Game.Guess.end"
                  />
                  <br />
                  <FormattedMessage
                    defaultMessage="下次再来！"
                    id="Game.Guess.nextTime"
                  />
                </>
              )}
              {props.title === "end" && (
                <FormattedMessage
                  defaultMessage="游戏结束"
                  id="Game.Guess.end"
                />
              )}
              {props.title === "continue" && (
                <FormattedMessage
                  defaultMessage="游戏结束"
                  id="Game.Guess.end"
                />
              )}
            </Text>
            {props.winner && (
              <Text
                position="absolute"
                top="50%"
                width="100%"
                textAlign="center"
                transform="auto"
                rotate="-10deg"
                fontSize="large"
              >
                {props.winner === "obj_3" && "平"}
                {props.winner !== "obj_3" && (
                  <div>{numToSide(parseInt(props.winner.split("_")[1]))}胜</div>
                )}
              </Text>
            )}
            {props.win && props.bet && props.lose && (
              <>
                <Text
                  position="absolute"
                  top="60%"
                  width="100%"
                  fontWeight="900"
                  filter="drop-shadow(2px 2px 0 #062083)"
                  rotate="-10deg"
                  transform="auto"
                  textAlign="center"
                  fontSize="small"
                >
                  <FormattedMessage defaultMessage="参与" id="Game.Guess.in" />{" "}
                  <Text as="span" color="#ffee35">
                    {props.bet ?? "0.0000"}
                  </Text>
                  {"IEB "}
                  <FormattedMessage
                    defaultMessage="获得"
                    id="Game.Guess.win"
                  />{" "}
                  <Text as="span" color="#ffee35">
                    {props.win ?? "0.0000"}
                  </Text>
                  {"IEB "}
                  <FormattedMessage
                    defaultMessage="获得"
                    id="Game.Guess.win"
                  />{" "}
                  <Text as="span" color="#ffee35">
                    {props.lose ?? "0.0000"} IEC
                  </Text>
                </Text>
              </>
            )}
            {props.title === "countdown" && (
              <>
                <Text
                  position="absolute"
                  top="65%"
                  left="calc(50% - 50px)"
                  fontWeight="900"
                  filter="drop-shadow(2px 2px 0 #062083)"
                  rotate="-10deg"
                  transform="auto"
                >
                  <FormattedMessage
                    defaultMessage="即将开启下一局"
                    id="Game.Guess.prepare"
                  />
                  <Spinner color="white" w="16px" h="16px" marginLeft="16px" />
                </Text>
              </>
            )}
            {props.title === "continue" && (
              <Box
                position="absolute"
                top="75%"
                left="calc(50% - 80px)"
                rotate="-10deg"
                transform="auto"
              >
                <Button
                  colorScheme={countdown <= 0 || continued ? "gray" : "blue"}
                  mr={3}
                  onClick={() => {
                    if (countdown <= 0 || continued) return;
                    setContinued(true);
                    props.onContinue?.();
                  }}
                  disabled={countdown <= 0 || continued}
                >
                  <FormattedMessage
                    defaultMessage="继续排队"
                    id="Game.Guess.continue"
                  />{" "}
                  ({countdown})
                </Button>
                <Button variant="ghost" onClick={() => props.onClose()}>
                  <FormattedMessage
                    defaultMessage="取消"
                    id="Game.Guess.cancel"
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
