import { ReactNode, useEffect, useState } from "react";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { useAppSelector } from "../stores/global";

async function loadLocaleData(locale: string) {
  switch (locale) {
    case "EN":
      return await import("../../compiled-lang/EN.json");
    default:
      return await import("../../compiled-lang/CN.json");
  }
}

export type IntlWrapperProps = {
  children?: ReactNode;
};

export function IntlWrapper({ children }: IntlWrapperProps) {
  const locale = useAppSelector((state) => state.locale);
  const [messages, setMessages] = useState<Record<string, MessageFormatElement>>({});
  useEffect(() => {
    loadLocaleData(locale).then((messages) => {
      setMessages(messages.default as any);
    });
  }, [locale]);

  return (
    <>
      <IntlProvider locale={locale} defaultLocale="CN" messages={messages as any}>
        {children}
      </IntlProvider>
    </>
  );
}
