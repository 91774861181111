import { useEffect, useState } from "react";
import { BetRecord } from "../../types";
import { useGetMyBetRecordQuery } from "../../stores/api";
import { unionWith } from "lodash";
import { FormattedMessage } from "react-intl";
import QueryPage from "../../components/QueryPage";
import { Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { numToSide, numToStar } from "./utils";
import { useParams } from "react-router-dom";

export function MyRecord() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<BetRecord[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const { type } = useParams();

  const { error, data, isFetching, refetch, requestId } =
    useGetMyBetRecordQuery({ page, type });

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTotal((total) => {
        return unionWith(total, data?.data?.data ?? [], (a, b) => {
          return a.id === b.id;
        });
      });
    }
  }, [data?.data, requestId]);

  useEffect(() => {
    setCanFetchMore(
      !isFetching && total.length !== 0 && page < data?.data?.last_page,
    );
  }, [page, data?.data?.last_page, total, isFetching]);

  return (
    <QueryPage
      error={error}
      refetch={() => {
        setTotal([]);
        setPage(1);
        refetch();
      }}
      isLoading={isFetching}
      onFetchMore={async () => {
        if (!isFetching) {
          setPage(page + 1);
        }
      }}
      hasMore={canFetchMore}
      errorMessage={
        <FormattedMessage id="Games.Record.error" defaultMessage="数据错误" />
      }
    >
      <Stack>
        {total.map((item) => {
          return (
            <Grid
              templateColumns="1fr 1fr 1fr 1fr"
              bgColor="#ffffff22"
              color="white"
              p="16px"
              rounded="8px"
              gap="8px"
            >
              <GridItem>
                <Stack
                  align="center"
                  bg="#ffffff22"
                  py="4px"
                  rounded="4px"
                  gap="16px"
                >
                  <Text>
                    <FormattedMessage
                      defaultMessage="期数"
                      id="Game.MyRecord.round"
                    />
                  </Text>
                  <Text>{item.hide_rec_id}</Text>
                </Stack>
              </GridItem>
              <GridItem>
                <Stack
                  align="center"
                  bg="#ffffff22"
                  py="4px"
                  rounded="4px"
                  gap="16px"
                >
                  {type === "2" && (
                    <>
                      <Text>
                        <FormattedMessage
                          defaultMessage="下注星球"
                          id="Game.MyRecord.betStar"
                        />
                      </Text>
                      <Text>
                        {numToStar(parseInt(item.betting_id.split("_")[1]))}
                      </Text>
                    </>
                  )}
                  {type === "1" && (
                    <>
                      <Text>
                        <FormattedMessage
                          defaultMessage="下注"
                          id="Game.MyRecord.bet"
                        />
                      </Text>
                      <Text>
                        {numToSide(parseInt(item.betting_id.split("_")[1]))}
                      </Text>
                    </>
                  )}
                </Stack>
              </GridItem>
              <GridItem>
                <Stack
                  align="center"
                  bg="#ffffff22"
                  py="4px"
                  rounded="4px"
                  gap="16px"
                >
                  <Text>
                    <FormattedMessage
                      defaultMessage="输/赢"
                      id="Game.MyRecord.lostOrWin"
                    />
                  </Text>
                  {item.game_lose === 1 && (
                    <Text color="red">
                      <FormattedMessage
                        defaultMessage="赢"
                        id="Game.MyRecord.win"
                      />
                    </Text>
                  )}
                  {item.game_lose === 2 && (
                    <Text color="green">
                      <FormattedMessage
                        defaultMessage="输"
                        id="Game.MyRecord.lost"
                      />
                    </Text>
                  )}
                  {item.game_lose === 3 && (
                    <Text color="white">
                      <FormattedMessage
                        defaultMessage="平"
                        id="Game.MyRecord.tie"
                      />
                    </Text>
                  )}
                </Stack>
              </GridItem>
              <GridItem>
                <Stack
                  align="center"
                  bg="#ffffff22"
                  py="4px"
                  rounded="4px"
                  gap="16px"
                >
                  <Text>
                    <FormattedMessage
                      defaultMessage="数量"
                      id="Game.MyRecord.number"
                    />
                  </Text>
                  <Text>{item.game_lose === 1 ? item.win_num : item.num}</Text>
                </Stack>
              </GridItem>
            </Grid>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
