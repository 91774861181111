import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Asset,
  Carousel,
  List,
  Notice,
  Robot,
  TeamListItem,
  Response,
  MyRobot,
  UserTeam,
  TransactionItem,
  UserInfo,
  GameRecord,
  BetRecord,
  KLine,
  SwapPairs,
  SwapRate,
  Invite,
  PoolOverview,
  PoolJoinRecord,
  LiquidityPoolJoinRecord,
  LiquidityPoolOverview,
} from "../types";
import { RootState } from "./global";

export const host = import.meta.env.VITE_API_HOST;

const makeApi = () => {
  return createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
      baseUrl: host,
      timeout: 15000,
      validateStatus: (_response, body) => {
        if (body.code === "9001") {
          const address = localStorage.getItem("session:address");
          localStorage.removeItem(`session:token:${address}`);
          window.location.replace("/");
          return false;
        }
        return true;
      },
      prepareHeaders: (headers, api) => {
        const state = api.getState() as RootState;
        headers.set("Authorization", state.auth.token);
        headers.set("Lang", state.locale === "EN" ? "en" : "ch");
        return headers;
      },
    }),
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
      getHomeCarousel: builder.query<Response<Carousel>, void>({
        query: () => "/api/home/carousel",
      }),
      getRobotList: builder.query<Response<Robot[]>, void>({
        query: () => "/api/robot/robotList",
      }),
      getAsset: builder.query<Response<Asset[]>, void>({
        query: () => ({
          url: "/api/user/userAccount",
        }),
      }),
      getHomeNotice: builder.query<Response<Notice[]>, void>({
        query: () => "/api/home/notice",
      }),
      getMyRobotList: builder.query<Response<MyRobot[]>, void>({
        query: () => ({
          url: "/api/robot/myRobotList",
        }),
      }),
      getUserTeam: builder.query<Response<UserTeam>, void>({
        query: () => "/api/user/userteam",
      }),
      getTeamList: builder.query<
        Response<List<TeamListItem>>,
        { address?: string; page?: number }
      >({
        query: ({ address, page = 1 }) => {
          return {
            url: "/api/user/teamlist",
            params: {
              paddress: address,
              page,
            },
            method: "GET",
          };
        },
      }),
      getUserTransaction: builder.query<
        Response<List<TransactionItem>>,
        { coin: string; page: number }
      >({
        query: ({ page, coin }) => {
          return {
            url: "/api/user/accountLog",
            method: "GET",
            params: {
              coin_name: coin,
              page: page,
            },
          };
        },
      }),
      postBuyRobot: builder.mutation<
        Response<string>,
        { id: string; hash: string }
      >({
        query: ({ id, hash }) => {
          const fd = new FormData();
          fd.append("robot_id", id);
          fd.append("hash", hash);
          return {
            url: "/api/robot/buyRobot",
            method: "POST",
            body: fd,
          };
        },
      }),
      getPayAddress: builder.query<Response<string>, "robot" | "charge">({
        query: (type) => {
          return {
            url: "/api/robot/getPayAdd",
            params: {
              type,
            },
            method: "GET",
          };
        },
      }),
      getBuyCheck: builder.query<Response<boolean>, number>({
        query: (id) => {
          return {
            url: "/api/robot/buyCheck",
            params: {
              robot_id: id,
            },
            method: "GET",
          };
        },
      }),
      getUserInfo: builder.query<Response<UserInfo>, void>({
        query: () => {
          return {
            url: "/api/user/userinfo",
            method: "GET",
          };
        },
      }),
      getNoticeList: builder.query<Response<Notice[]>, void>({
        query: () => "/api/home/notice",
      }),
      getFlashRate: builder.query<
        Response<string>,
        { from_coin: string; to_coin: string; num: string }
      >({
        query: (args) => {
          return {
            url: "/api/user/flashRate",
            method: "GET",
            params: args,
          };
        },
      }),
      postExchange: builder.mutation<
        Response<string>,
        {
          from_coin: string;
          to_coin: string;
          num: string;
        }
      >({
        query: (args) => {
          const fd = new FormData();
          fd.append("from_coin", args.from_coin);
          fd.append("to_coin", args.to_coin);
          fd.append("num", args.num);
          return {
            url: "/api/user/flashExchange",
            method: "POST",
            body: fd,
          };
        },
      }),
      postWithdraw: builder.mutation<
        Response<string>,
        { coin: string; num: string }
      >({
        query: ({ coin, num }) => {
          const fd = new FormData();
          fd.append("coin", coin);
          fd.append("number", num);
          return {
            url: "/api/user/withdrawal",
            method: "POST",
            body: fd,
          };
        },
      }),
      getGameRecord: builder.query<
        Response<List<GameRecord>>,
        { page: number; type: string }
      >({
        query: ({ page, type }) => {
          return {
            url: "/api/game/getPreRecord",
            params: {
              page: page,
              game_type: type,
            },
          };
        },
      }),
      getBettingNum: builder.query<Response<{ betting: string[] }>, number>({
        query: (type) => {
          return {
            url: "/api/game/getBettingNum",
            params: {
              game_type: type,
            },
          };
        },
      }),
      getGameOff: builder.query<Response<unknown>, void>({
        query: () => "/api/game/gameOff",
      }),
      getMyBetRecord: builder.query<
        Response<List<BetRecord>>,
        { page: number; type: string }
      >({
        query: ({ page, type }) => {
          return {
            url: "/api/game/getMyBetRecord",
            params: {
              page: page,
              game_type: type,
            },
          };
        },
      }),
      getGuesserBetNum: builder.query<Response<{ betting: string[] }>, void>({
        query: () => "/api/game/getGuesserBetNum",
      }),
      getRobStatus: builder.query<Response<unknown>, void>({
        query: () => "/api/inscription/robState",
      }),
      getRandomIECC: builder.query<
        Response<{ random: string; ieb_price: string }>,
        void
      >({
        query: () => "/api/inscription/randomIECC",
      }),
      getKLine: builder.query<
        Response<{ Kline: Array<KLine>; increase: string }>,
        { day: number; type: string }
      >({
        query: ({ day, type }) => {
          return {
            url: "/api/inscription/KLine",
            params: {
              day,
              type,
            },
          };
        },
      }),
      getBuyIECC: builder.query<Response<unknown>, void>({
        query: () => "/api/inscription/robIECC",
      }),
      getSwapPairs: builder.query<Response<SwapPairs>, void>({
        query: () => "/api/user/getFlashPairs",
      }),
      getSwapRate: builder.query<
        Response<SwapRate>,
        { from: string; to: string; num: string }
      >({
        query: ({ from, to, num }) => {
          return {
            url: "/api/user/flashRate",
            params: { from_coin: from, to_coin: to, num },
          };
        },
      }),
      getFeedback: builder.query<
        Response<unknown>,
        { text: string; email: string }
      >({
        query: ({ text, email }) => {
          return {
            url: "/api/home/submitQue",
            params: { text, email },
          };
        },
      }),
      getInviteUrl: builder.query<Response<Invite>, void>({
        query: () => "/api/home/inviteUrl",
      }),
      postBuyIEBRobot: builder.mutation<Response<boolean>, string>({
        query: (num) => {
          const fd = new FormData();
          fd.append("num", num);
          return {
            url: "/api/robot/buyIebRobot",
            method: "POST",
            body: fd,
          };
        },
      }),
      getPoolOverview: builder.query<Response<PoolOverview>, void>({
        query: () => "/api/robot/overview",
      }),
      getPoolRecord: builder.query<Response<List<PoolJoinRecord>>, number>({
        query: (page) => {
          return {
            url: "/api/robot/myIebRobotList",
            params: {
              page,
            },
          };
        },
      }),
      getActiveOrder: builder.query<Response<unknown>, void>({
        query: () => {
          return {
            url: "/api/robot/activeOrder",
          };
        },
      }),
      postCaptcha: builder.mutation<Response<string>, void>({
        query: () => ({
          url: "/api/user/randCode",
          method: "GET",
        }),
      }),
      getInterTransferAmount: builder.query<
        Response<{ to_num: string }>,
        string
      >({
        query: (amount) => {
          return {
            url: "/api/user/interTransforPre",
            params: {
              num: amount,
            },
          };
        },
      }),
      postInterTransfer: builder.mutation<
        Response<string>,
        { num: string; address: string; code: string }
      >({
        query: ({ num, address, code }) => {
          return {
            url: "/api/user/interTransfor",
            method: "GET",
            params: {
              num,
              toaddress: address,
              code,
            },
          };
        },
      }),
      getLiquidityPoolRecord: builder.query<
        Response<List<LiquidityPoolJoinRecord>>,
        number
      >({
        query: (page) => {
          return {
            url: "/api/robot/myLoopRobotList",
            params: {
              page,
            },
          };
        },
      }),
      getLiquidityPoolRecordDetail: builder.query<
        Response<LiquidityPoolJoinRecord>,
        string
      >({
        query: (id) => {
          return {
            url: "/api/robot/getDetailById",
            params: {
              id,
            },
          };
        },
      }),
      getLiquidityPoolOverview: builder.query<
        Response<LiquidityPoolOverview>,
        void
      >({
        query: () => "/api/robot/loopOverview",
      }),
      getBuyLiquidityPool: builder.mutation<
        Response<boolean>,
        { num: string; oneday: boolean; payType: string }
      >({
        query: ({ num, oneday, payType }) => {
          return {
            url: "/api/robot/buyLoopRobot",
            method: "GET",
            params: {
              num,
              one_day: oneday ? 1 : 0,
              pay_type: payType,
            },
          };
        },
      }),
      postLiquidityPoolSwitch: builder.mutation<
        Response<boolean>,
        { is_auto: 0 | 1; id: string }
      >({
        query: ({ is_auto, id }) => {
          return {
            url: "/api/robot/switchAuto",
            method: "GET",
            params: {
              is_auto,
              id,
            },
          };
        },
      }),
      postLiquidityPoolClaim: builder.mutation<Response<boolean>, string>({
        query: (id) => {
          return {
            url: "/api/robot/loopReceive",
            method: "GET",
            params: {
              id,
            },
          };
        },
      }),
      getAccountBalance: builder.query<Response<{ available: string }>, void>({
        query: () => {
          return {
            url: "/api/user/getAccountByCoin",
            params: { coin: "IEBB" },
          };
        },
      }),
      payIEBB: builder.mutation<Response<boolean>, string>({
        query: (num) => {
          return {
            url: "/api/inscription/robIEBB",
            method: "GET",
            params: { num },
          };
        },
      }),
      rushIEBB: builder.mutation<
        Response<{ random: string; iebb_price: string }>,
        void
      >({
        query: () => {
          return { url: "/api/inscription/randomIEBB", method: "GET" };
        },
      }),
      getLiquidityStatus: builder.query<Response<string>, void>({
        query: () => {
          return { url: "/api/robot/getLoopStatus" };
        },
      }),
      getIEBBPrice: builder.query<Response<string>, string>({
        query: (num) => {
          return { url: "/api/inscription/getRobPreIEbb", params: { num } };
        },
      }),
      getIpoAddress: builder.query<Response<string>, string>({
        query: (type) => {
          return { url: "/api/ipo/getIpoAddress", params: { type } };
        },
      }),
      getIpoIsBond: builder.query<
        Response<boolean>,
        { type: string; address: string }
      >({
        query: ({ type, address }) => {
          return { url: "/api/ipo/isBind", params: { type, address } };
        },
      }),
      getIpoInviteUrl: builder.query<
        Response<{ link: string; code: string }>,
        { type: string; address: string }
      >({
        query: ({ type, address }) => {
          return { url: "/api/ipo/ipoinviteUrl", params: { type, address } };
        },
      }),
      postPayCheck: builder.mutation<
        Response<boolean>,
        { address: string; num: string; type: string }
      >({
        query: ({ address, num, type }) => {
          return {
            url: "/api/ipo/payCheck",
            method: "GET",
            params: { address, num, type },
          };
        },
      }),
      getIpoList: builder.query<
        Response<
          Array<{ id: string; address: string; ctime: string; desc: string }>
        >,
        { type: string; address: string }
      >({
        query: ({ type, address }) => {
          return {
            url: "/api/ipo/inviteAddress",
            params: { type, address },
          };
        },
      }),
      getIsNode: builder.query<
        Response<string>,
        { type: string; address: string }
      >({
        query: ({ type, address }) => {
          return {
            url: "/api/ipo/isNode",
            params: { type, address },
          };
        },
      }),
      getPreLoopPrice: builder.query<Response<string>, string>({
        query: (num) => {
          return {
            url: "/api/robot/preLoopPrice",
            params: { num },
          };
        },
      }),
      iebTransfer: builder.mutation<Response<boolean>, void>({
        query: () => ({
          url: "api/user/iebTransfer",
          method: "GET",
        }),
      }),
      chainBalance: builder.query<
        Response<{ iebb: string; usdt: string; bnb: string }>,
        void
      >({
        query: () => ({
          url: "api/wallet/getChainBalance",
        }),
      }),
      getChargeAddress: builder.query<Response<string>, void>({
        query: () => ({
          url: "/api/wallet/chargeIebbAdd",
        }),
      }),
      getLevel: builder.query<Response<{ node: string; star: string }>, void>({
        query: () => ({
          url: "/api/robot/getLevel",
        }),
      }),
      getNiceOpen: builder.query<Response<number>, void>({
        query: () => ({
          url: "/api/home/getNiceOpen",
        }),
      }),
    }),
  });
};

export const Api = makeApi();

export const {
  useGetHomeCarouselQuery,
  useGetRobotListQuery,
  usePostBuyRobotMutation,
  useGetAssetQuery,
  useGetMyRobotListQuery,
  useGetUserTeamQuery,
  useGetTeamListQuery,
  useGetUserTransactionQuery,
  useGetPayAddressQuery,
  useGetBuyCheckQuery,
  useGetUserInfoQuery,
  useGetNoticeListQuery,
  useGetFlashRateQuery,
  usePostExchangeMutation,
  usePostWithdrawMutation,
  useGetGameRecordQuery,
  useGetBettingNumQuery,
  useGetGameOffQuery,
  useGetMyBetRecordQuery,
  useGetGuesserBetNumQuery,
  useGetRobStatusQuery,
  useGetRandomIECCQuery,
  useGetKLineQuery,
  useGetBuyIECCQuery,
  useGetSwapPairsQuery,
  useGetSwapRateQuery,
  useGetFeedbackQuery,
  useGetInviteUrlQuery,
  usePostBuyIEBRobotMutation,
  useGetPoolOverviewQuery,
  useGetPoolRecordQuery,
  useGetActiveOrderQuery,
  usePostCaptchaMutation,
  useGetInterTransferAmountQuery,
  usePostInterTransferMutation,
  useGetLiquidityPoolRecordQuery,
  useGetLiquidityPoolOverviewQuery,
  useGetBuyLiquidityPoolMutation,
  usePostLiquidityPoolSwitchMutation,
  usePostLiquidityPoolClaimMutation,
  useGetLiquidityPoolRecordDetailQuery,
  useGetAccountBalanceQuery,
  usePayIEBBMutation,
  useRushIEBBMutation,
  useGetLiquidityStatusQuery,
  useGetIEBBPriceQuery,
  useGetIpoAddressQuery,
  useGetIpoIsBondQuery,
  useGetIpoInviteUrlQuery,
  usePostPayCheckMutation,
  useGetIpoListQuery,
  useGetIsNodeQuery,
  useGetPreLoopPriceQuery,
  useIebTransferMutation,
  useGetChargeAddressQuery,
  useGetLevelQuery,
  useGetNiceOpenQuery,
} = Api;
