import { FormattedMessage } from "react-intl";
import { Image, Link, Flex, Box, Text, Stack } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useGetUserInfoQuery } from "../../stores/api";
import QueryPage from "../../components/QueryPage";
import { range } from "lodash";

export default function Profile() {
  const { data, error, isFetching, refetch } = useGetUserInfoQuery();
  return (
    <QueryPage
      error={error}
      refetch={refetch}
      hasMore={false}
      isLoading={isFetching}
      errorMessage={
        <FormattedMessage id="Profile.error" defaultMessage="数据加载错误" />
      }
      p="0"
    >
      <div>
        <Stack textAlign="center" mb="20px">
          <Box
            position={"relative"}
            display={"inline-block"}
            m="auto"
            boxSize={"130px"}
            boxSizing={"border-box"}
            rounded="50%"
            p="3px"
            bg="linear-gradient(135deg, #709e4c, #3d856d)"
          >
            <Image
              src={data?.data?.pic}
              w={"100%"}
              h={"100%"}
              fit="contain"
              rounded="50%"
              overflow="hidden"
              bg="#d6cab3"
            />
          </Box>
          <Text>
            {range(0, data?.data?.star_lv, 1).map(() => {
              return "⭐️";
            })}
          </Text>
          <Flex color="#6dfeb4" justifyContent={"center"} gap="16px">
            <Text>{data?.data?.address}</Text>
          </Flex>
        </Stack>
        <div>
          <Box mb="15px">
            <Link to="/profile/community" as={ReactRouterLink}>
              <Flex
                align="center"
                bg="#282636"
                w="100%"
                p={4}
                rounded={10}
                color="white"
              >
                <Flex align="center" flex="1">
                  <Image
                    src="/img/icon-cell-community.png"
                    alt="community"
                    boxSize="28px"
                    fit="contain"
                    mr={4}
                  />
                  <span>
                    <FormattedMessage
                      id="Profile.community"
                      defaultMessage="社区"
                    />
                  </span>
                </Flex>
                <ChevronRightIcon boxSize={6} color="#aaaaaa" />
              </Flex>
            </Link>
          </Box>
          <Box mb="15px">
            <Link to="/pool/liquidity/list" as={ReactRouterLink}>
              <Flex
                align="center"
                bg="#282636"
                w="100%"
                p={4}
                rounded={10}
                color="white"
              >
                <Flex align="center" flex="1">
                  <Image
                    src="/img/icon-cell-miningrobot.png"
                    alt="miningrobot"
                    boxSize="28px"
                    fit="contain"
                    mr={4}
                  />
                  <span>
                    <FormattedMessage
                      id="Profile.pool.liquidity"
                      defaultMessage="流动矿池"
                    />
                  </span>
                </Flex>
                <ChevronRightIcon boxSize={6} color="#aaaaaa" />
              </Flex>
            </Link>
          </Box>
          <Box mb="15px">
            <Link to="/pool/list" as={ReactRouterLink}>
              <Flex
                align="center"
                bg="#282636"
                w="100%"
                p={4}
                rounded={10}
                color="white"
              >
                <Flex align="center" flex="1">
                  <Image
                    src="/img/icon-cell-miningrobot.png"
                    alt="miningrobot"
                    boxSize="28px"
                    fit="contain"
                    mr={4}
                  />
                  <span>
                    <FormattedMessage
                      id="Profile.pool.ieb"
                      defaultMessage="矿池"
                    />
                  </span>
                </Flex>
                <ChevronRightIcon boxSize={6} color="#aaaaaa" />
              </Flex>
            </Link>
          </Box>
          <Box mb="15px">
            <Link href="https://inscriptionera.com">
              <Flex
                align="center"
                bg="#282636"
                w="100%"
                p={4}
                rounded={10}
                color="white"
              >
                <Flex align="center" flex="1">
                  <Image
                    src="/img/icon-cell-aboutus.png"
                    alt="aboutus"
                    boxSize="28px"
                    fit="contain"
                    mr={4}
                  />
                  <span>
                    <FormattedMessage
                      id="Profile.aboutus"
                      defaultMessage="关于我们"
                    />
                  </span>
                </Flex>
                <ChevronRightIcon boxSize={6} color="#aaaaaa" />
              </Flex>
            </Link>
          </Box>
        </div>
      </div>
    </QueryPage>
  );
}
