// "use client";
//
// import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
//
// import { WagmiConfig } from "wagmi";
// import { bsc } from "viem/chains";
// import { ReactNode } from "react";
//
// // 1. Get projectId at https://cloud.walletconnect.com
export const projectId = "34fe855222c2de54d6234d64060f7761";
//
// // 2. Create wagmiConfig
// const metadata = {
//   name: "Inscription Era",
//   description: "Inscription Era",
//   url: "https://www.ieb.ink",
//   icons: ["https://avatars.githubusercontent.com/u/37784886"],
// };
//
// const chains = [bsc];
// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
//
// // 3. Create modal
// createWeb3Modal({
//   wagmiConfig,
//   projectId,
//   chains,
// });
//
// export function Web3Modal({ children }: { children: ReactNode }) {
//   return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
// }
