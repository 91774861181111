import {
  AlertDialog,
  AlertDialogOverlay,
  Image,
  Stack,
  UseDisclosureProps,
  Text,
  Box,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { playSound } from "../routes/games/utils";

export interface GameNotificationProps {
  title: "end" | "countdown" | "stop" | "offline" | "continue";
  bet?: string;
  win?: string;
  lose?: string;
  onContinue?: () => void;
}

export function GameNotification(
  props: GameNotificationProps & UseDisclosureProps,
) {
  const cancelRef = useRef();
  return (
    <AlertDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <Stack justifyContent="center" h="100%" color="white">
          <Box position="relative">
            <Image src="/img/game/bg-notify.png" opacity="0.5" />
            <Text
              position="absolute"
              top="45%"
              left="calc(50% - 60px)"
              fontSize="xx-large"
              fontWeight="900"
              filter="drop-shadow(2px 2px 0 #062083)"
              rotate="-10deg"
              transform="auto"
            >
              {props.title === "stop" && (
                <FormattedMessage
                  defaultMessage="暂停选择"
                  id="Game.Guess.stop"
                />
              )}
              {props.title === "offline" && (
                <>
                  <FormattedMessage
                    defaultMessage="游戏结束"
                    id="Game.Guess.end"
                  />
                  <br />
                  <FormattedMessage
                    defaultMessage="下次再来！"
                    id="Game.Guess.nextTime"
                  />
                </>
              )}
              {props.title === "end" && (
                <FormattedMessage
                  defaultMessage="游戏结束"
                  id="Game.Guess.end"
                />
              )}
              {props.title === "continue" && (
                <FormattedMessage
                  defaultMessage="游戏结束"
                  id="Game.Guess.end"
                />
              )}
            </Text>
            {props.win && props.bet && props.lose && (
              <>
                <Text
                  position="absolute"
                  top="65%"
                  left="calc(30% - 60px)"
                  fontWeight="900"
                  filter="drop-shadow(2px 2px 0 #062083)"
                  rotate="-10deg"
                  transform="auto"
                >
                  <FormattedMessage defaultMessage="参与" id="Game.Guess.in" />{" "}
                  <Text as="span" color="#ffee35">
                    {props.bet ?? "0.0000"}
                  </Text>
                  {"IEBB "}
                  <FormattedMessage
                    defaultMessage="获得"
                    id="Game.Guess.win"
                  />{" "}
                  <Text as="span" color="#ffee35">
                    {props.win ?? "0.0000"}
                  </Text>
                  {"IEBB "}
                  <FormattedMessage
                    defaultMessage="获得"
                    id="Game.Guess.win"
                  />{" "}
                  <Text as="span" color="#ffee35">
                    {props.lose ?? "0.0000"} IEC
                  </Text>
                </Text>
              </>
            )}
            {props.title === "countdown" && (
              <>
                <Text
                  position="absolute"
                  top="65%"
                  left="calc(50% - 50px)"
                  fontWeight="900"
                  filter="drop-shadow(2px 2px 0 #062083)"
                  rotate="-10deg"
                  transform="auto"
                >
                  <FormattedMessage
                    defaultMessage="即将开启下一局"
                    id="Game.Guess.prepare"
                  />
                  <Spinner color="white" w="16px" h="16px" marginLeft="16px" />
                </Text>
              </>
            )}
            {props.title === "continue" && (
              <>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    props.onContinue?.();
                    playSound("bet");
                  }}
                >
                  <FormattedMessage
                    defaultMessage="继续排队"
                    id="Game.Guess.continue"
                  />
                </Button>
                <Button variant="ghost" onClick={() => props.onClose()}>
                  <FormattedMessage
                    defaultMessage="取消"
                    id="Game.Guess.cancel"
                  />
                </Button>
              </>
            )}
          </Box>
        </Stack>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
