import {
  Button,
  Center,
  Container,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import SecondaryHeader from "../../components/SecondaryHeader";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GradientButton } from "../../components/GradientButton";
import {
  Api,
  useGetSwapPairsQuery,
  useGetSwapRateQuery,
} from "../../stores/api";
import { useDebounce } from "usehooks-ts";
import { entries, keys } from "lodash";
import { useAppDispatch } from "../../stores/global";

export function Swap() {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [balance, setBalance] = useState("0");
  const [amount, setAmount] = useState("");
  const swapPairs = useGetSwapPairsQuery();
  const debouncedAmount = useDebounce(amount, 200);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const swapRate = useGetSwapRateQuery(
    {
      from: from,
      to: to,
      num: debouncedAmount.toString(),
    },
    {
      skip: Number(debouncedAmount) === 0,
    }
  );

  useEffect(() => {
    if (swapPairs.data) {
      const first = entries(swapPairs.data.data.pairs)[0];
      setFrom(first[0]);
    }
  }, [swapPairs.data]);

  useEffect(() => {
    if (swapPairs.data && from) {
      setTo(swapPairs.data.data.pairs[from][0]);
      setBalance(swapPairs.data.data.balance[from]);
    }
  }, [from, swapPairs.data]);

  return (
    <Container pos="relative" gap="16px">
      <SecondaryHeader
        title={<FormattedMessage id="Swap.title" defaultMessage="闪兑" />}
      />
      <Stack
        gap="4px"
        color="white"
        borderWidth="1px"
        borderRadius="16px"
        borderColor="#ccc"
        borderStyle="solid"
        p="16px"
      >
        <Flex justify="space-between" align="center">
          <Text fontSize="large" fontWeight="bold">
            <FormattedMessage id="Swap.send" defaultMessage="转出" />
          </Text>
          <Text>
            <FormattedMessage id="Swap.balance" defaultMessage="可用" />:
            {balance}
          </Text>
        </Flex>
        <Text
          color="lightblue"
          alignSelf="end"
          fontSize="small"
          onClick={() => {
            setAmount(balance);
          }}
        >
          <FormattedMessage id="Swap.max" defaultMessage="最大" />
        </Text>
        <Flex justify="space-between" gap="100px" align="center">
          <Flex
            bgColor="#040404"
            borderRadius="16px"
            py="4px"
            align="center"
            gap="16px"
          >
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                <Stack>
                  <Text>{from}</Text>
                </Stack>
              </MenuButton>
              <MenuList color="black">
                {keys(swapPairs.data?.data?.pairs).map((e) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setFrom(e);
                      }}
                    >
                      {e}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Flex>
          <Input
            type="number"
            value={amount}
            textAlign="end"
            onChange={(event) => {
              setAmount(event.target.value);
            }}
          />
        </Flex>
      </Stack>
      <Stack align="center" mt="16px">
        <ChevronDownIcon
          color="white"
          border="#fff 1px solid"
          borderRadius="48px"
          width="32px"
          height="32px"
        />
      </Stack>
      <Stack
        gap="16px"
        color="white"
        borderWidth="1px"
        borderRadius="16px"
        borderColor="#ccc"
        borderStyle="solid"
        p="16px"
        mt="16px"
      >
        <Flex justify="space-between" align="center">
          <Text fontSize="large" fontWeight="bold">
            <FormattedMessage id="Swap.receive" defaultMessage="接收" />
          </Text>
          <Text>
            <FormattedMessage
              id="Swap.aproximation"
              defaultMessage="预计接收数量"
            />
          </Text>
        </Flex>
        <Flex gap="16px" align="center">
          <Flex
            bgColor="#080808"
            borderRadius="16px"
            py="4px"
            align="center"
            gap="16px"
          >
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                <Stack>
                  <Text>{to}</Text>
                </Stack>
              </MenuButton>
              <MenuList color="black">
                {swapPairs.data?.data?.pairs?.[from]?.map((e) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setTo(e);
                      }}
                    >
                      {e}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Flex>
          <Spacer />
          {swapRate.isFetching && <Spinner />}
          <Text textAlign="end">
            {amount === "" ? "" : swapRate.data?.data?.expect_num}
          </Text>
        </Flex>
      </Stack>
      {isLoading && (
        <Center>
          <Spinner mt="16px" color="white" />
        </Center>
      )}
      {!isLoading && (
        <GradientButton
          fontSize="large"
          width="100%"
          mt="16px"
          onClick={async () => {
            setIsLoading(true);
            const response = await dispatch(
              Api.endpoints.postExchange.initiate({
                from_coin: from,
                to_coin: to,
                num: amount,
              })
            ).unwrap();
            if (response.data) {
              toast({
                title: (
                  <FormattedMessage
                    id="Swap.success"
                    defaultMessage="兑换成功"
                  />
                ),
              });
              setAmount("");
            }
            swapPairs.refetch();
            setIsLoading(false);
          }}
        >
          <FormattedMessage id="Swap.confirm" defaultMessage="确认兑换" />
        </GradientButton>
      )}
      <Stack
        gap="16px"
        color="white"
        borderWidth="1px"
        borderRadius="16px"
        borderColor="#ccc"
        borderStyle="solid"
        fontSize="small"
        p="16px"
        mt="16px"
      >
        <Flex justify="space-between" align="center">
          <Text color="#999">
            <FormattedMessage id="Swap.reference" defaultMessage="参考价格" />
          </Text>
          <Text>
            {amount === "" ? "" : swapRate.data?.data?.reference_price}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text color="#999">
            <FormattedMessage id="Swap.effect" defaultMessage="价格影响" />
          </Text>
          <Text>{amount === "" ? "" : swapRate.data?.data?.price_impact}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text color="#999">
            <FormattedMessage id="Swap.handling" defaultMessage="燃料" />
          </Text>
          <Text>{amount === "" ? "" : swapRate.data?.data?.fee}</Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Text color="#999">
            <FormattedMessage
              id="Swap.aproximation"
              defaultMessage="预计接收数量"
            />
          </Text>
          <Text>{amount === "" ? "" : swapRate.data?.data?.expect_num}</Text>
        </Flex>
      </Stack>
    </Container>
  );
}
