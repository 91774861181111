import { Flex, Image, useToast } from "@chakra-ui/react";
import { useSuffix } from "../../stores/locale";
import { useNavigate } from "react-router-dom";
import { Api } from "../../stores/api";
import { useAppDispatch } from "../../stores/global";
import { FormattedMessage } from "react-intl";

export function Game() {
  const dispatch = useAppDispatch();
  const subfix = useSuffix();
  const navigate = useNavigate();
  const toast = useToast();
  return (
    <Flex flexDirection="column" gap="16px" pb="100px">
      <Image
        src="/img/bg-game-5.jpg"
        onClick={() => {
          window.location.href = "https://k3-game.degengame.cc";
        }}
      />
      <Image
        src={`/img/bg-game-hide${subfix}.png`}
        onClick={async () => {
          const response = await dispatch(
            Api.endpoints.getGameOff.initiate(),
          ).unwrap();
          if (response?.code === "0") {
            navigate("/game/guess");
          } else {
            toast({
              title: response?.msg,
            });
          }
        }}
      />
      <Image
        src={`/img/bg-game-guess${subfix}.png`}
        onClick={() => navigate("/game/guess2")}
      />
      <Image
        src={`/img/bg-game-3${subfix}.png`}
        onClick={() =>
          toast({
            title: (
              <FormattedMessage
                id="Game.comingSoon"
                defaultMessage="敬请期待"
              />
            ),
          })
        }
      />
      <Image
        src={`/img/bg-game-4${subfix}.png`}
        onClick={() =>
          toast({
            title: (
              <FormattedMessage
                id="Game.comingSoon"
                defaultMessage="敬请期待"
              />
            ),
          })
        }
      />
    </Flex>
  );
}
