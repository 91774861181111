import {
  Button,
  Container,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Api, useGetAssetQuery } from "../../stores/api";
import { useAppDispatch } from "../../stores/global";
import { useState } from "react";
import { KLine } from "../../components/KLine";
import { GradientButton } from "../../components/GradientButton";

export function Rush() {
  const dispatch = useAppDispatch();
  const [result, setResult] = useState({
    random: "0",
    ieb_price: "0",
  });
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const { data } = useGetAssetQuery();

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Container
        bgImg="/img/inscription/bg.png"
        position="relative"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt="32px"
        pb="100px"
      >
        <KLine type="1" />
        <Image src="/img/inscription/icon.png" width="40%" mt="30px" />
        <Button
          bgImg="/img/inscription/button.png"
          width="200px"
          bgSize="contain"
          height="50px"
          bgRepeat="no-repeat"
          bgPos="center"
          fontSize="large"
          color="#044d2b"
          my="30px"
          onClick={async () => {
            setLoading(true);
            const data = await dispatch(
              Api.endpoints.getRandomIECC.initiate(),
            ).unwrap();
            if (data.code !== "0") {
              toast({
                title: data.msg,
                status: "error",
              });
            } else {
              setResult(data.data);
              onOpen();
            }
            setLoading(false);
          }}
        >
          {!loading && <FormattedMessage id="Rush.rush" defaultMessage="抢" />}
          {loading && <Spinner />}
        </Button>
        <Flex
          alignItems="center"
          bg="#ffffff33"
          rounded="16px"
          width="100%"
          px="16px"
          py="24px"
          m="16px"
        >
          <Stack>
            <Text color="white" fontSize="small">
              <FormattedMessage id="Asset.available" defaultMessage="可用" />
              IEC
            </Text>
            <Text color="white" fontSize="large" fontWeight="bold">
              {data?.data?.find((item) => item.coin_name === "IEC")
                ?.available || 0}
            </Text>
          </Stack>
          <Spacer />
          <GradientButton
            color="black"
            px="40px"
            py="4px"
            fontSize="small"
            onClick={() => {
              toast({
                title: (
                  <FormattedMessage id="Rush.coming" defaultMessage="余额不足" />
                ),
              });
            }}
          >
            <FormattedMessage id="Asset.forge" defaultMessage="铸造" />
          </GradientButton>
        </Flex>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="transparent" alignItems="center">
          <Stack
            bgImg="/img/inscription/dialog.png"
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPosition="center"
            alignItems="center"
            width="300px"
            py="30px"
            mt="100px"
          >
            <Image src="/img/inscription/icon.png" width="40%" />
            <Text fontSize="x-large">
              <FormattedMessage
                id="Rush.congratulation"
                defaultMessage="恭喜"
              />
            </Text>
            <Text>
              <FormattedMessage
                id="Rush.message"
                defaultMessage="使用{ieb} IEB抢{iecc} IECC"
                values={{
                  ieb: result.ieb_price,
                  iecc: result.random,
                }}
              />
            </Text>
            <Button
              bgImg="/img/inscription/button.png"
              bgPosition="center"
              width="180px"
              bgSize="contain"
              height="70px"
              bgRepeat="no-repeat"
              fontSize="large"
              color="#044d2b"
              mt="10%"
              onClick={async () => {
                setLoading(true);
                const data = await dispatch(
                  Api.endpoints.getBuyIECC.initiate(),
                ).unwrap();
                if (data.code !== "0") {
                  toast({
                    title: data.msg,
                    status: "error",
                  });
                } else {
                  toast({
                    title: (
                      <FormattedMessage
                        id="Rush.success"
                        defaultMessage="抢购成功"
                      />
                    ),
                  });
                  onClose();
                }
                setLoading(false);
              }}
            >
              {!loading && (
                <FormattedMessage id="Rush.confirm" defaultMessage="确认" />
              )}
              {loading && <Spinner />}
            </Button>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
}
