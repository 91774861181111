import {
  Button,
  Flex,
  Image,
  Text,
  Spacer,
  useDisclosure,
  Stack,
  Spinner,
  useToast,
  Box,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { connectThunk } from "../stores/auth";
import { useAppDispatch, useAppSelector } from "../stores/global";
import { GradientButton } from "./GradientButton";
import { setLocale } from "../stores/locale";
import WalletSelector from "./WalletSelector";
import { initWalletThunk, setIniting, setWalletType } from "../stores/wallet";
import { copyToClipboard } from "../copy";

export default function Header() {
  const dispatch = useAppDispatch();
  const address = useAppSelector((state) => state.auth.address);
  const token = useAppSelector((state) => state.auth.token);
  const locale = useAppSelector((state) => state.locale);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const walletSelectorDisclosure = useDisclosure();

  const toast = useToast();

  const handleOpen = () => {
    if (!address) {
      walletSelectorDisclosure.onOpen();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Flex direction={"row"} my={2} mx={4}>
        <Button
          colorScheme="whiteAlpha"
          rounded={100}
          leftIcon={
            <Image
              alt="icon"
              src="/img/icon-wallet.png"
              boxSize={"20px"}
              mb={"4px"}
            />
          }
          onClick={handleOpen}
        >
          {address && (
            <Text
              textColor={"white"}
              maxW={100}
              textOverflow={"ellipsis"}
              overflowX={"clip"}
            >
              {address.substring(0, 4) +
                "..." +
                address.substring(address.length - 5)}
            </Text>
          )}
          {!token && !address && (
            <Text
              textColor="white"
              maxW={100}
              textOverflow={"ellipsis"}
              overflowX={"clip"}
            >
              <FormattedMessage id="Header.signin" defaultMessage="登录" />
            </Text>
          )}
          {token && !address && <Spinner color="white" />}
        </Button>
        <Spacer />
        <Button
          colorScheme="whiteAlpha"
          rounded={100}
          px={2}
          onClick={() => {
            dispatch(setLocale(locale === "EN" ? "CN" : "EN"));
          }}
        >
          <Text textColor={"white"} fontWeight={900}>
            <FormattedMessage id="Header.language" defaultMessage="语言 " />
            {locale}
          </Text>
        </Button>
      </Flex>
      {isOpen && (
        <Stack
          gap="0px"
          position="fixed"
          top="0"
          height="100%"
          width="100%"
          zIndex="10"
          textColor="white"
        >
          <Stack py="16px" bg="gray.800" width="100%" align="center" gap="16px">
            <Text
              rounded="50px"
              border="1px"
              bg="#ffffff11"
              px="16px"
              py="8px"
              wordBreak="break-all"
              fontSize="x-small"
            >
              {address}
            </Text>
            <GradientButton
              fontSize="large"
              onClick={() => {
                if (copyToClipboard(address)) {
                  toast({
                    title: "复制成功",
                  });
                }
              }}
            >
              <FormattedMessage id="Header.copy" defaultMessage="复制地址" />
            </GradientButton>
          </Stack>
          <Box
            flex="1"
            bg="#00000099"
            onClick={() => {
              onClose();
            }}
          />
        </Stack>
      )}
      <WalletSelector
        {...walletSelectorDisclosure}
        onSelect={async (wallet) => {
          dispatch(setWalletType(wallet));
          try {
            await dispatch(initWalletThunk()).unwrap();
            await dispatch(connectThunk()).unwrap();
          } catch (e) {
            if (wallet === "tokenpocket") {
              toast({
                title: (
                  <FormattedMessage
                    id="Header.error.tokenpocket"
                    defaultMessage="找不到TokenPocket钱包，请在钱包浏览器中访问"
                  />
                ),
              });
            }
            dispatch(setIniting(false));
            console.error(e);
          }
        }}
      />
    </>
  );
}
