import { bsc } from "viem/chains";
import usdtAbi from "../abi/usdt.json";
import { WalletClient, createPublicClient, createWalletClient, custom, http } from "viem";
import Bugsnag from "@bugsnag/js";

export const USDT_BSC_ADDRESS = "0x55d398326f99059ff775485246999027b3197955";
export const IEBB_BSC_ADDRESS = "0x023305db80811165eb7fc7eb5a2e5d661d1e2e80";

const client = createPublicClient({
  chain: bsc,
  transport: http(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export let walletClient: WalletClient;

export async function getBalance(address: `0x${string}`, contract: `0x${string}`) {
  return await client.readContract({
    abi: usdtAbi,
    functionName: "balanceOf",
    address: contract,
    args: [address],
  });
}

export async function getAllowance(address: `0x${string}`, spender: `0x${string}`, contract: `0x${string}`, polling: boolean) {
  let result: unknown = BigInt(0);
  const maxRetry = polling ? 10 : 1;
  let index = 0;
  while (index < maxRetry && result === BigInt(0)) {
    result = await client.readContract({
      abi: usdtAbi,
      functionName: "allowance",
      address: contract,
      args: [address, spender],
    });
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, 2000);
    });
    index ++;
  }
  return result as bigint;
}

export async function initEtherWallet() {
  try {
    if ((window as any).ethereum) {
      walletClient = createWalletClient({
        chain: bsc,
        transport: custom((window as any).ethereum),
      });
      console.log(walletClient);
    }
  } catch (e) {
    Bugsnag.notify(e);
  }
}

export async function sign(account: `0x${string}`, message: string) {
  await walletClient.signMessage({
    message,
    account,
  })
}
