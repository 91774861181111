import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  UseDisclosureProps,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { GradientButton } from "../../components/GradientButton";
import { usePostWithdrawMutation } from "../../stores/api";

export type WithdrawProps = {
  coin: "USDT" | "IEB" | "IEC" | "IECC" | "IEBB" | "NICE-COIN";
  available: string;
  onSuccess: () => void;
} & UseDisclosureProps;

export default function Withdraw({
  coin,
  available,
  onClose,
  onSuccess,
  ...rest
}: WithdrawProps) {
  const toast = useToast();
  const [fromValue, setFromValue] = useState("");
  const [finalValue, setFinalValue] = useState(0);
  const [postWithdrawMutation] = usePostWithdrawMutation();

  const handlleWithdraw = async () => {
    const data = await postWithdrawMutation({
      coin,
      num: finalValue.toString(),
    }).unwrap();
    if (data.code === "0") {
      toast({
        title: (
          <FormattedMessage id="Withdraw.success" defaultMessage="提币成功" />
        ),
        status: "success",
      });
      setFromValue("");
      setFinalValue(0);
      onClose();
      onSuccess();
    } else {
      toast({
        title: (
          <FormattedMessage id="Withdraw.error" defaultMessage="提币失败" />
        ),
        status: "error",
      });
    }
  };

  return (
    <Drawer
      placement="bottom"
      {...(rest as { isOpen: boolean; onClose: () => void })}
    >
      <DrawerOverlay />
      <DrawerContent bg="gray.800" color="white">
        <DrawerHeader>
          <FormattedMessage id="Withdraw.title" defaultMessage="提币" />
          <DrawerCloseButton onClick={() => onClose()} />
        </DrawerHeader>
        <DrawerBody>
          <Stack>
            <FormControl
              isInvalid={parseFloat(fromValue) > parseFloat(available)}
            >
              <Input
                value={fromValue}
                type="number"
                onBlur={(e) => {
                  setFinalValue(parseFloat(e.target.value) || 0);
                }}
                onChange={(e) => {
                  setFromValue(e.target.value);
                }}
              />
              <FormHelperText>
                <FormattedMessage
                  id="Withdraw.available"
                  defaultMessage="可用"
                />{" "}
                {available}
              </FormHelperText>
              <FormErrorMessage>
                <FormattedMessage
                  id="Withdraw.insufficient"
                  defaultMessage="可用余额不足"
                />
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <GradientButton
            fontSize="large"
            onClick={() => {
              handlleWithdraw();
            }}
          >
            <FormattedMessage id="Withdraw.confirm" defaultMessage="提交" />
          </GradientButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
