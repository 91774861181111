import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Record } from "./Record";
import { Record2 } from "./Record2";
import { useState } from "react";
import SecondaryHeader from "../../components/SecondaryHeader";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { MyRecord } from "./MyRecord";

export function History() {
  const [index, setIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setIndex(index);
  };

  const { type } = useParams();

  return (
    <Box position="relative" color="white">
      <SecondaryHeader
        title={<FormattedMessage id="history" defaultMessage="游戏记录" />}
      />
      <Tabs
        isFitted
        variant="unstyled"
        onChange={handleTabsChange}
        index={index}
      >
        <TabList>
          <Tab
            fontSize={index === 0 ? "large" : "normal"}
            fontWeight={index === 0 ? "bold" : "normal"}
          >
            往期记录
          </Tab>
          <Tab
            fontSize={index === 1 ? "large" : "normal"}
            fontWeight={index === 1 ? "bold" : "normal"}
          >
            个人记录
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0">
            {type === "1" && <Record2 />}
            {type === "2" && <Record />}
          </TabPanel>
          <TabPanel px="0">
            <MyRecord />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
