import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../stores/global";
import { useEffect } from "react";
import { initWalletThunk, signClient } from "../stores/wallet";
import { GradientButton } from "./GradientButton";
import { authThunk } from "../stores/auth";
import { authing as setAuthing } from "../stores/auth";
import { walletClient } from "../wallet/EtherWallet";

export default function ClientProvider() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authDisclosure = useDisclosure();
  const dispatch = useAppDispatch();
  const address = useAppSelector((state) => state.auth.address);
  const token = useAppSelector((state) => state.auth.token);
  const initing = useAppSelector((state) => state.wallet.initing);
  const toast = useToast();
  const authing = useAppSelector((state) => state.auth.authing);
  const wallet = useAppSelector((state) => state.wallet.wallet);

  useEffect(() => {
    if (wallet && (!signClient || !walletClient)) {
      dispatch(initWalletThunk());
    }
  }, [dispatch, wallet]);

  useEffect(() => {
    if (!token && initing) {
      onOpen();
    } else {
      onClose();
    }
  }, [token, initing, onOpen, onClose]);

  useEffect(() => {
    if (address && !token) {
      authDisclosure.onOpen();
    } else {
      authDisclosure.onClose();
    }
  }, [address, token, authDisclosure]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="gray.800" textColor="white">
          <DrawerBody>
            <Flex h="100px">
              <Center>
                <Flex direction="row">
                  <Text>
                    <FormattedMessage
                      id="SignClientProvider.connecting"
                      defaultMessage="连接钱包..."
                    />
                  </Text>
                  <Spacer />
                  <Spinner />
                </Flex>
              </Center>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={authDisclosure.isOpen}
        placement="bottom"
        onClose={authDisclosure.onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="gray.800" textColor="white" py="16px">
          <DrawerBody>
            <Flex direction="column" alignItems="center" gap="16px">
              <Text>
                <FormattedMessage
                  id="SignClientProvider.auth"
                  defaultMessage="请求使用钱包签名登录FixBox"
                />
              </Text>
              <Spacer />
              <GradientButton
                width="100px"
                fontSize="large"
                onClick={() => {
                  dispatch(authThunk())
                    .unwrap()
                    .catch((e) => {
                      toast({
                        title: e.message,
                      });
                    });
                  dispatch(setAuthing(true));
                }}
              >
                {authing ? (
                  <Spinner color="white" />
                ) : (
                  <FormattedMessage
                    id="SignClientProvider.signin"
                    defaultMessage="签名"
                  />
                )}
              </GradientButton>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
