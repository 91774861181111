import {
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  Link,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { GradientButton } from "../components/GradientButton";
import QueryPage from "../components/QueryPage";
import { useGetUserTeamQuery } from "../stores/api";
import { Link as ReactRouterLink } from "react-router-dom";
import { ChevronRightIcon, HamburgerIcon, SunIcon } from "@chakra-ui/icons";
import { copyToClipboard } from "../copy";

export default function Community() {
  const { isLoading, error, data, refetch } = useGetUserTeamQuery();

  const toast = useToast();

  return (
    <QueryPage
      isLoading={isLoading}
      error={error}
      errorMessage={
        <FormattedMessage id="Community.error" defaultMessage="数据错误" />
      }
      refetch={refetch}
      title={
        <FormattedMessage id="Community.title" defaultMessage="我的社区" />
      }
    >
      {data && (
        <Stack>
          <Stack
            bg="#ffffff11"
            rounded="12px"
            color="white"
            px="12px"
            py="24px"
          >
            <Flex alignItems="center">
              <Stack>
                <Text>
                  <FormattedMessage
                    id="Community.inviteLink"
                    defaultMessage="邀请链接"
                  />
                </Text>
                <Text>{data.data.link}</Text>
              </Stack>
              <Spacer />
            </Flex>
            <GradientButton
              alignSelf="end"
              onClick={async () => {
                if (copyToClipboard(data.data.link)) {
                  toast({
                    title: (
                      <FormattedMessage
                        id="Community.copied"
                        defaultMessage="已复制"
                      />
                    ),
                  });
                }
              }}
            >
              <Text>
                <FormattedMessage
                  id="Community.copyLink"
                  defaultMessage="复制链接"
                />
              </Text>
            </GradientButton>
          </Stack>
          <Flex mt="20px" gap="12px">
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              alignItems="center"
              flex="1"
            >
              <Link to="/profile/team" as={ReactRouterLink} width="100%">
                <Flex alignItems="center">
                  <Stack>
                    <Text color="gray.400" fontSize="small">
                      <FormattedMessage
                        id="Community.invitedUsers"
                        defaultMessage="直推用户"
                      />
                    </Text>
                    <Text color="green.300" fontWeight="bold">
                      {data.data.child}
                    </Text>
                  </Stack>
                  <Spacer />
                  <Image
                    src="/img/icon-direct.png"
                    width="24px"
                    color="white"
                  />
                  <ChevronRightIcon color="white" />
                </Flex>
              </Link>
            </Flex>
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack>
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.communityUsers"
                    defaultMessage="社区用户"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.team}
                </Text>
              </Stack>
              <Spacer />
              <Image src="/img/icon-indirect.png" width="32px" color="white" />
            </Flex>
          </Flex>
          <Flex gap="12px">
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              alignItems="center"
              flex="1"
            >
              <Stack>
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.bigArea"
                    defaultMessage="大区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.big_area}
                </Text>
              </Stack>
              <Spacer />
              <SunIcon color="white" width="32px" height="32px" />
            </Flex>
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack>
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.smallArea"
                    defaultMessage="小区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.small_area}
                </Text>
              </Stack>
              <Spacer />
              <HamburgerIcon color="white" width="32px" height="32px" />
            </Flex>
          </Flex>
          <Flex gap="12px">
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              alignItems="center"
              flex="1"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.poolBigArea"
                    defaultMessage="矿池大区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.pool_big_area}
                </Text>
              </Stack>
            </Flex>
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.poolSmallArea"
                    defaultMessage="矿池小区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.pool_small_area}
                </Text>
              </Stack>
            </Flex>
          </Flex>
          <Flex gap="12px">
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              alignItems="center"
              flex="1"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.liquidityPoolBigArea"
                    defaultMessage="流动矿池大区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.flow_pool_big_area}
                </Text>
              </Stack>
            </Flex>
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.liquidityPoolSmallArea"
                    defaultMessage="流动矿池小区业绩"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.flow_pool_small_area}
                </Text>
              </Stack>
            </Flex>
          </Flex>
          <Flex gap="10px">
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.todayCommunityTransaction"
                    defaultMessage="今日社区流水"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.todayflowing ?? 0}
                </Text>
              </Stack>
            </Flex>
            <Flex
              bg="#ffffff11"
              bgColor="#ffffff22"
              rounded="10px"
              p="12px"
              flex="1"
              alignItems="center"
            >
              <Stack align="center" width="100%">
                <Text color="gray.400" fontSize="small">
                  <FormattedMessage
                    id="Community.guessPercent"
                    defaultMessage="猜猜乐"
                  />
                </Text>
                <Text color="green.300" fontWeight="bold">
                  {data.data.guess_percent ?? 0}
                </Text>
              </Stack>
            </Flex>
          </Flex>
          <Flex
            bg="#ffffff11"
            bgColor="#ffffff22"
            rounded="10px"
            p="12px"
            flex="1"
            alignItems="center"
          >
            <Stack>
              <Text color="gray.400" fontSize="small">
                <FormattedMessage
                  id="Community.todayTransaction"
                  defaultMessage="今日社区业绩"
                />
              </Text>
              <Text color="green.300" fontWeight="bold">
                {data.data.todayperformance}
              </Text>
            </Stack>
            <Spacer />
            <Image src="/img/icon-transaction.png" width="24px" color="white" />
          </Flex>
        </Stack>
      )}
    </QueryPage>
  );
}
