import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  UseDisclosureProps,
  useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import { GradientButton } from "../../components/GradientButton";
import {
  useGetSwapRateQuery,
  usePostExchangeMutation,
} from "../../stores/api";

export type ExchangeProps = {
  from: "USDT" | "IEB" | "IEC" | "IECC" | "IEBB" | "NICE-COIN";
  to: "USDT" | "IEB" | "IEC" | "IECC" | "IEBB" | "NICE-COIN";
  available: string;
  onSuccess: () => void;
} & UseDisclosureProps;

export default function Exchange({
  from,
  to,
  available,
  onClose,
  onSuccess,
  ...rest
}: ExchangeProps) {
  const toast = useToast();
  const [fromValue, setFromValue] = useState("");
  const [finalValue, setFinalValue] = useState(0);
  const { isFetching, data, error } = useGetSwapRateQuery(
    {
      from: from,
      to: to,
      num: finalValue.toString(),
    },
    {
      skip: !(from && to && finalValue > 0),
      pollingInterval: 8000,
    }
  );
  const toValue = useMemo(() => {
    if (data && data.msg !== "success") {
      toast({
        title: data.msg,
      });
    }
    if (error) {
      return "计算错误";
    }
    return data?.data?.expect_num;
  }, [data, error, toast]);

  const [postExchangeMutation] = usePostExchangeMutation();

  const handlleExchange = async () => {
    const data = await postExchangeMutation({
      from_coin: from,
      to_coin: to,
      num: finalValue.toString(),
    }).unwrap();
    if (data.data) {
      toast({
        title: (
          <FormattedMessage id="Exchange.success" defaultMessage="闪兑成功" />
        ),
        status: "success",
      });
      setFromValue("");
      setFinalValue(0);
      onClose();
      onSuccess();
    } else {
      toast({
        title: (
          <FormattedMessage id="Exchange.error" defaultMessage="闪兑失败" />
        ),
        status: "error",
      });
    }
  };

  useEffect(() => {
    setFromValue("");
    setFinalValue(0);
  }, [from]);

  return (
    <Drawer
      placement="bottom"
      {...(rest as { isOpen: boolean; onClose: () => void })}
    >
      <DrawerOverlay />
      <DrawerContent bg="gray.800" color="white">
        <DrawerHeader>
          <FormattedMessage id="Exchange.title" defaultMessage="闪兑" />
          <DrawerCloseButton onClick={() => onClose()} />
        </DrawerHeader>
        <DrawerBody>
          <Stack>
            <FormControl
              isInvalid={parseFloat(fromValue) > parseFloat(available)}
            >
              <FormLabel>{from}</FormLabel>
              <InputGroup>
                <Input
                  value={fromValue}
                  type="number"
                  onBlur={(e) => {
                    setFinalValue(parseFloat(e.target.value) || 0);
                  }}
                  onChange={(e) => {
                    setFromValue(e.target.value);
                  }}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setFromValue(available);
                      setFinalValue(parseFloat(available));
                    }}
                  >
                    <FormattedMessage id="Exchange.max" defaultMessage="最大" />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormHelperText>
                <FormattedMessage
                  id="Exchange.available"
                  defaultMessage="可用"
                />{" "}
                {available}
              </FormHelperText>
              <FormErrorMessage>
                <FormattedMessage
                  id="Exchange.insufficient"
                  defaultMessage="可用余额不足"
                />
              </FormErrorMessage>
            </FormControl>

            {isFetching ? (
              <Spinner />
            ) : (
              <Text>
                <FormattedMessage
                  id="Exchange.exchange"
                  defaultMessage="兑换为"
                />
              </Text>
            )}
            <FormControl isDisabled={true}>
              <FormLabel>{to}</FormLabel>
              <Input value={toValue} type="number" />
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <GradientButton
            fontSize="large"
            onClick={() => {
              handlleExchange();
            }}
          >
            <FormattedMessage id="Exchange.confirm" defaultMessage="提交" />
          </GradientButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
