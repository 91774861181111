import {
  Box,
  Divider,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import SecondaryHeader from "../../components/SecondaryHeader";
import { FormattedMessage } from "react-intl";
import {
  Api,
  useGetPoolOverviewQuery,
  useGetPoolRecordQuery,
} from "../../stores/api";
import QueryPage from "../../components/QueryPage";
import { useEffect, useState } from "react";
import { unionWith } from "lodash";
import { PoolJoinRecord } from "../../types";
import { useAppDispatch } from "../../stores/global";
import Wave from "../../components/Wave";

export function List() {
  const overview = useGetPoolOverviewQuery();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<PoolJoinRecord[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const { error, data, isFetching, refetch, requestId } =
    useGetPoolRecordQuery(page);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTotal((total) => {
        return unionWith(total, data?.data?.data ?? [], (a, b) => {
          return a.identifier === b.identifier;
        });
      });
    }
  }, [data?.data, requestId]);

  useEffect(() => {
    setCanFetchMore(
      !isFetching && total.length !== 0 && page < data?.data?.last_page,
    );
  }, [page, data?.data?.last_page, total, isFetching]);

  return (
    <QueryPage
      isLoading={overview.isFetching || isFetching}
      error={overview.error || error}
      errorMessage={
        <FormattedMessage id="Pool.List.error" defaultMessage="Error" />
      }
      refetch={() => {
        setPage(1);
        overview.refetch();
        refetch();
      }}
      hasMore={canFetchMore}
      onFetchMore={async () => {
        if (!isFetching) {
          setPage(page + 1);
        }
      }}
      pos="relative"
      bgImg="/img/bg-pool-list.png"
      bgPos="top"
      bgSize="contain"
      p="0px"
    >
      <Box color="black" px="8px">
        <SecondaryHeader
          title={
            <FormattedMessage id="Pool.List.title" defaultMessage="IEB矿池" />
          }
        />
        <Text>
          <FormattedMessage id="Pool.List.overview" defaultMessage="矿池总览" />
        </Text>
      </Box>
      <Wrap p="16px">
        <WrapItem width="35%" justifyContent="end">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.pre_sum}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.pre_sum"
                defaultMessage="预计总量"
              />
            </Text>
          </Stack>
        </WrapItem>
        <WrapItem width="30%" justifyContent="end">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.left_sum}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.left_sum"
                defaultMessage="剩余总量"
              />
            </Text>
          </Stack>
        </WrapItem>
        <WrapItem width="30%" justifyContent="end">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.preReward}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.reward"
                defaultMessage="直推间推奖"
              />
            </Text>
          </Stack>
        </WrapItem>
        <WrapItem width="33%" mt="-10px">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.join_sum}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.join_sum"
                defaultMessage="参与总量"
              />
            </Text>
          </Stack>
        </WrapItem>
        <WrapItem width="32%" mt="-10px">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.has_sum}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.has_sum"
                defaultMessage="已产量"
              />
            </Text>
          </Stack>
        </WrapItem>
        <WrapItem width="30%" mt="-10px">
          <Stack
            justify="center"
            width="100px"
            borderRadius="50%"
            aspectRatio="1"
            bg="#ffffff99"
            textAlign="center"
            pos="relative"
            clipPath="border-box"
          >
            <Box
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              height="100%"
              overflow="hidden"
              zIndex="-1"
            >
              <Wave />
            </Box>
            <Text fontWeight="bold">{overview.data?.data?.history_sum}</Text>
            <Text fontSize="small">
              <FormattedMessage
                id="Pool.List.history_sum"
                defaultMessage="已产总量"
              />
            </Text>
          </Stack>
        </WrapItem>
      </Wrap>

      <Stack
        bgColor="black"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        color="white"
        p="16px"
        gap="16px"
        minH="600px"
      >
        <Flex>
          <Text>
            <FormattedMessage
              id="Pool.List.toReorder"
              defaultMessage="待复投"
            />{" "}
            {overview.data?.data?.reOrder}
          </Text>
          <Spacer />
          {isLoading && <Spinner />}
          {!isLoading && (
            <Text
              onClick={async () => {
                setIsLoading(true);
                const response = await dispatch(
                  Api.endpoints.getActiveOrder.initiate(),
                ).unwrap();
                if (response.data) {
                  toast({
                    title: (
                      <FormattedMessage
                        defaultMessage="成功复投"
                        id="Pool.List.success"
                      />
                    ),
                  });
                } else {
                  toast({
                    title: response.msg,
                  });
                }
                setIsLoading(false);
              }}
            >
              <FormattedMessage id="Pool.List.reorder" defaultMessage="激活" />
            </Text>
          )}
        </Flex>
        <Text>
          <FormattedMessage id="Pool.List.amount" defaultMessage="参与数量" />
        </Text>
        {total.map((e) => {
          return (
            <Stack
              key={e.identifier + e.has_profit}
              border="#bcf96b solid 2px"
              p="8px"
              borderRadius="8px"
              filter={e.status === 2 ? "grayscale(10)" : "none"}
              opacity={e.status === 2 ? "0.3" : "1"}
            >
              <Flex align="center">
                <Text fontWeight="bold">#{e.identifier}</Text>
                <Text
                  ml="8px"
                  fontSize="x-small"
                  bg="green"
                  borderRadius="4px"
                  px="8px"
                  py="2px"
                >
                  {e.status_desc}
                </Text>
              </Flex>
              <Flex align="center" gap="8px">
                <Text
                  color="gray"
                  fontSize="small"
                  alignSelf="flex-end"
                  pb="4px"
                >
                  <FormattedMessage
                    id="Pool.List.amount"
                    defaultMessage="参与数量"
                  />
                </Text>
                <Text fontWeight="extrabold" fontSize="x-large">
                  {e.num}
                </Text>
                <Spacer />
                <Text
                  fontSize="small"
                  bgColor={e.residue === "0.0000" ? "gray" : "#233035"}
                  color="#77fdaa"
                  p="8px"
                  borderRadius="4px"
                >
                  <FormattedMessage id="Pool.List.left" defaultMessage="剩余" />{" "}
                  {e.residue}
                </Text>
              </Flex>
              <Divider pt="6px" mb="6px" borderColor="#ffffff44" />
              <Flex fontSize="small" color="gray">
                <Text>
                  <FormattedMessage
                    id="Pool.List.total"
                    defaultMessage="挖矿总量："
                  />
                  {e.free_total}
                </Text>
                <Spacer />
                <Text>
                  <FormattedMessage
                    id="Pool.List.produced"
                    defaultMessage="已产："
                  />
                  {e.has_profit}
                </Text>
              </Flex>
              <Box
                bgGradient="linear-gradient(90deg, rgba(39,78,78,1) 0%, rgba(39,78,78,0) 100%)"
                p="8px"
                fontSize="small"
                color="gray"
              >
                <FormattedMessage
                  id="Pool.List.timestamp"
                  defaultMessage="参与时间"
                />{" "}
                {e.ctime}
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
