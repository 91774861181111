import { useParams } from "react-router-dom";
import QueryPage from "../components/QueryPage";
import { useGetUserTransactionQuery } from "../stores/api";
import { miniSerializeError } from "@reduxjs/toolkit";
import { FormattedMessage } from "react-intl";
import { Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TransactionItem } from "../types";
import { unionWith } from "lodash";

export default function Transaction() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<TransactionItem[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(false);

  const { coin } = useParams();
  const { isFetching, error, data, requestId, refetch } =
    useGetUserTransactionQuery({
      coin,
      page,
    });

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTotal((total) => {
        return unionWith(total, data?.data?.data ?? [], (a, b) => {
          return a.id === b.id;
        });
      });
    }
  }, [data?.data, requestId]);

  useEffect(() => {
    setCanFetchMore(
      !isFetching && total.length !== 0 && page < data?.data?.last_page,
    );
  }, [page, data?.data?.last_page, total, isFetching]);

  return (
    <QueryPage
      error={coin ? error : miniSerializeError("error")}
      isLoading={isFetching}
      refetch={() => {
        setTotal([]);
        setPage(1);
        refetch();
      }}
      onFetchMore={() => {
        if (!isFetching) {
          setPage(page + 1);
        }
      }}
      hasMore={canFetchMore}
      errorMessage={
        <FormattedMessage
          id="Transaction.error"
          defaultMessage="加载账户记录错误"
        />
      }
      title={
        <FormattedMessage id="Transaction.title" defaultMessage="账户记录" />
      }
    >
      <Stack gap="8px">
        {total.map((item) => {
          return (
            <Flex
              key={item.id}
              textColor="white"
              bgColor="#ffffff11"
              rounded="16px"
              p="12px"
              alignItems="center"
            >
              <Image
                src="/img/icon-log.png"
                boxSize="40px"
                bgColor="#ffffff22"
                rounded="50%"
                p="12px"
              />
              <Stack marginLeft="12px">
                <Text fontSize="large">{item.from}</Text>
                <Text textColor="gray.300" fontSize="small">
                  {item.ctime}
                </Text>
              </Stack>
              <Spacer />
              <Text color={item.op === "+" ? "green" : "red"}>{item.op}</Text>
              <Text>{item.amount}</Text>
            </Flex>
          );
        })}
      </Stack>
    </QueryPage>
  );
}
