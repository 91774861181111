import { Button } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function OutlineButton({
  children,
  ...rest
}: {
  children: ReactNode;
  [key: string]: unknown;
}) {
  return (
    <Button
      rounded="100px"
      variant="outline"
      color="white"
      px="20px"
      py="4px"
      fontSize="small"
      height="30px"
      {...rest}
    >
      {children}
    </Button>
  );
}
