import { Box, Container, Image, Stack, Text, useToast } from "@chakra-ui/react";
import SecondaryHeader from "../components/SecondaryHeader";
import { FormattedMessage } from "react-intl";
import { CopyIcon } from "@chakra-ui/icons";
import { useGetInviteUrlQuery } from "../stores/api";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../copy";

export function Invite() {
  const { data } = useGetInviteUrlQuery();
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <Container pos="relative" h="100%">
      <Box zIndex="10" pos="relative" color="white">
        <SecondaryHeader
          title={
            <FormattedMessage id="Invite.title" defaultMessage="邀请好友" />
          }
        />
        <Image
          src="/img/ic-community.png"
          width="24px"
          pos="absolute"
          top="18px"
          right="0"
          onClick={() => {
            navigate("/profile/community")
          }}
        />
      </Box>
      <Stack
        pos="relative"
        align="center"
        color="white"
        bg="#ffffff11"
        p="8px"
        borderRadius="16px"
        mt="150px"
        zIndex="10"
      >
        <Image src="/img/ic-invitation.png" width="100px" mt="-100px" />
        <Text mt="24px">
          <FormattedMessage
            id="Invite.content"
            defaultMessage="参与IEB生态建设，见证奇迹时刻"
          />
        </Text>
        <Text color="gray" fontSize="small">
          <FormattedMessage
            id="Invite.description"
            defaultMessage="邀请好友，一起遨游数字链游，获得更多奖励"
          />
        </Text>
        <Stack
          width="100%"
          align="center"
          bg="#ffffff11"
          borderRadius="8px"
          p="8px"
        >
          <Text>
            <FormattedMessage id="Invite.link" defaultMessage="邀请链接" />
            <CopyIcon
              color="green"
              ml="16px"
              onClick={() => {
                if (copyToClipboard(data?.data?.link)) {
                  toast({
                    title: (
                      <FormattedMessage
                        id="Invite.copied"
                        defaultMessage="已复制"
                      />
                    ),
                  });
                }
              }}
            />
          </Text>
          <Text color="#5bff95" fontWeight="bold">
            {data?.data?.link}
          </Text>
        </Stack>
        <Stack
          width="100%"
          align="center"
          bg="#ffffff11"
          borderRadius="8px"
          p="8px"
        >
          <Text>
            <FormattedMessage id="Invite.code" defaultMessage="我的邀请码" />
            <CopyIcon
              color="green"
              ml="16px"
              onClick={() => {
                if (copyToClipboard(data?.data?.code)) {
                  toast({
                    title: (
                      <FormattedMessage
                        id="Invite.copied"
                        defaultMessage="已复制"
                      />
                    ),
                  });
                }
              }}
            />
          </Text>
          <Text fontSize="x-large" color="#5bff95" fontWeight="bold">
            {data?.data?.code}
          </Text>
        </Stack>
      </Stack>
      <Image
        src="/img/bg-invite.png"
        pos="absolute"
        left="0"
        top="0"
        w="100%"
        h="100%"
      />
    </Container>
  );
}
