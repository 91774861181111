import { Button, TypographyProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export interface GradientButtonProps {
  children: ReactNode | string;
  fontSize?: TypographyProps["fontSize"];
  [key: string]: unknown;
}

export function GradientButton({
  children,
  fontSize = "x-small",
  ...rest
}: GradientButtonProps) {
  return (
    <Button
      bgGradient="linear-gradient(150deg, rgba(233,250,44,1) 0%, rgba(93,254,197,1) 100%)"
      rounded="100px"
      fontSize={fontSize}
      height="32px"
      {...rest}
    >
      {children}
    </Button>
  );
}
