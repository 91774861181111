"use client";
import { Grid, GridItem, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export default function SecondaryHeader({
  title,
  onClick,
  color,
}: {
  title: string | ReactNode;
  onClick?: () => void;
  color?: string;
}) {
  const navigate = useNavigate();
  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      py="10px"
      alignItems="center"
    >
      <GridItem w="100%">
        <IconButton
          alignSelf="start"
          justifySelf="start"
          icon={<ChevronLeftIcon boxSize={8} />}
          aria-label="Back"
          variant="ghost"
          color="#ffffffcc"
          onClick={() => {
            onClick ? onClick() : navigate(-1);
          }}
        />
      </GridItem>
      <GridItem
        colSpan={3}
        w="100%"
        textAlign={"center"}
        fontWeight="600"
        fontSize="large"
        color={color ?? "black"}
      >
        {title}
      </GridItem>
    </Grid>
  );
}
