import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SignClient from "@walletconnect/sign-client";
import { RootState } from "./global";
import { Abi, encodeFunctionData, parseEther, parseGwei } from "viem";
import usdtAbi from "../abi/usdt.json";
import { bsc } from "viem/chains";
import { setAddress } from "./auth";
import { initEtherWallet, walletClient } from "../wallet/EtherWallet";

const USDT_BSC_ADDRESS = "0x55d398326f99059ff775485246999027b3197955";

export let signClient: SignClient;

type WalletType = "walletconnect" | "tokenpocket";

export interface WalletState {
  initing: boolean;
  wallet: WalletType | undefined;
}

const initialState: WalletState = {
  initing: false,
  wallet: localStorage.getItem("wallet:default") as WalletType,
};

export const initWalletThunk = createAsyncThunk(
  "wallet/init",
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (rootState.wallet.initing) return;
    thunkApi.dispatch(setIniting(true));
    if (rootState.wallet.wallet === "walletconnect") {
      if (signClient) return;
      signClient = await SignClient.init({
        projectId: "34fe855222c2de54d6234d64060f7761",
        metadata: {
          name: "FixBox",
          description: "ieb.ink",
          url: "https://ieb.ink",
          icons: ["https://walletconnect.com/walletconnect-logo.png"],
        },
      });

      console.log("wallet inited");

      signClient.events.on("session_update", (args) => {
        console.log("session_update", args);
        signClient = null;
        thunkApi.dispatch(initWalletThunk());
      });

      const index = signClient.session.getAll().length - 1;
      const session = signClient.session.getAll()[index];
      if (session) {
        thunkApi.dispatch(
          setAddress({
            address: session.namespaces.eip155.accounts[0].split(
              ":",
            )[2] as `0x${string}`,
          }),
        );
      }
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (walletClient) return;
      await initEtherWallet();
      console.log("wallet inited");

      const addresses = await walletClient.requestAddresses();
      if (addresses && addresses.length > 0) {
        thunkApi.dispatch(
          setAddress({
            address: addresses[0],
          }),
        );
      }
    }
    thunkApi.dispatch(setIniting(false));
  },
);

export const setApproveThunk = createAsyncThunk(
  "wallet/approve",
  async (args: { targetAddress: string; abi: Abi; contract: `0x${string}` }, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const address = rootState.auth.address;
    const approveData = encodeFunctionData({
      abi: args.abi,
      functionName: "approve",
      args: [args.targetAddress, parseGwei("1000000000000000")],
    });
    if (rootState.wallet.wallet === "walletconnect") {
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      if (!signClient) {
        return;
      }
      const result = await signClient.request({
        request: {
          method: "eth_sendTransaction",
          params: [
            {
              from: address,
              to: args.contract,
              data: approveData,
            },
          ],
        },
        chainId: `eip155:${bsc.id}`,
        topic: lastSession.topic,
        expiry: 600000,
      });
      console.log(result);
      return result as string;
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      const result = await walletClient.sendTransaction({
        account: address,
        chain: bsc,
        to: args.contract,
        data: approveData,
      });
      console.log(result);
      return result;
    }
  },
);

export const buyRobotThunk = createAsyncThunk(
  "wallet/transferUSDT",
  async (args: { amount: string; targetAddress: string }, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const address = rootState.auth.address;
    const transactionData = encodeFunctionData({
      abi: usdtAbi,
      functionName: "transfer",
      args: [args.targetAddress, parseEther(args.amount)],
    });
    if (rootState.wallet.wallet === "walletconnect") {
      if (!signClient) {
        return;
      }
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      return await signClient.request({
        request: {
          method: "eth_sendTransaction",
          params: [
            {
              from: address,
              to: USDT_BSC_ADDRESS,
              data: transactionData,
            },
          ],
        },
        chainId: `eip155:${bsc.id}`,
        topic: lastSession.topic,
      });
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      return await walletClient.sendTransaction({
        account: address,
        chain: bsc,
        to: USDT_BSC_ADDRESS,
        data: transactionData,
      });
    }
  },
);

export const depositThunk = createAsyncThunk(
  "wallet/deposit",
  async (args: { amount: string; targetAddress: string; abi: Abi; contract: `0x${string}` }, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const address = rootState.auth.address;
    const transactionData = encodeFunctionData({
      abi: args.abi,
      functionName: "transfer",
      args: [args.targetAddress, parseEther(args.amount)],
    });
    if (rootState.wallet.wallet === "walletconnect") {
      if (!signClient) {
        return;
      }
      const lastIndex = signClient.session.getAll().length - 1;
      const lastSession = signClient.session.getAll()[lastIndex];
      return await signClient.request({
        request: {
          method: "eth_sendTransaction",
          params: [
            {
              from: address,
              to: args.contract,
              data: transactionData,
            },
          ],
        },
        chainId: `eip155:${bsc.id}`,
        topic: lastSession.topic,
      });
    } else if (rootState.wallet.wallet === "tokenpocket") {
      if (!walletClient) return;
      return await walletClient.sendTransaction({
        account: address,
        chain: bsc,
        to: args.contract,
        data: transactionData,
      });
    }
  },
);

export const Wallet = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    setIniting: (state, action: PayloadAction<boolean>) => {
      state.initing = action.payload;
      return state;
    },
    setWalletType: (
      state,
      action: PayloadAction<"walletconnect" | "tokenpocket">,
    ) => {
      state.wallet = action.payload;
      localStorage.setItem("wallet:default", state.wallet);
      return state;
    },
  },
});

export default Wallet.reducer;

export const { setIniting, setWalletType } = Wallet.actions;
